import { createSlice } from '@reduxjs/toolkit';
import BaseError from 'utils/base-error';
import { OutdoorBarArticleModelSchema } from 'entities/outdoor-bar-article';
import { z } from 'zod';
import { OutdoorBarDetailBlockState } from './outdoor-bar-detail-block-state';
import { OutdoorBarDetailBlockActions } from './outdoor-bar-detail-block-actions';

const initialState: OutdoorBarDetailBlockState = {
    loaded: false,
};

export const outdoorBarDetailBlockSlice = createSlice({
    name: 'outdoorBarDetailBlockSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        /**
         * Получение записи по uid
         */
        builder.addCase(
            OutdoorBarDetailBlockActions.getOutdoorBarByUid.fulfilled,
            (state, action) => {
                const response = action.payload;
                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log('outdoorBarDetailBlockSlice', response);
                        const result = OutdoorBarArticleModelSchema.parse({
                            uid: response.data.documentId,
                            name: response.data.name,
                            description: response.data.description,
                            category: {
                                uid: response.data.category.documentId,
                                name: response.data.category.name,
                            },
                            images: response.data.images.map((images: { url: string; }) => ({
                                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + images.url,
                            })),
                            portions: response.data.portions.map((
                                portion: {
                                    value: number;
                                    meassure: string;
                                    description: any;
                                    weight: number;
                                    price: number;
                                    currency: {
                                        documentId: string;
                                        name: string;
                                        abbreviation: string;
                                    };
                                },
                            ) => ({
                                value: portion.value,
                                meassure: portion.meassure,
                                description: portion.description,
                                weight: portion.weight,
                                price: portion.price,
                                currency: {
                                    uid: portion.currency.documentId,
                                    name: portion.currency.name,
                                    abbreviation: portion.currency.abbreviation,
                                },
                            })),
                            sets: response.data.sets.map((
                                set: {
                                    name: string;
                                    elements: {
                                        name: string;
                                        meassure: string;
                                        value: number;
                                    }[];
                                },
                            ) => ({
                                name: set.name,
                                elements: set.elements.map((
                                    element: {
                                        name: string;
                                        meassure: string;
                                        value: number;
                                    },
                                ) => ({
                                    name: element.name,
                                    meassure: element.meassure,
                                    value: element.value,
                                })),
                            })),
                            includedServices: response.data.includedServices.map((
                                includedService: {
                                    name: string;
                                    description: any;
                                },
                            ) => ({
                                name: includedService.name,
                                description: includedService.description,
                            })),
                            nonIncludedServices: response.data.nonIncludedServices.map((
                                nonIncludedService: {
                                    name: string;
                                },
                            ) => ({
                                name: nonIncludedService.name,
                            })),
                            recommendations: response.data.recommendations?.map((
                                recommendation: {
                                    documentId: string
                                    name: string
                                    weight: number
                                    price: number
                                    currency?: {
                                        documentId: string
                                        name: string
                                        abbreviation: string
                                    }
                                    images?: {
                                        url: string
                                    }[]
                                },
                            ) => ({
                                uid: recommendation.documentId,
                                name: recommendation.name,
                                weight: recommendation.weight,
                                price: recommendation.price,
                                currency: recommendation.currency && {
                                    uid: recommendation.currency.documentId,
                                    name: recommendation.currency.name,
                                    abbreviation: recommendation.currency.abbreviation,
                                },
                                image: recommendation.images && {
                                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST!
                                    + recommendation.images[0].url,
                                },
                            })),
                        });
                        console.log('outdoorBarDetailBlockSlice', result);
                        state.outdoorBar = result;
                        state.loaded = true;
                    } catch (e) {
                        if (e instanceof z.ZodError) console.log(e.message);
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
