import { z } from 'zod';

export const GetWidgetInfoByNameResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
        markup: z.any(),
    })).max(1),
    meta: z.any(),
});

export type GetWidgetInfoByNameResponse = z.infer<typeof GetWidgetInfoByNameResponseSchema>;
