import { Flex } from '@chakra-ui/react';
import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SimpleCarousel, useAppDispath, RichText } from 'shared';
import { CateringEventPageMainHeader } from './CateringEventPageMainHeader';
import { CateringEventPageActions, useCateringEventPageState } from '../../store';

export const CateringEventPageMain = () => {
    const { id } = useParams();
    const dispatch = useAppDispath();
    const { cateringEvent } = useCateringEventPageState();

    useLayoutEffect(() => {
        if (id) dispatch(CateringEventPageActions.getCateringEventByUid({ uid: id }));
    }, []);

    return (
        <Flex
            gap="2rem"
            padding="2rem"
            flexDirection="column"
        >
            <CateringEventPageMainHeader
                name={cateringEvent?.name || ''}
            />
            <Flex
                flexDirection="row"
                gap="2rem"
            >
                <RichText
                    flex="1 0 40%"
                    content={cateringEvent?.description || []}
                />
                <SimpleCarousel
                    flex="1 0 50%"
                    images={cateringEvent?.images?.map(({ url }) => url) || []}
                />
            </Flex>
        </Flex>
    );
};
