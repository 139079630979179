import { AxiosRequestConfig } from 'axios';
import { FindArticlesResponse, FindArticlesResponseSchema } from './responses/find-articles-response';
import { Api } from '../../../api/api';
import BaseError from '../../../utils/base-error';
import { ErrorResponseSchema } from '../../../api/base/responses/error-response';

export class ArticleApi extends Api {
    findArticles = async (
        page: number,
        categoryUID?: string,
        tagUID?: string,
    ): Promise<BaseError | FindArticlesResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/articles`,
            params: {
                populate: '*',
                'filters[category][documentId][$eq]': categoryUID,
                'filters[tags][documentId][$eq]': tagUID,
                'pagination[page]': page,
                'pagination[pageSize]': 3,
            },
        };
        const response = await this.makeCrud<FindArticlesResponse>(
            config,
            FindArticlesResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };
}

export const articleApiInstance = new ArticleApi();
