import {
    GridProps,
} from '@chakra-ui/react';
import { OutdoorBarArticlePortionModel } from 'entities';
import React, { useState } from 'react';
import { Carousel } from 'shared';
import { OutdoorBarPortionCard } from './OutdoorBarArticlePortionCard';

export type OutdoorBarArticlePortionPickerProps = {
    outdoorBarArticlePortions: OutdoorBarArticlePortionModel[]
} & GridProps & React.RefAttributes<HTMLDivElement>;

export const OutdoorBarArticlePortionPicker
: React.FC<OutdoorBarArticlePortionPickerProps> = ({
    outdoorBarArticlePortions,
}) => {
    const [currentPortionUid, setCurrentPortionUid] = useState(0);

    return (
        <Carousel
            items={outdoorBarArticlePortions?.map(({
                value,
                meassure,
                description,
                weight,
                price,
                currency,
            }, i) => ({
                key: i,
                element: (
                    <OutdoorBarPortionCard
                        value={value}
                        measure={meassure.toLocaleUpperCase()}
                        description={description}
                        weight={weight}
                        price={price}
                        currency={currency?.abbreviation}
                        isActive={i === currentPortionUid}
                    />
                ),
            })) || []}
            value={currentPortionUid}
            onValueChange={(uid) => setCurrentPortionUid(uid)}
        />
    );
};
