import { Flex, FlexProps } from '@chakra-ui/react';
import { CateringEventModel } from 'entities';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { TopicCard } from 'shared/ui';

type EventTypeBlockEventsListProps = {
    cateringEvents: CateringEventModel[]
} & FlexProps & React.RefAttributes<HTMLDivElement>

export const CateringEventFormatPageMainCateringEventsList
: React.FC<EventTypeBlockEventsListProps> = ({
    cateringEvents,
    ...props
}) => (
    <Flex
        flexDirection="column"
        gap="2rem"
        {...props}
    >
        {cateringEvents.map(({
            uid,
            name,
            description,
            images,
        }, index) => (
            <TopicCard
                key={uid}
                to={`/catering/events/${uid}`}
                title={name}
                description={description}
                imagesContainerType={(index % 2) === 0 ? 1 : 2}
                imagesSrcs={images?.map(({ url }) => url) || []}
            />
        ))}
    </Flex>
);
