import {
    Flex,
    FlexProps,
    Image,
    Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export type GridCardProps = {
    label: string,
    src?: string,
    to: string,
} & FlexProps & React.RefAttributes<HTMLDivElement>

export const GridCard: React.FC<GridCardProps> = ({
    label,
    src,
    to,
    ...props
}) => (
    <Flex
        position="relative"
        {...props}
    >
        <Image
            position="absolute"
            height="100%"
            width="100%"
            objectFit="cover"
            src={src}
        />
        <Flex
            height="100%"
            width="100%"
            zIndex={1}
        >
            <Text
                margin="auto auto 0 auto"
                padding="1rem"
                color="white"
                fontSize="1.5rem"
                fontWeight="900"
                textAlign="center"
            >
                <Link to={to}>
                    {label.toLocaleUpperCase()}
                </Link>
            </Text>
        </Flex>
    </Flex>
);
