import { createSlice } from '@reduxjs/toolkit';
import { z } from 'zod';
import { ImagesBlockState } from './images-block-state';
import BaseError from '../../../utils/base-error';
import { ImagesBlockActions } from './actions';
import { createGetBestImagesGroupFromGetGetBestImagesGroupApiResponse } from '../utils';

const initialState: ImagesBlockState = {
    loaded: false,
};

// eslint-disable-next-line import/prefer-default-export
export const imagesBlockSlice = createSlice({
    name: 'imagesBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            ImagesBlockActions.getBestImagesGroup.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    console.log('imagesBlockSlice BaseError', response);
                } else {
                    try {
                        console.log('imagesBlockSlice', response);
                        // eslint-disable-next-line max-len
                        state.bestImagesGroup = createGetBestImagesGroupFromGetGetBestImagesGroupApiResponse(
                            response.data,
                        );
                    } catch (e) {
                        if (e instanceof z.ZodError) {
                            console.log('DeliveryBlockActions.getDeliveryArticlesByCategoryAndTag', e);
                        }
                    }
                }
                state.loaded = true;
            },
        );
    },
});
