import { createSlice } from '@reduxjs/toolkit';
import { FAQsBlockState } from './faqs-block-state';
import FAQsBlockActions from './faqs-block-actions';
import BaseError from '../../../utils/base-error';
import { FAQModelSchema } from '../../../models/faq';

const initialState: FAQsBlockState = {
    loaded: false,
    faqs: [],
};

// eslint-disable-next-line import/prefer-default-export
export const fAQsBlockSlice = createSlice({
    name: 'fAQsBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(FAQsBlockActions.getFAQs.fulfilled, (state, action) => {
            const response = action.payload;

            if (response instanceof BaseError) {
                // TODO
            } else {
                // Валидация
                try {
                    const result = response.data.map(({
                        documentId,
                        question,
                        answer,
                    }) => FAQModelSchema.parse({
                        uid: documentId,
                        question,
                        answer,
                    }));

                    state.faqs = result;
                    state.loaded = true;
                } catch (e) {
                    // return new BaseError(400, String(e));
                    // TODO
                }
            }
        });
    },
});
