import { z } from 'zod';
import { DeliveryArticleModelSchema } from 'entities/delivery-article';
import { GetDeliveryArticleByUidApiResponseSchema } from '../store';

export const createDeliveryArticleFromGetDeliveryArticleByUidApiResponse = (
    (response: z.infer< typeof GetDeliveryArticleByUidApiResponseSchema>) => (
        DeliveryArticleModelSchema.parse({
            uid: response.data.documentId,
            name: response.data.name,
            description: response.data.description,
            weight: response.data.weight,
            price: response.data.price,
            images: response.data.images?.map(({ url }) => ({
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + url,
            })),
            elements: response.data.elements?.map(({
                name: name2,
                value,
                meassure,
            }) => ({
                name: name2,
                value,
                meassure,
            })),
            currency: response.data.currency && {
                name: response.data.currency.name,
                abbreviation: response.data.currency.abbreviation,
            },
            category: response.data.category && {
                name: response.data.category.name,
            },
            tags: response.data.tags?.map(({
                name: name2,
            }) => ({
                name: name2,
            })),
            recommendations: response.data.recommendations?.map(({
                documentId: documentId2,
                name: name2,
                weight: weight2,
                price: price2,
                images: images2,
                currency: currency2,
            }) => ({
                uid: documentId2,
                name: name2,
                weight: weight2,
                price: price2,
                image: images2?.[0] && {
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + String(images2?.[0].url),
                },
                currency: currency2 && {
                    name: currency2.name,
                    abbreviation: currency2.abbreviation,
                },
            })),
        })
    )
);
