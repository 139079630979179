import { AxiosRequestConfig } from 'axios';
import { Api } from '../api';
import { FindAwardsResponse, FindAwardsResponseSchema } from './responses/find-awards-response';
import { ErrorResponseSchema } from '../base/responses/error-response';
import BaseError from '../../utils/base-error';

export class AwardsApi extends Api {
    findAwards = async (): Promise<BaseError | FindAwardsResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/awards`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<FindAwardsResponse>(
            config,
            FindAwardsResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };
}

export const awardsApiInstance = new AwardsApi();
