import { z } from 'zod';

export const GetRoutesResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        url: z.string(),
        title: z.string().optional(),
    })),
    meta: z.any(),
});

export type GetRoutesResponse = z.infer<typeof GetRoutesResponseSchema>;
