import { z } from 'zod';

export const FindCrewMembersResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
        jobTitle: z.string(),
        description: z.any().optional(),
        image: z.object({
            url: z.string(),
        }).optional(),
    })),
    meta: z.any().optional(),
});

export type FindCrewMembersResponse = z.infer<typeof FindCrewMembersResponseSchema>;
