import axios, { AxiosRequestConfig } from 'axios';
import { Schema } from 'zod';
import { GetPageInfoByIdResponse, GetPageInfoByIdResponseSchema } from './responses/get-page-info-by-id-response';
import { ErrorResponseSchema } from './responses/error-response';
import BaseError from '../utils/base-error';
import { PageId } from '../shared/types/page-info';
import { GetIconsResponse, GetIconsResponseSchema } from './responses/get-icons-response';
import { GetRoutesResponse, GetRoutesResponseSchema } from './responses/get-routes-response';
import { GetWidgetInfoByNameResponse, GetWidgetInfoByNameResponseSchema } from './responses/get-widget-info-by-name-response';
import { FindOneResponse, FindOneResponseSchema } from './responses/find-one-response';
import { FindResponse, FindResponseSchema } from './responses/find-response';
import { ExecuteDatasourceResponse, ExecuteDatasourceResponseSchema } from './responses/execute-datasource-response';

export class Api {
    readonly host = process.env.REACT_APP_STRAPI_HOST!;

    readonly makeCrud = async<T> (
        config: AxiosRequestConfig,
        schema: Schema,
        errorSchema: Schema,
    ): Promise<T | BaseError> => {
        try {
            console.log(config);
            const response = await axios(config);
            console.log(response);
            try {
                return schema.parse(response.data);
            } catch (e) {
                try {
                    const { error } = errorSchema.parse(response.data);
                    return new BaseError(error.status, error.message);
                } catch (e1) {
                    return new BaseError(400, String(e1));
                }
            }
        } catch (e) {
            return new BaseError(400, String(e));
        }
    };

    getPageInfoById = async (id: PageId): Promise<GetPageInfoByIdResponse | BaseError> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/pages/${id}`,
        };
        return this.makeCrud<GetPageInfoByIdResponse>(
            config,
            GetPageInfoByIdResponseSchema,
            ErrorResponseSchema,
        );
    };

    getIcons = async (): Promise<GetIconsResponse | BaseError> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/icons`,
        };
        return this.makeCrud<GetIconsResponse>(
            config,
            GetIconsResponseSchema,
            ErrorResponseSchema,
        );
    };

    getRoutes = async (): Promise<GetRoutesResponse | BaseError> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/pages`,
            params: {
                'fields[0]': 'url',
            },
        };
        return this.makeCrud<GetRoutesResponse>(
            config,
            GetRoutesResponseSchema,
            ErrorResponseSchema,
        );
    };

    getWidgetInfoByName = async (
        name: string,
    ): Promise<GetWidgetInfoByNameResponse | BaseError> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/widgets`,
            params: {
                'filters[name][$eq]': name,
            },
        };
        return this.makeCrud<GetWidgetInfoByNameResponse>(
            config,
            GetWidgetInfoByNameResponseSchema,
            ErrorResponseSchema,
        );
    };

    findOne = async (
        collectionName: string,
        collectionId: string,
        params?: object,
    ): Promise<FindOneResponse | BaseError > => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/${collectionName}/${collectionId}`,
            params,
        };
        return this.makeCrud<FindOneResponse>(
            config,
            FindOneResponseSchema,
            ErrorResponseSchema,
        );
    };

    find = async (
        collectionName: string,
        params?: object,
    ): Promise<FindResponse | BaseError > => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/${collectionName}`,
            params,
        };
        return this.makeCrud<FindResponse>(
            config,
            FindResponseSchema,
            ErrorResponseSchema,
        );
    };

    executeDatasource = async (
        datasourceName: string,
        values?: Record<string, string | number | null | boolean>,
        params?: object,
    ): Promise<FindResponse | BaseError > => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/datasource-executor/${datasourceName}`,
            params,
            data: values,
        };
        return this.makeCrud<ExecuteDatasourceResponse>(
            config,
            ExecuteDatasourceResponseSchema,
            ErrorResponseSchema,
        );
    };
}

export const ApiInstance = new Api();
