/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useAppDispath } from 'shared';
import styles from './ImagesBlock.module.scss';
import {
    useImagesBlockState,
    ImagesBlockActions,
} from '../store';
import { Image } from '../../../components';

type CollageItemProps = {
    className?: string,
    image1?: {
        url?: string
    },
    image2?: {
        url?: string
    },
    image3?: {
        url?: string
    },
}

const Collage = ({
    className,
    image1,
    image2,
    image3,
}: CollageItemProps) => (
    <div className={cn(className, styles.collage)}>
        <div className={styles.collage__image1}>
            <Image
                url={image1?.url || ''}
            />
        </div>
        <div className={styles.collage__imageContainer}>
            <div className={styles.collage__image2}>
                <Image
                    url={image2?.url || ''}
                />
            </div>
            <div className={styles.collage__image3}>
                <Image
                    url={image3?.url || ''}
                />
            </div>
        </div>
    </div>
);

const createCollageItems = (images: { url: string }[]) => {
    const arr: React.JSX.Element[] = [];

    for (let i = 0; i < images.length; i += 4) {
        arr.push(
            <Image
                className={styles.imagesBlock__image}
                url={images.at(i)?.url || ''}
            />,
        );
        arr.push(
            <Collage
                image1={images.at(i + 1)}
                image2={images.at(i + 2)}
                image3={images.at(i + 3)}
            />,
        );
    }

    return arr;
};

export type ImagesBlockProps = {
    className?: string
}

export const ImagesBlock = ({
    className,
}: ImagesBlockProps) => {
    const { loaded, bestImagesGroup } = useImagesBlockState();
    const dispatch = useAppDispath();

    const [selected, setSelected] = useState(0);

    const listRef = useRef<HTMLDivElement>(null);
    const rem = window.getComputedStyle(document.documentElement).fontSize;

    const handleItemClick = (key: any) => {
        setSelected(key);
    };

    useLayoutEffect(() => {
        if (listRef.current) {
            const width = listRef.current.children.item(0)?.getBoundingClientRect().width;
            if (width) {
                listRef.current.scrollTo({
                    left: (selected - 1) * (width + 1.25 * parseFloat(rem)),
                    behavior: 'smooth',
                });
            }
        }
    }, [selected]);

    useLayoutEffect(() => {
        dispatch(ImagesBlockActions.getBestImagesGroup());
    }, []);

    console.log('ImagesBlock', bestImagesGroup);

    return loaded ? (
        <div className={cn(className, styles.imagesBlock)} ref={listRef}>
            {bestImagesGroup?.images && createCollageItems(
                bestImagesGroup?.images,
            ).map((element, index) => (
                <div
                    className={styles.imagesBlock__imageWrapper}
                    onClick={() => handleItemClick(index)}
                >
                    {element}
                </div>
            ))}
        </div>
    ) : null;
};
