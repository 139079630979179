import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiInstance } from '../../api/api';

const getIcons = createAsyncThunk(
    'getIcons',
    async () => {
        const response = await ApiInstance.executeDatasource('ALL_ICONS');
        return response;
    },
);

const getRoutes = createAsyncThunk(
    'getRoutes',
    async () => {
        const response = await ApiInstance.executeDatasource('ALL_ROUTES');
        return response;
    },
);

type OpenModalPayload = {
    modalName: string;
}

type OpenModalParams = {
    modalName: string;
}

const openModal = createAsyncThunk<OpenModalPayload, OpenModalParams>(
    'openModal',
    async ({ modalName }) => {
        try {
            console.log('OPEN_MODEASDASDAD');
            return { modalName };
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
);

type CloseModalPayload = {
    id: number;
}

const closeModal = createAction<CloseModalPayload>('closeModal');

type SetLoadedPayload = {
    loaded: boolean;
}

const setLoaded = createAction<SetLoadedPayload>('setLoaded');

type SetValueToDataBunchRequest = {
    id: string;
    value: any,
}

const setValueToDataBunch = createAction<SetValueToDataBunchRequest>('setValue');

// type GetWidgetMarkupPayload = {
//     markup: Markup;
// }

const APP_ACTIONS = {
    GET_ICONS: getIcons,
    GET_ROUTES: getRoutes,
    SET_LOADED: setLoaded,
    OPEN_MODAL: openModal,
    CLOSE_MODAL: closeModal,
    SET_VALUE_TO_DATABUNCH: setValueToDataBunch,
};

export default APP_ACTIONS;
