import { AxiosRequestConfig } from 'axios';
import { FindCateringFormatsResponse, FindCateringFormatsResponseSchema } from './responses/find-catering-formats-response';
import { Api } from '../../../api/api';
import BaseError from '../../../utils/base-error';
import { ErrorResponseSchema } from '../../../api/base/responses/error-response';
import { CateringFormatApiFindOneResponse, CateringFormatApiFindOneResponseSchema } from './responses/catering-format-api-find-one-response';

export class CateringFormatApi extends Api {
    findCateringFormats = async (): Promise<BaseError | FindCateringFormatsResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/formats`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<FindCateringFormatsResponse>(
            config,
            FindCateringFormatsResponseSchema,
            ErrorResponseSchema,
        );

        console.log('LOL3', response);

        return response;
    };

    override findOne = async (
        uid: string,
    ): Promise<BaseError | CateringFormatApiFindOneResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/formats/${uid}`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<CateringFormatApiFindOneResponse>(
            config,
            CateringFormatApiFindOneResponseSchema,
            ErrorResponseSchema,
        );

        console.log('LOL3', response);

        return response;
    };
}

export const cateringFormatApiInstance = new CateringFormatApi();
