import React from 'react';
import cn from 'classnames';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import styles from './Awardcard.module.scss';
import { Image } from '../image/Image';

export type AwardCardProps = {
    className?: string
    years: number[],
    description: any,
    image?: {
        url: string
    }
}

export const AwardCard = ({
    className,
    years,
    description,
    image,
}: AwardCardProps) => (
    <div className={cn(className, styles.awardCard)}>
        <ul className={styles.awardCard__yearsList}>
            {years.map((year) => (
                <li>
                    {year}
                </li>
            ))}
        </ul>
        <div>
            <BlocksRenderer content={description} />
            <Image className={styles.awardCard__image} url={image?.url || ''} />
        </div>
    </div>
);
