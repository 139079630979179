import {
    Button,
    Grid,
    GridProps,
    Heading,
    Text,
} from '@chakra-ui/react';
import { Icon, IconsNames } from 'components';
import React from 'react';

export type DeliveryArticlePageMainHeaderProps = {
    title: string
} & GridProps & React.RefAttributes<HTMLDivElement>

export const DeliveryArticlePageMainHeader: React.FC<DeliveryArticlePageMainHeaderProps> = ({
    title,
    ...props
}) => (
    <Grid
        templateColumns="1fr auto"
        {...props}
    >
        <Heading
            flex={1}
            textDecoration="underline"
        >
            {title}
        </Heading>
        <Button
            gap="1rem"
        >
            <Icon
                iconName={IconsNames.Track}
            />
            <Text
                fontSize="1.25rem"
            >
                ДОСТАВКА И ОПЛАТА
            </Text>
        </Button>
    </Grid>
);
