import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Map, Placemark } from '@pbe/react-yandex-maps';
import styles from './ContactsBlock.module.scss';
import { Icon, IconsNames, LeadForm } from '../../../components';

export type ContactsBlockProps = {
    className?: string
}

export const ContactsBlock = ({
    className,
}: ContactsBlockProps) => (
    <div className={cn(className, styles.contactsBlock)}>
        <LeadForm
            className={styles.contactsBlock__leadForm}
            title="ЕЩЕ ОСТАЛИСЬ ВОПРОСЫ?"
            subtitle="Напишите сейчас и мы поможем их решить"
            pdf={{ url: '' }}
            hasTextArea
        />
        <div className={styles.contactsBlock__divider} />
        <div className={styles.contactsBlock__infoSection}>
            <span className={styles.contactsBlock__title}>
                Контакты
            </span>
            <div className={styles.contactsBlock__contacts}>
                <div className={styles.iconText}>
                    <Icon iconName={IconsNames.Phone} />
                    <span>
                        +7 (499) 647-61-64
                    </span>
                </div>
                <div className={styles.iconText}>
                    <Icon iconName={IconsNames.Mail} />
                    <span>
                        info@corsocatering.ru
                    </span>
                </div>
            </div>
            <span className={styles.contactsBlock__title}>
                Мы в социальных сетях
            </span>
            <div className={styles.contactsBlock__socialMedias}>
                <Link to="/a">
                    <Icon iconName={IconsNames.Telegram} />
                </Link>
                <Link to="/">
                    <Icon iconName={IconsNames.VK} />
                </Link>
            </div>
            <span className={styles.contactsBlock__title}>
                Как до нас добраться
            </span>
            <div className={styles.contactsBlock__address}>
                <div className={styles.iconText}>
                    <Icon iconName={IconsNames.Map} />
                    <span>
                        Москва, ул. Зорге, 7г
                    </span>
                </div>
                <div className={styles.contactsBlock__mapWrapper}>
                    <Map
                        className={styles.contactsBlock__map}
                        defaultState={{
                            center: [55.78101263554019, 37.51089135581785],
                            zoom: 17,
                        }}
                    >
                        <Placemark
                            geometry={[55.78101263554019, 37.51089135581785]}
                        />
                    </Map>
                    <div className={styles.contactsBlock__mapDivider}>
                        <div />
                        <span>НАШ АДРЕС</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
