import { z } from 'zod';

export const ErrorResponseSchema = z.object({
    data: z.null(),
    error: z.object({
        status: z.number(),
        name: z.string(),
        message: z.string(),
    }),
});

export type ErrorResponse = z.infer<typeof ErrorResponseSchema>
