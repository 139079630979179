import { createSlice } from '@reduxjs/toolkit';
import BaseError from '../../../utils/base-error';
import { CrewMembersBlockState } from './crew-members-block-state';
import CrewMembersBlockActions from './crew-members-block-actions';
import { CrewMemberModelSchema } from '../../../models/crew-member';

const initialState: CrewMembersBlockState = {
    loaded: false,
    crewMembers: [],
};

// eslint-disable-next-line import/prefer-default-export
export const crewMembersBlockSlice = createSlice({
    name: 'crewMembersBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(CrewMembersBlockActions.getCrewMembers.fulfilled, (state, action) => {
            const response = action.payload;

            if (response instanceof BaseError) {
                // TODO
            } else {
                // Валидация
                try {
                    const result = response.data.map(({
                        documentId,
                        description,
                        image,
                        name,
                        jobTitle,
                    }) => CrewMemberModelSchema.parse({
                        uid: documentId,
                        description,
                        image: image && {
                            url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + image.url,
                        },
                        name,
                        jobTitle,
                    }));

                    state.crewMembers = result;
                    state.loaded = true;
                } catch (e) {
                    // return new BaseError(400, String(e));
                    // TODO
                }
            }
        });
    },
});
