import {
    getPopularServicesGroup,
    GetPopularServicesGroupApiResponseSchema,
} from './get-popular-services-group';
import {
    getCompanyInformation,
    GetCompanyInformationApiResponseSchema,
} from './get-company-information';

export {
    GetPopularServicesGroupApiResponseSchema,
    GetCompanyInformationApiResponseSchema,
};

export const HomePageActions = {
    getPopularServicesGroup,
    getCompanyInformation,
};
