import { z } from 'zod';

export const OutdoorBarArticleElementModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    meassure: z.string(),
    value: z.number(),
});

export type OutdoorBarArticleElementModel = z.infer<typeof OutdoorBarArticleElementModelSchema>;
