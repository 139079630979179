import {
    Grid,
    GridProps,
    Heading,
    Text,
} from '@chakra-ui/react';
import { CulinaryStudioArticleSetModel } from 'entities';
import React, { useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Tabs, TabsItemProps } from 'shared/ui/Tabs';

const createTabsItems = (sets: CulinaryStudioArticleSetModel[]): TabsItemProps[] => sets.map(({
    name,
    elements,
}, i) => ({
    key: i,
    labelComponent: (
        <Text fontSize="1.5rem">{name.toLocaleUpperCase()}</Text>
    ),
    contentComponent: (
        <Grid
            gap="0.25rem"
        >
            {elements?.map(({
                name,
                meassure,
                value,
            }) => (
                <Grid
                    background="#DDDDDD"
                    padding="1rem 2rem"
                    templateColumns="1fr auto"
                >
                    <Text fontSize="1rem">{name}</Text>
                    <Text fontSize="1rem" fontWeight={900}>
                        {value}
                        {meassure}
                    </Text>
                </Grid>
            ))}
        </Grid>
    ),
}));

export type CulinaryStudioArticlePageMainSetsPickerProps = {
    culinaryStudioArticleSets: CulinaryStudioArticleSetModel[]
} & GridProps & React.RefAttributes<HTMLDivElement>;

export const CulinaryStudioArticlePageMainSetsPicker
: React.FC<CulinaryStudioArticlePageMainSetsPickerProps> = ({
    culinaryStudioArticleSets,
    ...props
}) => {
    const [currentSetUid, setCurrentSetUid] = useState(0);

    const memoTabsItems = useMemo(() => (
        createTabsItems(culinaryStudioArticleSets)
    ), [culinaryStudioArticleSets]);

    return (
        <Grid
            gap="2rem"
            {...props}
        >
            <Heading
                size="md"
            >
                Вы можете выбрать
            </Heading>
            <Tabs
                onValueChange={(uid) => setCurrentSetUid(uid)}
                value={currentSetUid}
                items={memoTabsItems}
            />
        </Grid>
    );
};
