/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { useAppDispath } from 'shared';
import styles from './FAQsBlock.module.scss';
import { useFAQsBlockState } from '../model/faqs-block-hooks';
import FAQsBlockActions from '../model/faqs-block-actions';
import { Icon, IconsNames } from '../../../components';

type FAQCardProps = {
    className?: string
    index: any
    question: string
    answer: any
}

const FAQCard = ({
    className,
    index,
    question,
    answer,
}: FAQCardProps) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        console.log(isOpen);
    }, [isOpen]);
    return (
        <div className={cn(className, styles.fAQCard)}>
            <div
                className={styles.fAQCard__question}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>
                    {index}
                    .
                    {' '}
                    {question}
                </span>
                <Icon
                    className={cn(
                        styles.fAQCard__icon,
                        isOpen
                            ? styles.fAQCard__icon_open
                            : styles.fAQCard__icon_close,
                    )}
                    iconName={IconsNames.Plus}
                />
            </div>
            <div className={cn(
                styles.fAQCard__answerWrapper,
                isOpen
                    ? styles.fAQCard__answerWrapper_open
                    : styles.fAQCard__answerWrapper_close,
            )}
            >
                <BlocksRenderer
                    content={answer}
                />
            </div>
        </div>
    );
};

export type FAQsBlockProps = {
    className?: string
}

export const FAQsBlock = ({
    className,
}: FAQsBlockProps) => {
    const { faqs } = useFAQsBlockState();
    const dispatch = useAppDispath();

    useLayoutEffect(() => {
        dispatch(FAQsBlockActions.getFAQs());
    }, []);

    return (
        <div className={cn(className, styles.fAQsBlock)}>
            <span className={styles.fAQsBlock__title}>
                ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ
            </span>
            <div className={styles.fAQsBlock__main}>
                {faqs.map(({ answer, question }, index) => (
                    <FAQCard
                        className={styles.fAQsBlock__card}
                        index={index + 1}
                        answer={answer}
                        question={question}
                    />
                ))}
            </div>
        </div>
    );
};
