import { z } from 'zod';

export const CompanyInformationModelSchema = z.object({
    name: z.string(),
    title: z.string(),
    description: z.any().optional().nullable(),
    phone: z.string(),
    address: z.string(),
    email: z.string(),
    logoImage: z.object({
        url: z.string(),
    }),
    mainImage: z.object({
        url: z.string(),
    }),
});

export type CompanyInformationModel
= z.infer<typeof CompanyInformationModelSchema>;
