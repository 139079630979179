import { z } from 'zod';

export const FindFAQsResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        question: z.string(),
        answer: z.any().optional(),
    })),
    meta: z.any().optional(),
});

export type FindFAQsResponse = z.infer<typeof FindFAQsResponseSchema>;
