import React, { useLayoutEffect } from 'react';
import {
    Button,
    Flex,
    FlexProps,
    Grid,
} from '@chakra-ui/react';
import {
    RichText,
    SimpleCarousel,
    useAppDispath,
} from 'shared';
import { useParams } from 'react-router-dom';
import { LeadSection, LeadSectionType } from 'features';
import { CulinaryStudioArticlePageActions, useCulinaryStudioArticlePageState } from '../../store';
import { CulinaryStudioArticlePageMainSetsPicker } from './CulinaryStudioArticlePageMainSetsPicker';
import { CulinaryStudioArticlePageMainPortionPicker } from './CulinaryStudioArticlePageMainPortionPicker';
import { CulinaryStudioArticlePageMainHeader } from './CulinaryStudioArticlePageMainHeader';
import { CulinaryStudioArticlePageMainIncludedServicesSection } from './CulinaryStudioArticlePageMainIncludedServicesSection';
import { CulinaryStudioArticlePageMainNonIncludedServicesSection } from './CulinaryStudioArticlePageMainNonIncludedServicesSection';
import { CulinaryStudioArticlePageMainRecommendationsSection } from './CulinaryStudioArticlePageMainRecommendationsSection';

export type CulinaryStudioArticlePageMainProps = FlexProps & React.RefAttributes<HTMLDivElement>

export const CulinaryStudioArticlePageMain = (props: CulinaryStudioArticlePageMainProps) => {
    const { id } = useParams();
    const dispatch = useAppDispath();
    const { culinaryStudioArticle } = useCulinaryStudioArticlePageState();

    useLayoutEffect(() => {
        if (id) {
            dispatch(CulinaryStudioArticlePageActions.getCulinaryStudioArticleByUid({
                uid: id,
            }));
        }
    }, []);
    return (
        <Flex
            {...props}
            flexDirection="column"
            gap="2rem"
            paddingTop="2rem"
        >
            <CulinaryStudioArticlePageMainHeader
                title={culinaryStudioArticle?.name || ''}
                padding="0 2rem"
            />
            <SimpleCarousel
                images={culinaryStudioArticle?.images?.map(({ url }) => url) || []}
                padding="0 2rem"
            />
            <Grid
                templateColumns={{
                    base: '1fr',
                    md: '1fr 2fr',
                }}
                gap="2rem"
                padding="0 2rem"
            >
                <Grid>
                    <RichText
                        fontSize="1rem"
                        content={culinaryStudioArticle?.description}
                    />
                    <Button>
                        Добавить в заказ
                    </Button>
                </Grid>
                <CulinaryStudioArticlePageMainPortionPicker
                    culinaryStudioArticlePortions={culinaryStudioArticle?.portions || []}
                />
            </Grid>
            <CulinaryStudioArticlePageMainSetsPicker
                culinaryStudioArticleSets={culinaryStudioArticle?.sets || []}
                padding="0 2rem"
            />
            <CulinaryStudioArticlePageMainIncludedServicesSection
                culinaryStudioArticleIncludedServices={
                    culinaryStudioArticle?.includedServices || []
                }
                padding="0 2rem"
            />
            <CulinaryStudioArticlePageMainNonIncludedServicesSection
                culinaryStudioArticleNonIncludedServices={
                    culinaryStudioArticle?.nonIncludedServices || []
                }
                padding="0 2rem"
            />
            <LeadSection
                type={LeadSectionType.Small}
                title="НЕ МОЖЕТЕ ОПРЕДЕЛИТЬСЯ?"
                buttonLabel="Помогите выбрать"
                padding="2rem"
            />
            <CulinaryStudioArticlePageMainRecommendationsSection
                culinaryStudioArticleRecommendations={
                    culinaryStudioArticle?.recommendations || []
                }
                padding="0 2rem 2rem 2rem"
            />
        </Flex>
    );
};
