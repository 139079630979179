import {
    AwardsBlock,
    ContactsBlock,
    CrewMembersBlock,
    DegustationBlock,
    FAQsBlock,
    Footer,
    Header,
    ImagesBlock,
    NavigationBlock,
    PortfolioBlock,
    TopHeader,
    PortfolioEventDetailBlock,
} from 'widgets';
import { PageLayout } from 'components';
import React, { memo } from 'react';

const MemoTopHeader = memo(TopHeader);
const MemoHeader = memo(Header);
const MemoFooter = memo(Footer);
const MemoAwardsBlock = memo(AwardsBlock);
const MemoCrewMembersBlock = memo(CrewMembersBlock);
const MemoPortfolioBlock = memo(PortfolioBlock);
const MemoFAQsBlock = memo(FAQsBlock);
const MemoDegustationBlock = memo(DegustationBlock);
const MemoImagesBlock = memo(ImagesBlock);
const MemoContactsBlock = memo(ContactsBlock);
const MemoNavigationBlock = memo(NavigationBlock);
const MemoPortfolioEventDetailBlock = memo(PortfolioEventDetailBlock);

export const PortfolioEventDetailPage = () => (
    <PageLayout>
        <MemoTopHeader />
        <MemoHeader />
        <MemoPortfolioEventDetailBlock />
        <MemoAwardsBlock />
        <MemoImagesBlock />
        <MemoDegustationBlock />
        <MemoCrewMembersBlock />
        <MemoPortfolioBlock />
        <MemoContactsBlock />
        <MemoFAQsBlock />
        <MemoNavigationBlock />
        <MemoFooter />
    </PageLayout>
);
