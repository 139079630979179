import { createSlice } from '@reduxjs/toolkit';
import { DegustationBlockState } from './degustation-block-state';

const initialState: DegustationBlockState = {
    loaded: false,
};

// eslint-disable-next-line import/prefer-default-export
export const degustationBlockSlice = createSlice({
    name: 'degustationBlockSlice',
    initialState,
    reducers: {},
});
