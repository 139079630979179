import {
    Tabs as ChakraTabs,
    Flex,
    Grid,
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

export type TabsItemProps = {
    key: any;
    labelComponent: React.ReactNode;
    contentComponent: React.ReactNode;
}

export type TabsProps = {
    value: any;
    onValueChange: (v: any) => void;
    items: TabsItemProps[]
} & ChakraTabs.RootProps & React.RefAttributes<HTMLDivElement>;

export const Tabs: React.FC<TabsProps> = ({
    value,
    onValueChange,
    items,
    ...props
}) => {
    const listRef = useRef<HTMLDivElement>(null);
    const rem = window.getComputedStyle(document.documentElement).fontSize;

    useEffect(() => {
        if (listRef.current) {
            const index = items.findIndex((item) => item.key === value);

            if (index > -1) {
                let position = 0;
                for (let i = 0; i < index - 1; i++) {
                    const width = listRef.current?.children.item(0)
                        ?.children?.item(i)
                        ?.getBoundingClientRect().width;
                    if (width) position += width + 1.25 * parseFloat(rem);
                }
                listRef.current.scrollTo({
                    left: position,
                    behavior: 'smooth',
                });
            }
        }
    }, [value]);

    return (
        <ChakraTabs.Root
            visual="plain"
            size="lg"
            variant="plain"
            value={value}
            onValueChange={(e: any) => onValueChange(e.value)}
            {...props}
        >
            <Grid>
                <Flex overflow="scroll" scrollbar="hidden" ref={listRef}>
                    <ChakraTabs.List gap="1.25rem">
                        {items.map(({ key, labelComponent }) => (
                            <ChakraTabs.Trigger key={key} value={key} transition="0.5s" whiteSpace="nowrap">
                                {labelComponent}
                            </ChakraTabs.Trigger>
                        ))}
                    </ChakraTabs.List>
                </Flex>
            </Grid>
            {items.map(({ key, contentComponent }) => (
                <ChakraTabs.Content value={key} key={key} transition="0.5s">
                    {contentComponent}
                </ChakraTabs.Content>
            ))}
        </ChakraTabs.Root>
    );
};
