/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef } from 'react';
import {
    Flex,
    Grid,
    GridProps,
} from '@chakra-ui/react';
// eslint-disable-next-line no-restricted-imports
import { useComputedStyle } from 'shared/hooks';

/**
 * Пропсы для обёртки дочерних элементов карусели
 */
type CarouselItemProps = {
    key: any,
    element: React.JSX.Element,
    onClick?: () => void
} & GridProps & React.RefAttributes<HTMLDivElement>

/**
 * Компонент обёртки дочерних элементов карусели
 * @param {CarouselItemProps} param0
 * @returns
 */
const CarouselItem: React.FC<CarouselItemProps> = ({
    key,
    element,
    onClick,
    ...props
}) => (
    <Grid
        key={key}
        onClick={onClick}
        {...props}
    >
        {element}
    </Grid>
);

/**
 * Пропсы основного компонента
 */
export type CarouselProps = {
    items: CarouselItemProps[]
    value: any,
    onValueChange: (key: any) => void
} & GridProps & React.RefAttributes<HTMLDivElement>

/**
 * Основной компонент карусели
 *
 * @param {CarouselProps} param0
 * @returns
 */
export const Carousel: React.FC<CarouselProps> = ({
    items,
    value,
    onValueChange,
    ...props
}) => {
    const listRef = useRef<HTMLDivElement>(null);
    const mainContainerRef = useRef<HTMLDivElement>(null);

    const { fontSize: rem } = useComputedStyle();

    const handleItemClick = (key: any) => {
        onValueChange(key);
    };

    const mainContainerRect = mainContainerRef.current?.getBoundingClientRect();

    const itemWidth = useMemo(() => (
        mainContainerRect && ((mainContainerRect.width - 2.5 * parseFloat(rem)) / 3)
    ), [mainContainerRect]);

    // Эффект для скрола
    useEffect(() => {
        if (listRef.current) {
            const index = items.findIndex((item) => item.key === value);
            if (itemWidth && index > -1) {
                listRef.current.scrollTo({
                    left: (index - 1) * (itemWidth + 1.25 * parseFloat(rem)),
                    behavior: 'smooth',
                });
            }
        }
    }, [value]);
    return (
        <Grid
            ref={mainContainerRef}
            {...props}
        >
            <Flex
                ref={listRef}
                flexDirection="row"
                position="relative"
                columnGap="1.25rem"
                overflow="hidden"
            >
                {items.map(({ key, element }) => (
                    <CarouselItem
                        transform={key !== value ? 'scale(0.95)' : undefined}
                        key={key}
                        element={element}
                        onClick={() => handleItemClick(key)}
                        flex={`1 0 ${itemWidth}px`}
                        cursor="pointer"
                        transition="0.5s"
                    />
                ))}
            </Flex>
        </Grid>
    );
};
