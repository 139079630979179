import { createAsyncThunk } from '@reduxjs/toolkit';
import { awardsApiInstance } from '../../../api';

const getAwards = createAsyncThunk(
    'getAwards',
    async () => {
        const response = await awardsApiInstance.findAwards();
        return response;
    },
);

const AwardsBlockActions = {
    getAwards,
};

export default AwardsBlockActions;
