import { z } from 'zod';
import { CateringFormatModelSchema } from 'entities/catering-format';
import { GetCateringFormatsApiResponseSchema } from '../store';

export const createCateringFormatsFromGetCateringFormatsApiResponse = (
    (response: z.infer< typeof GetCateringFormatsApiResponseSchema>) => (
        response.data?.map(({
            documentId,
            name,
            description,
            images,
        }) => (
            CateringFormatModelSchema.parse({
                uid: documentId,
                name,
                description,
                images: images?.map(({ url }) => ({
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + url,
                })),
            })
        ))
    )
);
