import {
    getCateringFormatByUid,
    GetCateringFormatByUidApiResponseSchema,
} from './get-catering-cormat-by-uid';

export {
    GetCateringFormatByUidApiResponseSchema,
};

export const CateringFormatPageActions = {
    getCateringFormatByUid,
};
