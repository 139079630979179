import { z } from 'zod';

export const FindImagesBlockInfoResponseSchema = z.object({
    data: z.object({
        documentId: z.string(),
        images: z.array(z.object({
            url: z.string(),
        })).optional(),
    }),
    meta: z.any().optional(),
});

export type FindImagesBlockInfoResponse = z.infer<typeof FindImagesBlockInfoResponseSchema>;
