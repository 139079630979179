import { z } from 'zod';

export const OutdoorBarArticleElementsSetModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    elements: z.array(z.object({
        uid: z.string(),
    })).optional().nullable(),
});

export type OutdoorBarArticleElementsSetModel = z.infer<
typeof OutdoorBarArticleElementsSetModelSchema>;
