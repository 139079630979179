import { z } from 'zod';
import { DeliveryArticleCategoryModelSchema } from 'entities/delivery-article-category';
import { GetDeliveryArticleTagsApiResponseSchema } from '../model/actions';

export const createDeliveryArticleTagsFromGetDeliveryArticleTagsApiResponse = (
    (response: z.infer< typeof GetDeliveryArticleTagsApiResponseSchema>) => (
        response.data.map(({
            documentId,
            name,

        }) => DeliveryArticleCategoryModelSchema.parse({
            uid: documentId,
            name,
        }))
    )
);
