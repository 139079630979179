// eslint-disable-next-line no-restricted-imports
import { BaseEntityApi } from 'shared/api';

export type OutdoorBarArticleElementDto = {
    documentId?: string,
    name?: string,
    meassure?: string,
    value?: number,
}

export class OutdoorBarArticleElementEntityApi
    extends BaseEntityApi<OutdoorBarArticleElementDto> {
    constructor() {
        super('outdoor-bar-article-categories');
    }
}

export const outdoorBarArticleElementEntityApiInstance = new OutdoorBarArticleElementEntityApi();
