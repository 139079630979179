import { createSlice } from '@reduxjs/toolkit';
import { SweetShopArticlesBlockState } from './sweet-shop-articles-block-state';
import { SweetShopArticlesBlockActions } from './sweet-shop-articles-block-actions';
import BaseError from '../../../utils/base-error';
import {
    parseSweetShopArticleCategoryDtoToDomain,
    parseSweetShopArticleCategoryTagDtoToDomain,
    parseSweetShopArticleDtoToDomain,
} from '../../../entities';

const initialState: SweetShopArticlesBlockState = {
    loaded: false,
    sweetShopArticleCategoryTags: [],
    sweetShopArticles: [],
};

export const sweetShopArticlesBlockSlice = createSlice({
    name: 'sweetShopArticlesBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /**
         * Обработчик получения списка типов баров (коллекция OutdoorBarType)
         */
        builder.addCase(
            SweetShopArticlesBlockActions.getSweetShopArticleCategoryByUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log(response);
                        const result = parseSweetShopArticleCategoryDtoToDomain(response.data);

                        console.log(result);

                        state.sweetShopArticleCategory = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
        /**
         * Обработчик получения списка типов баров (коллекция OutdoorBarType)
         */
        builder.addCase(
            SweetShopArticlesBlockActions.getSweetShopArticleCategoryTagsByCategoryUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log(response);
                        const result = response.data.map((dto) => (
                            parseSweetShopArticleCategoryTagDtoToDomain(dto)
                        ));

                        console.log(result);

                        state.sweetShopArticleCategoryTags = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );

        /**
         * Обработчик получения списка типов баров (коллекция OutdoorBarType)
         */
        builder.addCase(
            SweetShopArticlesBlockActions.getSweetShopArticlesByCategoryUidAndTagUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log(response);
                        const result = response.data.map((dto) => (
                            parseSweetShopArticleDtoToDomain(dto)
                        ));

                        console.log(result);

                        state.sweetShopArticles = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
