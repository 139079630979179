import { z } from 'zod';

export const DeliveryArticleElementModelSchema = z.object({
    name: z.string(),
    value: z.number(),
    meassure: z.string(),
});

export type DeliveryArticleElementModel
= z.infer<typeof DeliveryArticleElementModelSchema>;
