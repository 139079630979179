import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCateringEventsByFormatUidApiResponseSchema = z.object({
    data: z.array(
        z.object({
            documentId: z.string(),
            name: z.string(),
            description: z.any(),
            images: z.array(
                z.object({
                    url: z.string(),
                }),
            ).optional().nullable(),
            format: z.object({
                name: z.string(),
            }).optional().nullable(),
        }),
    ),
});

type GetCateringEventsByFormatUidActionResponse
    = ApiResponse<z.infer<typeof GetCateringEventsByFormatUidApiResponseSchema>>
    | BaseError;

type GetCateringEventsByFormatUidActionRequest = {
    formatUid: string,
}

export const getCateringEventsByFormatUid = createAsyncThunk<
    GetCateringEventsByFormatUidActionResponse,
    GetCateringEventsByFormatUidActionRequest
>(
    'CateringEventFormatActionsGetCateringEventsByFormatUid',
    async ({
        formatUid,
    }) => apiInstance.get(
        '/catering-events',
        {
            'filters[format][documentId][$eq]': formatUid,
            'populate[images][fields]': 'url',
            'populate[format][fields]': '*',
        },
        GetCateringEventsByFormatUidApiResponseSchema,
    ),
);
