import { z } from 'zod';
// eslint-disable-next-line no-restricted-imports
import { ImageModelSchema } from 'shared/schemas';
import { EventTypeModelSchema } from 'entities/event-type';

export const EventModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any(),
    images: z.array(ImageModelSchema).nullable().optional(),
    eventTypeUid: EventTypeModelSchema.nullable().optional(),
});

export type EventModel = z.infer<typeof EventModelSchema>;
