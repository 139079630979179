import { createSlice } from '@reduxjs/toolkit';
import BaseError from 'utils/base-error';
import { z } from 'zod';
import { createCulinaryStudioArticleCategoriesFromGetCulinaryStudioArticleCategoriesApiResponse } from '../utils';
import { CulinaryStudioArticlePageState } from './state';
import { CulinaryStudioArticlePageActions } from './actions';

const initialState: CulinaryStudioArticlePageState = {
    loaded: false,
};

export const culinaryStudioArticlePageSlice = createSlice({
    name: 'culinaryStudioArticlePageSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        /**
         * Получение записи по uid
         */
        builder.addCase(
            CulinaryStudioArticlePageActions.getCulinaryStudioArticleByUid.fulfilled,
            (state, action) => {
                const response = action.payload;
                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log('culinaryStudioArticlePageSlice', response);
                        // eslint-disable-next-line max-len
                        const result = createCulinaryStudioArticleCategoriesFromGetCulinaryStudioArticleCategoriesApiResponse(
                            response.data,
                        );
                        console.log('culinaryStudioArticlePageSlice', result);
                        state.culinaryStudioArticle = result;
                        state.loaded = true;
                    } catch (e) {
                        if (e instanceof z.ZodError) console.log(e.message);
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
