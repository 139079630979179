import { z } from 'zod';

export const CateringEventFormatModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any().optional(),
    image: z.object({
        url: z.string(),
    }).optional().nullable(),
    category: z.object({
        name: z.string(),
    }).optional().nullable(),
});

export type CateringEventFormatModel = z.infer<typeof CateringEventFormatModelSchema>;
