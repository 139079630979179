import { z } from 'zod';
import { CulinaryStudioArticleModelSchema } from 'entities/culinary-studio-article';
import { GetCulinaryStudioArticleByUidApiResponseSchema } from '../store';

// eslint-disable-next-line max-len
export const createCulinaryStudioArticleCategoriesFromGetCulinaryStudioArticleCategoriesApiResponse = (
    (response: z.infer< typeof GetCulinaryStudioArticleByUidApiResponseSchema>) => (
        CulinaryStudioArticleModelSchema.parse({
            uid: response.data.documentId,
            name: response.data.name,
            description: response.data.description,
            category: response.data.category && {
                uid: response.data.category.documentId,
                name: response.data.category.name,
            },
            images: response.data.images?.map(({ url }) => ({
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + url,
            })),
            portions: response.data.portions?.map(({
                value,
                meassure,
                description,
                weight,
                price,
                currency,
            }) => ({
                value,
                meassure,
                description,
                weight,
                price,
                currency: currency && {
                    uid: currency.documentId,
                    name: currency.name,
                    abbreviation: currency.abbreviation,
                },
            })),
            sets: response.data.sets?.map(({
                name,
                elements,
            }) => ({
                name,
                elements: elements?.map(({
                    name: name2,
                    meassure,
                    value,
                }) => ({
                    name: name2,
                    meassure,
                    value,
                })),
            })),
            includedServices: response.data.includedServices?.map(({
                name,
                description,
            }) => ({
                name,
                description,
            })),
            nonIncludedServices: response.data.nonIncludedServices?.map(({
                name,
            }) => ({
                name,
            })),
            recommendations: response.data.recommendations?.map(({
                documentId,
                name,
                weight,
                price,
                currency,
                images,
            }) => ({
                uid: documentId,
                name,
                weight,
                price,
                currency: currency && {
                    uid: currency.documentId,
                    name: currency.name,
                    abbreviation: currency.abbreviation,
                },
                image: images && {
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST!
                    + images[0].url,
                },
            })),
        })
    )
);
