import { z } from 'zod';
import { CulinaryStudioArticleCategoryModelSchema } from 'entities/culinary-studio-article-category';
import { GetCulinaryStudioArticleCategoriesApiResponseSchema } from '../store';

// eslint-disable-next-line max-len
export const createCulinaryStudioArticleCategoriesFromGetCulinaryStudioArticleCategoriesApiResponse = (
    (response: z.infer< typeof GetCulinaryStudioArticleCategoriesApiResponseSchema>) => (
        response.data.map(({
            documentId,
            name,
        }) => CulinaryStudioArticleCategoryModelSchema.parse({
            uid: documentId,
            name,
        }))
    )
);
