import { z } from 'zod';
import { DeliveryArticleCategoryModelSchema } from 'entities/delivery-article-category';
import { GetDeliveryArticleCategoriesApiResponseSchema } from '../model/actions';

export const createDeliveryArticleCategoriesFromGetDeliveryArticleCategoriesApiResponse = (
    (response: z.infer< typeof GetDeliveryArticleCategoriesApiResponseSchema>) => (
        response.data.map(({
            documentId,
            name,

        }) => DeliveryArticleCategoryModelSchema.parse({
            uid: documentId,
            name,
        }))
    )
);
