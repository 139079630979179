import { z } from 'zod';
import { PopularServicesGroupCateringModelSchema } from './popular-services-group-catering-model';
import { PopularServicesGroupDeliveryModelSchema } from './popular-services-group-delivery-model';
import { PopularServicesGroupCulinaryStudioModelSchema } from './popular-services-group-culinary-studio-model';
import { PopularServicesGroupOutdoorBarModelSchema } from './popular-services-group-outdoor-bar-model';
import { PopularServicesGroupSweetShopModelSchema } from './popular-services-group-sweet-shop-model';

export const PopularServicesGroupModelSchema = z.object({
    catering: PopularServicesGroupCateringModelSchema.optional().nullable(),
    delivery: PopularServicesGroupDeliveryModelSchema.optional().nullable(),
    culinaryStudio: PopularServicesGroupCulinaryStudioModelSchema.optional().nullable(),
    outdoorBar: PopularServicesGroupOutdoorBarModelSchema.optional().nullable(),
    sweetShop: PopularServicesGroupSweetShopModelSchema.optional().nullable(),
});

export type PopularServicesGroupModel
= z.infer<typeof PopularServicesGroupModelSchema>;
