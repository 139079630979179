import { z } from 'zod';
import { PortfolioEventModelSchema } from './portfolio-event';

export const PortfolioBlockInfoModelSchema = z.object({
    uid: z.string(),
    portfolioEvents: z.array(PortfolioEventModelSchema),
    image: z.object({
        url: z.string(),
    }).optional(),
});

export type PortfolioBlockInfoModel = z.infer<typeof PortfolioBlockInfoModelSchema>;
