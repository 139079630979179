// eslint-disable-next-line no-restricted-imports
import { BaseEntityApi } from 'shared/api';

export class CulinaryStudioArticleEntityApi extends BaseEntityApi {
    constructor() {
        super('culinary-studio-articles');
    }
}

export const culinaryStudioArticleEntityApiInstance = new CulinaryStudioArticleEntityApi();
