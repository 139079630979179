import React, { useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Carousel, useAppDispath } from 'shared';
import styles from './CrewMembersBlock.module.scss';
import { useCrewMembersBlockState } from '../model/crew-members-block-hooks';
import CrewMembersBlockActions from '../model/crew-members-block-actions';
import { Image } from '../../../components';

export type CrewMembersBlockProps = {
    className?: string
}

export const CrewMembersBlock = ({
    className,
}: CrewMembersBlockProps) => {
    const { crewMembers } = useCrewMembersBlockState();
    const dispatch = useAppDispath();

    const [selected, setSelected] = useState<any>(0);

    useLayoutEffect(() => {
        dispatch(CrewMembersBlockActions.getCrewMembers());
    }, []);

    return (
        <div className={cn(className, styles.crewMembersBlock)}>
            {/* <div className={styles.crewMembersBlock__backgroundBox} /> */}
            <div className={styles.crewMembersBlock__header}>
                <span>
                    КОМАНДА
                </span>
            </div>
            <div className={styles.crewMembersBlock__section}>
                <Carousel
                    className={styles.crewMembersBlock__carousel}
                    items={crewMembers.map(({ image }, index) => ({
                        key: index,
                        element: (
                            <div className={styles.crewMemberCard}>
                                <Image
                                    className={styles.crewMemberCard__image}
                                    url={image?.url || ''}
                                />
                            </div>
                        ),
                    }))}
                    value={selected}
                    onValueChange={(key) => setSelected(key)}
                />
                <div className={styles.crewMembersBlock__crewMemberInfoBlock}>
                    <span className={styles.crewMembersBlock__crewMemberName}>
                        {crewMembers.at(selected)?.name}
                    </span>
                    <span className={styles.crewMembersBlock__crewMemberJobTitle}>
                        {crewMembers.at(selected)?.jobTitle}
                    </span>
                    {crewMembers.at(selected)?.description
                        && <BlocksRenderer content={crewMembers.at(selected)?.description} />}
                </div>
            </div>
        </div>
    );
};
