import { createSlice } from '@reduxjs/toolkit';
import { ContactsBlockState } from './contacts-block-state';

const initialState: ContactsBlockState = {
    loaded: false,
};

// eslint-disable-next-line import/prefer-default-export
export const contactsBlockSlice = createSlice({
    name: 'contactsBlockSlice',
    initialState,
    reducers: {},
});
