import { createSlice } from '@reduxjs/toolkit';
import { z } from 'zod';
import { DeliveryPageState } from './delivery-page-state';
import { DeliveryPageActions } from './actions';
import BaseError from '../../../utils/base-error';
import { createDeliveryArticleCardsFromGetDeliveryArticlesByCategoryAndTagApiResponse, createDeliveryArticleCategoriesFromGetDeliveryArticleCategoriesApiResponse, createDeliveryArticleTagsFromGetDeliveryArticleTagsApiResponse } from '../utils';

const initialState: DeliveryPageState = {
    loaded: false,
    articles: [],
    articleTags: [],
    articleCategories: [],
    page: 1,

    deliveryArticleCategories: [],
    deliveryArticlesCards: [],
    deliveryArticleTags: [],
};

// eslint-disable-next-line import/prefer-default-export
export const deliveryPageSlice = createSlice({
    name: 'deliveryPageSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // builder.addCase(DeliveryPageActions.getArticles.fulfilled, (state, action) => {
        //     const response = action.payload;

        //     if (response instanceof BaseError) {
        //         // TODO
        //     } else {
        //         // Валидация
        //         try {
        //             const result = response.data.map(({
        //                 documentId,
        //                 name,
        //                 description,
        //                 images,
        //                 weight,
        //                 price,
        //                 category,
        //                 tags,
        //             }) => ArticleModelSchema.parse({
        //                 uid: documentId,
        //                 name,
        //                 description,
        //                 weight,
        //                 price,
        //                 images: images?.map((image) => ({
        //                     url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + image.url,
        //                 })),
        //                 category: category && ArticleCategoryModelSchema.parse({
        //                     uid: category?.documentId,
        //                     name: category?.name,
        //                 }),
        //                 tags: tags && tags.map((tag) => ArticleTagModelSchema.parse({
        //                     uid: tag?.documentId,
        //                     name: tag?.name,
        //                 })),
        //             }));

        //             state.articles = result;
        //             state.page = 1;
        //             state.loaded = true;
        //         } catch (e) {
        //             // return new BaseError(400, String(e));
        //             // TODO
        //         }
        //     }
        // });
        // builder.addCase(DeliveryPageActions.loadMoreArticles.fulfilled, (state, action) => {
        //     const response = action.payload;

        //     if (response instanceof BaseError) {
        //         // TODO
        //     } else {
        //         // Валидация
        //         try {
        //             const result = response.data.map(({
        //                 documentId,
        //                 name,
        //                 description,
        //                 images,
        //                 weight,
        //                 price,
        //                 category,
        //                 tags,
        //             }) => ArticleModelSchema.parse({
        //                 uid: documentId,
        //                 name,
        //                 description,
        //                 weight,
        //                 price,
        //                 images: images?.map((image) => ({
        //                     url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + image.url,
        //                 })),
        //                 category: category && ArticleCategoryModelSchema.parse({
        //                     uid: category?.documentId,
        //                     name: category?.name,
        //                 }),
        //                 tags: tags && tags.map((tag) => ArticleTagModelSchema.parse({
        //                     uid: tag?.documentId,
        //                     name: tag?.name,
        //                 })),
        //             }));
        //             state.articles = state.articles.concat(result);
        //             state.page = response.meta.pagination.page;
        //             state.loaded = true;
        //         } catch (e) {
        //             // return new BaseError(400, String(e));
        //             // TODO
        //         }
        //     }
        // });
        // builder.addCase(
        //     DeliveryPageActions.getArticleCategories.fulfilled,
        //     (state, action) => {
        //         const response = action.payload;

        //         if (response instanceof BaseError) {
        //             // TODO
        //         } else {
        //             // Валидация
        //             try {
        //                 const result = response.data.map(({
        //                     documentId,
        //                     name,
        //                 }) => ArticleCategoryModelSchema.parse({
        //                     uid: documentId,
        //                     name,
        //                 }));

        //                 state.articleCategories = result;
        //                 state.loaded = true;
        //             } catch (e) {
        //                 // return new BaseError(400, String(e));
        //                 // TODO
        //             }
        //         }
        //     },
        // );
        // builder.addCase(
        //     DeliveryPageActions.getArticleTags.fulfilled,
        //     (state, action) => {
        //         const response = action.payload;

        //         if (response instanceof BaseError) {
        //             // TODO
        //         } else {
        //             // Валидация
        //             try {
        //                 const result = response.data.map(({
        //                     documentId,
        //                     name,
        //                 }) => ArticleTagModelSchema.parse({
        //                     uid: documentId,
        //                     name,
        //                 }));

        //                 state.articleTags = result;
        //                 state.loaded = true;
        //             } catch (e) {
        //                 // return new BaseError(400, String(e));
        //                 // TODO
        //             }
        //         }
        //     },
        // );
        builder.addCase(
            DeliveryPageActions.getDeliveryArticlesByCategoryAndTag.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    console.log('deliveryPageSlice BaseError', response);
                } else {
                    try {
                        // eslint-disable-next-line max-len
                        state.deliveryArticlesCards = createDeliveryArticleCardsFromGetDeliveryArticlesByCategoryAndTagApiResponse(
                            response.data,
                        );
                    } catch (e) {
                        if (e instanceof z.ZodError) {
                            console.log('DeliveryPageActions.getDeliveryArticlesByCategoryAndTag', e.message);
                        }
                    }
                }
            },
        );
        builder.addCase(
            DeliveryPageActions.getDeliveryArticleCategories.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    console.log('deliveryPageSlice BaseError', response);
                } else {
                    try {
                        // eslint-disable-next-line max-len
                        state.deliveryArticleCategories = createDeliveryArticleCategoriesFromGetDeliveryArticleCategoriesApiResponse(
                            response.data,
                        );
                    } catch (e) {
                        if (e instanceof z.ZodError) {
                            console.log('DeliveryPageActions.getDeliveryArticleCategories', e.message);
                        }
                    }
                }
            },
        );
        builder.addCase(
            DeliveryPageActions.getDeliveryArticleTags.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    console.log('deliveryPageSlice BaseError', response);
                } else {
                    try {
                        // eslint-disable-next-line max-len
                        state.deliveryArticleTags = createDeliveryArticleTagsFromGetDeliveryArticleTagsApiResponse(
                            response.data,
                        );
                    } catch (e) {
                        if (e instanceof z.ZodError) {
                            console.log('DeliveryPageActions.getDeliveryArticleTags', e.message);
                        }
                    }
                }
            },
        );
    },
});
