import {
    Box,
    Button,
    Flex,
    FlexProps,
    Grid,
    Heading,
    Input,
    Text,
} from '@chakra-ui/react';
import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispath } from 'shared';
import { LeadSectionActions, useLeadSectionState } from '../../store';

export type LeadSectionSmallContentProps = {
    title: string
    buttonLabel: string
} & FlexProps & React.RefAttributes<HTMLDivElement>

export const LeadSectionSmallContent: React.FC<LeadSectionSmallContentProps> = ({
    title,
    buttonLabel,
    ...props
}) => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const { leadForm } = useLeadSectionState();
    const dispatch = useAppDispath();

    useLayoutEffect(() => {
        dispatch(LeadSectionActions.getLeadForm());
    }, []);

    return (
        <Flex
            flexDirection="column"
            gap="1rem"
            {...props}
        >
            <Heading
                colorScheme="variant"
            >
                {title}
            </Heading>
            <Flex
                flexDirection="row"
                flexWrap="wrap"
                gap="1rem"
            >
                <Input
                    flex="1 0 30%"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant="outline"
                    colorScheme="variant"
                    size="md"
                    placeholder="Ваше имя"
                />
                <Input
                    flex="1 0 30%"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    variant="outline"
                    colorScheme="variant"
                    size="md"
                    placeholder="Номер телефона"
                />
                <Button
                    flex="1 0 30%"
                    colorScheme="accent"
                    size="lg"
                    height="100%"
                    minWidth="fit-content"
                >
                    {buttonLabel}
                </Button>
            </Flex>
            <Grid
                templateColumns="1fr auto 1fr"
                gap="1rem"
            >
                <Box
                    margin="auto 0"
                    background="white"
                    height="1px"
                />
                <Text
                    colorScheme="variant"
                    fontSize="1.5rem"
                >
                    или
                </Text>
                <Box
                    margin="auto 0"
                    background="white"
                    height="1px"
                />
            </Grid>
            <Link to={leadForm?.commercialOfferFile?.url || ''}>
                <Text
                    colorScheme="variant"
                    fontSize="1.5rem"
                    textDecoration="underline"
                    fontWeight="bold"
                    textAlign="center"
                >
                    Получить коммерческое предложение PDF
                </Text>
            </Link>
        </Flex>
    );
};
