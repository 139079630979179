import {
    getBestImagesGroup,
    GetBestImagesGroupApiResponseSchema,
} from './get-best-images-group';

export {
    GetBestImagesGroupApiResponseSchema,
};

export const ImagesBlockActions = {
    getBestImagesGroup,
};
