import React from 'react';
import { YMaps } from '@pbe/react-yandex-maps';
import { withRedux } from './store/store-provider';
// import { useAppDispath } from './store/store-hooks';
// import APP_ACTIONS from '../controllers/app/app-actions';
// import '../styles/index.scss';
// import AppLoadingPreview from '../components/app-loading-preview';
// import { useAppState } from '../controllers/app/app-hooks';
import AppRouterProvider from './router/app-router-provider';
import { ChakraProvider } from './providers/chakra-provider';

const App = () => <AppRouterProvider />;

export default withRedux(() => (
    <ChakraProvider>
        <YMaps>
            <App />
        </YMaps>
    </ChakraProvider>
));
