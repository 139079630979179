import { z } from 'zod';

export const FindEventTypesResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
        category: z.object({
            documentId: z.string(),
            name: z.string(),
        }),
        images: z.array(z.object({
            url: z.string(),
        })).nullable().optional(),
    })),
    meta: z.any().optional(),
});

export type FindEventTypesResponse = z.infer<typeof FindEventTypesResponseSchema>;
