import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCateringFormatsApiResponseSchema = z.object({
    data: z.array(
        z.object({
            documentId: z.string(),
            name: z.string(),
            description: z.any().optional().nullable(),
            images: z.array(
                z.object({
                    url: z.string(),
                }),
            ),
        }),
    ),
});

type GetCateringFormatsApiResponse
    = ApiResponse<z.infer<typeof GetCateringFormatsApiResponseSchema>>
    | BaseError;

type GetCateringFormatsActionRequest = undefined

export const getCateringFormats = createAsyncThunk<
    GetCateringFormatsApiResponse,
    GetCateringFormatsActionRequest
>(
    'CateringPageActionsGetCateringFormats',
    async () => apiInstance.get(
        '/catering-formats',
        {
            'populate[images][fields]': 'url',
        },
        GetCateringFormatsApiResponseSchema,
    ),
);
