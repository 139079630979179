import React from 'react';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    BurgerIcon,
    HomeIcon,
    LogoIcon,
    MailIcon,
    MapIcon,
    PhoneIcon,
    PlusIcon,
    TelegramIcon,
    TrackIcon,
    VKIcon,
    DoneIcon,
    WarningIcon,
    CartIcon,
} from 'shared';

// eslint-disable-next-line no-shadow
export enum IconsNames {
    Burger,
    Home,
    Phone,
    Mail,
    Map,
    Logo,
    ArrowRight,
    ArrowLeft,
    Plus,
    Telegram,
    VK,
    Track,
    Done,
    Warning,
    Cart
}

const IconsPack = {
    [IconsNames.Burger]: {
        src: '/icons/burger.svg',
        alt: 'Burger Icon',
        element: BurgerIcon,
    },
    [IconsNames.Home]: {
        src: '/icons/home.svg',
        alt: 'Home Icon',
        element: HomeIcon,
    },
    [IconsNames.Phone]: {
        src: '/icons/phone.svg',
        alt: 'Phone Icon',
        element: PhoneIcon,
    },
    [IconsNames.Mail]: {
        src: '/icons/mail.svg',
        alt: 'Mail Icon',
        element: MailIcon,
    },
    [IconsNames.Map]: {
        src: '/icons/map.svg',
        alt: 'Map Icon',
        element: MapIcon,
    },
    [IconsNames.Logo]: {
        src: '/icons/logo.svg',
        alt: 'Logo Icon',
        element: LogoIcon,
    },
    [IconsNames.ArrowRight]: {
        src: '/icons/arrow_right.svg',
        alt: 'Arrow right Icon',
        element: ArrowRightIcon,
    },
    [IconsNames.ArrowLeft]: {
        src: '/icons/arrow_right.svg',
        alt: 'Arrow left Icon',
        element: ArrowLeftIcon,
    },
    [IconsNames.Plus]: {
        src: '/icons/plus.svg',
        alt: 'Plus Icon',
        element: PlusIcon,
    },
    [IconsNames.Telegram]: {
        src: '/icons/plus.svg',
        alt: 'Telegram Icon',
        element: TelegramIcon,
    },
    [IconsNames.VK]: {
        src: '/icons/plus.svg',
        alt: 'VK Icon',
        element: VKIcon,
    },
    [IconsNames.Track]: {
        src: '/icons/track.svg',
        alt: 'Track Icon',
        element: TrackIcon,
    },
    [IconsNames.Done]: {
        src: '/icons/done.svg',
        alt: 'Done Icon',
        element: DoneIcon,
    },
    [IconsNames.Warning]: {
        src: '/icons/warning.svg',
        alt: 'Warning Icon',
        element: WarningIcon,
    },
    [IconsNames.Cart]: {
        src: '/icons/warning.svg',
        alt: 'Warning Icon',
        element: CartIcon,
    },
};

type IconProps = {
    className?: string
    iconName: IconsNames
}

export const Icon = ({
    className,
    iconName,
}: IconProps) => {
    const { element } = IconsPack[iconName];
    const NewIcon = element;
    return <NewIcon className={className} />;
};
