// const getOutdoorBarsTypes = createAsyncThunk(
//     'getOutdoorBarsTypes',
//     async () => {
//         const response = await outdoorBarTypeEntityApiInstance.find();
//         return response;
//     },
// );

import { outdoorBarArticleEntityApiInstance } from 'entities/outdoor-bar-article';
import { createAsyncThunk } from '@reduxjs/toolkit';

type GetOutdoorBarsByTypeUidRequest = {
    uid: string
}
/**
 * Запрос на получение списка выездных баров с фильтрацией по uid типа
 */
export const getOutdoorBarByUid = createAsyncThunk(
    'getOutdoorBarsCardsByTypeUid',
    async ({
        uid,
    }: GetOutdoorBarsByTypeUidRequest) => (
        outdoorBarArticleEntityApiInstance.findOne(
            uid,
            {
                'populate[category][fields]': '*',
                'populate[images][fields]': 'url',
                'populate[portions][fields]': '*',
                'populate[portions][populate]': 'currency',
                'populate[sets][fields]': '*',
                'populate[sets][populate]': 'elements',
                'populate[includedServices][fields]': '*',
                'populate[nonIncludedServices][fields]': '*',
                'populate[recommendations][fields]': '*',
                'populate[recommendations][populate][0]': 'images',
                'populate[recommendations][populate][1]': 'currency',
                'populate[recommendations][populate][images][fields]': 'url',
                'populate[recommendations][populate][currency][fields]': '*',
            },
        )
    ),
);

export const getOutdoorBarArticlePortionsByUid = createAsyncThunk(
    'outdoorBarDetailBlockActionsGetOutdoorBarArticlePortionsByUid',
    async ({
        uid,
    }: GetOutdoorBarsByTypeUidRequest) => (
        outdoorBarArticleEntityApiInstance.findOne(
            uid,
            {
                'fields[0]': 'name',
                'fields[1]': 'description',
                'populate[images][fields][0]': 'url',
                'populate[category][fields][0]': 'documentId',
                'populate[portions][fields][0]': 'documentId',
            },
        )
    ),
);

export const OutdoorBarDetailBlockActions = {
    getOutdoorBarByUid,
};
