import { AxiosRequestConfig } from 'axios';
import { Api } from '../../../api/api';
import BaseError from '../../../utils/base-error';
import { ErrorResponseSchema } from '../../../api/base/responses/error-response';
import {
    EventApiFindOneResponse,
    EventApiFindOneResponseSchema,
    EventApiFindByEventTypeUIDResponse,
    EventApiFindByEventTypeUIDResponseSchema,
} from './responses';

export class EventApi extends Api {
    override findOne = async (
        uid: string,
    ): Promise<BaseError | EventApiFindOneResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/events/${uid}`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<EventApiFindOneResponse>(
            config,
            EventApiFindOneResponseSchema,
            ErrorResponseSchema,
        );

        console.log('LOL3', response);

        return response;
    };

    findByEventTypeUID = async (
        eventTypeUID: string,
    ): Promise<BaseError | EventApiFindByEventTypeUIDResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/events/`,
            params: {
                populate: '*',
                'filters[eventType][documentId][$eq]': eventTypeUID,
            },
        };

        const response = await this.makeCrud<EventApiFindByEventTypeUIDResponse>(
            config,
            EventApiFindByEventTypeUIDResponseSchema,
            ErrorResponseSchema,
        );

        console.log('LOL3', response);

        return response;
    };
}

export const eventApiInstance = new EventApi();
