import React, { useLayoutEffect } from 'react';
import {
    Button,
    Flex,
    FlexProps,
    Grid,
} from '@chakra-ui/react';
import {
    RichText,
    SimpleCarousel,
    useAppDispath,
} from 'shared';
import { useParams } from 'react-router-dom';
import { LeadSection, LeadSectionType } from 'features';
import { OutdoorBarDetailBlockActions, useOutdoorBarDetailBlockState } from '../model';
import {
    OutdoorBarArticleIncludedServicesSection,
    OutdoorBarArticleNonIncludedServicesSection,
    OutdoorBarArticleRecommendationsSection,
    OutdoorBarDetailBlockHeader,
} from './components';
import { OutdoorBarArticleSetsPicker } from './components/OutdoorBarArticleSetsPicker';
import { OutdoorBarArticlePortionPicker } from './components/OutdoorBarArticlePortionPicker';

export type OutdoorBarDetailBlockProps = FlexProps & React.RefAttributes<HTMLDivElement>

export const OutdoorBarDetailBlock = (props: OutdoorBarDetailBlockProps) => {
    const { id } = useParams();
    const dispatch = useAppDispath();
    const { outdoorBar } = useOutdoorBarDetailBlockState();

    useLayoutEffect(() => {
        if (id) {
            dispatch(OutdoorBarDetailBlockActions.getOutdoorBarByUid({
                uid: id,
            }));
        }
    }, []);
    return (
        <Flex
            {...props}
            flexDirection="column"
            gap="2rem"
            paddingTop="2rem"
        >
            <OutdoorBarDetailBlockHeader
                title={outdoorBar?.name || ''}
                padding="0 2rem"
            />
            <SimpleCarousel
                images={outdoorBar?.images?.map(({ url }) => url) || []}
                padding="0 2rem"
            />
            <Grid
                templateColumns={{
                    base: '1fr',
                    md: '1fr 2fr',
                }}
                gap="2rem"
                padding="0 2rem"
            >
                <Grid>
                    <RichText
                        fontSize="1rem"
                        content={outdoorBar?.description}
                    />
                    <Button>
                        Добавить в заказ
                    </Button>
                </Grid>
                <OutdoorBarArticlePortionPicker
                    outdoorBarArticlePortions={outdoorBar?.portions || []}
                />
            </Grid>
            <OutdoorBarArticleSetsPicker
                outdoorBarArticleSets={outdoorBar?.sets || []}
                padding="0 2rem"
            />
            <OutdoorBarArticleIncludedServicesSection
                outdoorBarArticleIncludedServices={outdoorBar?.includedServices || []}
                padding="0 2rem"
            />
            <OutdoorBarArticleNonIncludedServicesSection
                outdoorBarArticleNonIncludedServices={outdoorBar?.nonIncludedServices || []}
                padding="0 2rem"
            />
            <LeadSection
                type={LeadSectionType.Small}
                title="НЕ МОЖЕТЕ ОПРЕДЕЛИТЬСЯ?"
                buttonLabel="Помогите выбрать"
                padding="2rem"
            />
            <OutdoorBarArticleRecommendationsSection
                outdoorBarArticleRecommendations={outdoorBar?.recommendations || []}
                padding="0 2rem 2rem 2rem"
            />
        </Flex>
    );
};
