import {
    Grid,
    GridProps,
    Heading,
} from '@chakra-ui/react';
import {
    DeliveryArticleRecommendationModel,
    DeliveryArticleShortCard,
} from 'entities/delivery-article';
import React from 'react';

export type DeliveryArticlePageMainRecommendationsSectionProps = {
    deliveryArticleRecommendations: DeliveryArticleRecommendationModel[]
} & GridProps & React.RefAttributes<HTMLDivElement>;

export const DeliveryArticlePageMainRecommendationsSection
: React.FC<DeliveryArticlePageMainRecommendationsSectionProps> = ({
    deliveryArticleRecommendations,
    ...props
}) => (
    <Grid
        {...props}
        gap="2rem"
    >
        <Heading
            size="md"
            textDecoration="underline"
        >
            Вместе с этим, мы предлагаем
        </Heading>
        <Grid
            templateColumns="1fr 1fr 1fr 1fr"
            gap="2rem"
        >
            {deliveryArticleRecommendations.map(({
                uid,
                name,
                weight,
                price,
                currency,
                image,
            }) => (
                <DeliveryArticleShortCard
                    name={name}
                    weight={weight || undefined}
                    price={price || undefined}
                    currencyAbbreviation={currency?.abbreviation || ''}
                    link={{
                        to: `/delivery/${uid}`,
                    }}
                    image={{
                        src: image?.url || '',
                    }}
                />
            ))}
        </Grid>
    </Grid>
);
