import { EventCategoryModelSchema } from 'entities/event-category';
import { z } from 'zod';

export const PortfolioEventCardModelSchema = z.object({
    uid: z.string(),
    description: z.any().optional(),
    name: z.string(),
    images: z.array(z.object({
        url: z.string(),
    })).optional(),
    category: EventCategoryModelSchema,
});

export type PortfolioEventCardModel = z.infer<typeof PortfolioEventCardModelSchema>;
