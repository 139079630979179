import { z } from 'zod';
import { CateringEventModelSchema } from 'entities/catering-event';
import { GetCateringEventByUidApiResponseSchema } from '../store';

export const createCateringEventFromGetCateringEventByUidApiResponse = (
    (response: z.infer< typeof GetCateringEventByUidApiResponseSchema>) => (
        CateringEventModelSchema.parse({
            uid: response.data.documentId,
            name: response.data.name,
            description: response.data.description,
            images: response.data.images?.map(({ url }) => ({
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + url,
            })),
            format: response.data.format && ({
                name: response.data.format.name,
            }),
        })
    )
);
