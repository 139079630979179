import { Grid, GridProps, Text } from '@chakra-ui/react';
import { DeliveryArticleElementModel } from 'entities';
import React from 'react';

export type DeliveryArticlePageMainElementsSectionProps = {
    elements: DeliveryArticleElementModel[]
} & GridProps & React.RefAttributes<HTMLDivElement>

export const DeliveryArticlePageMainElementsSection:
React.FC<DeliveryArticlePageMainElementsSectionProps> = ({
    elements,
    ...props
}) => (
    <Grid
        height="fit-content"
        gap="0.5rem"
        {...props}
    >
        {elements.map(({
            name,
            value,
            meassure,
        }) => (
            <Grid
                templateColumns="1fr auto"
                padding="1rem"
                background="#DDDDDD"
            >
                <Text fontSize="1rem" margin="auto 0">
                    {name}
                </Text>
                <Text fontSize="1rem" fontWeight={900} margin="auto 0">
                    {value}
                    {' '}
                    {meassure}
                </Text>
            </Grid>
        ))}
    </Grid>
);
