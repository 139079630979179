/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import cn from 'classnames';
import styles from './Tabs.module.scss';

export type TabsItem = {
    key: any
    label: string
    element: React.JSX.Element
}

// eslint-disable-next-line no-shadow
export enum TabsType {
    Small = 'small',
    Regular = 'regular'
}

export type TabsProps = {
    className?: string,
    type?: TabsType,
    items: TabsItem[],
    startKey?: any,
    onChange?: (key: any) => any
}

export const Tabs = ({
    className,
    type,
    items,
    startKey,
    onChange,
}: TabsProps) => {
    const [selectedKey, setSelectedKey] = useState(startKey || items[0]?.key);
    const listRef = useRef<HTMLDivElement>(null);
    const rem = window.getComputedStyle(document.documentElement).fontSize;

    const onTabChange = (key: any) => {
        setSelectedKey(key);
        if (onChange) onChange(key);
    };

    let tabsClassName: string;
    let tabsHeaderClassName: string;
    let tabsMainClassName: string;
    let tabsItemClassName: string;
    let selectedTabsItemClassName: string;

    switch (type) {
    case TabsType.Small: {
        tabsClassName = styles.tabs_small;
        tabsHeaderClassName = styles.tabs_small__header;
        tabsMainClassName = styles.tabs_small__main;
        tabsItemClassName = styles.tabs_small__item;
        selectedTabsItemClassName = styles.tabs_small__item_selected;
        break;
    }
    default: {
        tabsClassName = styles.tabs_regular;
        tabsHeaderClassName = styles.tabs_regular__header;
        tabsMainClassName = styles.tabs_regular__main;
        tabsItemClassName = styles.tabs_regular__item;
        selectedTabsItemClassName = styles.tabs_regular__item_selected;
    }
    }

    useEffect(() => {
        setSelectedKey(startKey);
    }, [startKey]);

    useLayoutEffect(() => {
        if (listRef.current) {
            const width = listRef.current.children.item(0)?.getBoundingClientRect().width;
            const index = items.findIndex((item) => item.key === selectedKey);
            if (width && index > -1) {
                listRef.current.scrollTo({
                    left: (index - 1) * (width + 1.25 * parseFloat(rem)),
                    behavior: 'smooth',
                });
            }
        }
    }, [selectedKey]);

    return (
        <div className={cn(className, tabsClassName)}>
            <div ref={listRef} className={tabsHeaderClassName}>
                {items.map(({ key, label }) => (
                    <span
                        key={key}
                        className={cn(
                            tabsItemClassName,
                            (key === selectedKey) && selectedTabsItemClassName,
                        )}
                        onClick={() => onTabChange(key)}
                    >
                        {label}
                    </span>
                ))}
            </div>
            <div className={tabsMainClassName}>
                {items.find(({ key }) => key === selectedKey)?.element}
            </div>
        </div>
    );
};
