import axios, { AxiosRequestConfig } from 'axios';
import BaseError from 'utils/base-error';

export type EntityFindResponse <T> = {
    data: T[]
    meta: any // TODO
}

export type EntityFindOneResponse <T> = {
    data: T
    meta: any // TODO
}

export interface IEntityApi <T> {
    collectionName: string
    host: string

    find(params?: object): Promise<EntityFindResponse<T> | BaseError>
    findOne(uid: string, params?: object): Promise<EntityFindOneResponse<T> | BaseError>
}

export class BaseEntityApi <T = any> implements IEntityApi <T> {
    collectionName: string;

    host: string;

    constructor(collectionName: string) {
        this.collectionName = collectionName;
        this.host = process.env.REACT_APP_STRAPI_HOST!;
    }

    async find(params?: object): Promise<BaseError | EntityFindResponse <T>> {
        console.log('ASD ', `${this.host}/${this.collectionName}`, params);
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/${this.collectionName}`,
            params,
        };

        try {
            const { status, statusText, data } = await axios(config);
            console.log('ASD2', status, statusText, data);
            if ([200, 201].includes(status)) {
                return {
                    data: data.data as T[],
                    meta: data.meta,
                };
            }
            return new BaseError(status, statusText);
        } catch (e) {
            return new BaseError(500, String(e));
        }
    }

    async findOne(uid: string, params?: object): Promise<BaseError | EntityFindOneResponse<T>> {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/${this.collectionName}/${uid}`,
            params,
        };

        try {
            const { status, statusText, data } = await axios(config);

            if ([200, 201].includes(status)) {
                return {
                    data: data.data as T,
                    meta: data.meta,
                };
            }
            return new BaseError(status, statusText);
        } catch (e) {
            return new BaseError(500, String(e));
        }
    }
}
