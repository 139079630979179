import { defineRecipe } from '@chakra-ui/react';

export const inputRecipe = defineRecipe({
    base: {
        borderRadius: 0,
        height: 'fit-content',
    },
    variants: {
        variant: {
            outline: {
                border: '2px solid',
            },
        },
        colorScheme: {
            primary: {
                color: 'black',

                _hover: {

                },
            },
            variant: {
                color: 'white',
                borderColor: 'white',
                _focus: {
                    borderColor: '#E74C3C',
                },
            },
        },
        size: {
            md: {
                fontSize: '1.5rem',
                padding: '1rem',
                lineHeight: 1.5,
                fontWeight: 'normal',
            },
        },
    },
});
