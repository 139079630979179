import React, { memo, useLayoutEffect } from 'react';
import {
    AwardsBlock,
    Footer,
    Header,
    CrewMembersBlock,
    PortfolioBlock,
    FAQsBlock,
    DegustationBlock,
    ImagesBlock,
    ContactsBlock,
    NavigationBlock,
    TopHeader,
} from 'widgets';
import { useAppDispath } from 'shared';
import { LeadSection, PageLayout } from '../../../components';
import { CateringPageActions, useCateringPageState } from '../store';
import { CateringPageMain } from './components/CateringPageMain';

const MemoTopHeader = memo(TopHeader);
const MemoHeader = memo(Header);
const MemoFooter = memo(Footer);
const MemoAwardsBlock = memo(AwardsBlock);
const MemoCrewMembersBlock = memo(CrewMembersBlock);
const MemoPortfolioBlock = memo(PortfolioBlock);
const MemoFAQsBlock = memo(FAQsBlock);
const MemoDegustationBlock = memo(DegustationBlock);
const MemoImagesBlock = memo(ImagesBlock);
const MemoContactsBlock = memo(ContactsBlock);
const MemoNavigationBlock = memo(NavigationBlock);
const MemoLeadSection = memo(LeadSection);
const MemoMain = memo(CateringPageMain);

export const CateringPage = () => {
    const {
        cateringEventCategories,
        cateringFormats,
        cateringEventFormats,
    } = useCateringPageState();

    const dispatch = useAppDispath();

    useLayoutEffect(() => {
        dispatch(CateringPageActions.getCateringFormats());
        dispatch(CateringPageActions.getCateringEventCategories());
    }, []);
    return (
        <PageLayout>
            <MemoTopHeader />
            <MemoHeader />
            <MemoLeadSection
                title="ЕСТЬ ВОПРОСЫ ПО ДОСТАВКЕ?"
                subtitle="Наши менеджеры Вам помогут"
                sidebar={{
                    title: 'СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ',
                }}
            />
            <MemoMain
                cateringEventCategories={cateringEventCategories}
                cateringFormats={cateringFormats}
                cateringEventFormats={cateringEventFormats}
            />
            <MemoAwardsBlock />
            <MemoImagesBlock />
            <MemoDegustationBlock />
            <MemoCrewMembersBlock />
            <MemoPortfolioBlock />
            <MemoContactsBlock />
            <MemoFAQsBlock />
            <MemoNavigationBlock />
            <MemoFooter />
        </PageLayout>
    );
};
