import { z } from 'zod';
import { CulinaryStudioArticleCategoryModelSchema } from '../../culinary-studio-article-category';

export const CulinaryStudioTypeModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    category: CulinaryStudioArticleCategoryModelSchema,
});

export type CulinaryStudioTypeModel = z.infer<typeof CulinaryStudioTypeModelSchema>;
