import { z } from 'zod';

export const OutdoorBarArticleRecommendationModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    weight: z.number(),
    price: z.number(),
    currency: z.object({
        uid: z.string(),
        name: z.string(),
        abbreviation: z.string(),
    }).optional().nullable(),
    image: z.object({
        url: z.string(),
    }).optional().nullable(),
});

export type OutdoorBarArticleRecommendationModel
    = z.infer<typeof OutdoorBarArticleRecommendationModelSchema>;
