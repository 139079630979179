import {
    Grid,
    GridProps,
    Heading,
    Text,
} from '@chakra-ui/react';
import React from 'react';

export type FilterSectionItemProps = {
    label: string
    isActive: boolean
} & GridProps & React.RefAttributes<HTMLDivElement>

export const FilterSectionItem: React.FC<FilterSectionItemProps> = ({
    label,
    isActive,
    ...props
}) => (
    <Grid {...props}>
        <Text
            fontSize="1.5rem"
            colorScheme="variant"
            padding={isActive ? '0.5rem 1rem' : undefined}
            background={isActive ? '#E74C3C' : undefined}
            width="fit-content"
            cursor="pointer"
            transition="0.5s"
        >
            {label}
        </Text>
    </Grid>
);

export type FilterSectionItemModel = {
    key: any,
    label: string,
}

export type FilterSectionProps = {
    title: string
    value: any,
    onValueChange: (v: any) => void,
    items: FilterSectionItemModel[]
} & GridProps & React.RefAttributes<HTMLDivElement>

export const FilterSection: React.FC<FilterSectionProps> = ({
    title,
    value,
    onValueChange,
    items,
    ...props
}) => (
    <Grid
        gap="2rem"
        height="fit-content"
        {...props}
    >
        <Heading
            colorScheme="variant"
            size="xs"
        >
            {title}
        </Heading>
        <Grid
            gap="1rem"
        >
            {items.map(({
                key,
                label,
            }) => (
                <FilterSectionItem
                    label={label}
                    isActive={key === value}
                    onClick={() => onValueChange(key)}
                />
            ))}
        </Grid>
    </Grid>
);
