import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetDeliveryArticlesByCategoryAndTagApiResponseSchema = z.object({
    data: z.array(
        z.object({
            documentId: z.string(),
            name: z.string(),
            description: z.any(),
            weight: z.number().optional().nullable(),
            price: z.number().optional().nullable(),
            images: z.array(
                z.object({
                    url: z.string(),
                }),
            ).optional().nullable(),
            elements: z.array(
                z.object({
                    name: z.string(),
                    value: z.number(),
                    meassure: z.string(),
                }),
            ).optional().nullable(),
            currency: z.object({
                name: z.string(),
                abbreviation: z.string(),
            }).optional().nullable(),
            category: z.object({
                name: z.string(),
            }).optional().nullable(),
            tags: z.array(
                z.object({
                    name: z.string(),
                }),
            ).optional().nullable(),
            recommendations: z.array(
                z.object({
                    name: z.string(),
                    weight: z.number().optional().nullable(),
                    price: z.number().optional().nullable(),
                    images: z.array(
                        z.object({
                            url: z.string(),
                        }),
                    ),
                }),
            ).optional().nullable(),
        }),
    ),
});

type GetDeliveryArticlesByCategoryAndTagActionResponse
    = ApiResponse<z.infer<typeof GetDeliveryArticlesByCategoryAndTagApiResponseSchema>>
    | BaseError;

type GetDeliveryArticlesByCategoryAndTagActionRequest = {
    categoryUid: string,
    tagUid?: string,
}

export const getDeliveryArticlesByCategoryAndTag = createAsyncThunk<
    GetDeliveryArticlesByCategoryAndTagActionResponse,
    GetDeliveryArticlesByCategoryAndTagActionRequest
>(
    'DeliveryPageActionsGetDeliveryArticlesByCategoryAndTag',
    async ({
        categoryUid,
        tagUid,
    }) => apiInstance.get(
        '/delivery-articles',
        {
            'filters[category][documentId][$eq]': categoryUid,
            'filters[tags][documentId][$eq]': tagUid,
            'populate[images][fields]': 'url',
            'populate[elements][fields]': '*',
            'populate[currency][fields]': '*',
            'populate[category][fields]': '*',
            'populate[tags][fields]': '*',
            'populate[recommendations][fields]': '*',
            'populate[recommendations][populate][images][fields]': 'url',
        },
        GetDeliveryArticlesByCategoryAndTagApiResponseSchema,
    ),
);
