import React from 'react';
import {
    Grid,
    GridProps,
    Heading,
    Image,
} from '@chakra-ui/react';
import { RichText } from 'shared';
import { CompanyInformationModel, PopularServicesGroupModel } from 'entities';
import { HomePageMainPopularServicesGroupSection } from './HomePageMainPopularServicesGroupSection';

export type HomePageMainProps = {
    popularServicesGroup?: PopularServicesGroupModel,
    companyInformation?: CompanyInformationModel,
} & GridProps & React.RefAttributes<HTMLDivElement>

export const HomePageMain: React.FC<HomePageMainProps> = ({
    popularServicesGroup,
    companyInformation,
    ...props
}) => {
    console.log('HomePageMain', popularServicesGroup, companyInformation);

    return (
        <Grid
            gap="2rem"
            {...props}
            padding="2rem"
        >
            <Grid
                position="relative"
            >
                <Image
                    src={companyInformation?.mainImage?.url}
                    aspectRatio="3 / 2"
                    objectFit="cover"
                />
                <Grid
                    position="absolute"
                    padding="2rem"
                    height="100%"
                    width="auto"
                >
                    <Grid
                        margin="auto auto 0 auto"
                        width="60%"
                        gap="2rem"
                    >
                        <Heading
                            fontSize="4.5rem"
                            colorScheme="variant"
                        >
                            {companyInformation?.title}
                        </Heading>
                        <RichText
                            content={companyInformation?.description}
                            textAlign="justify"
                            colorScheme="variant"
                            margin="auto"
                            fontSize="1.25rem"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <HomePageMainPopularServicesGroupSection
                catering={popularServicesGroup?.catering || undefined}
                delivery={popularServicesGroup?.delivery || undefined}
                culinaryStudio={popularServicesGroup?.culinaryStudio || undefined}
                outdoorBar={popularServicesGroup?.outdoorBar || undefined}
                sweetShop={popularServicesGroup?.sweetShop || undefined}
            />
        </Grid>
    );
};
