import React, {
    memo,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import {
    Flex,
    FlexProps,
    Grid,
    Text,
} from '@chakra-ui/react';
import { ArticleCard, RichText, useAppDispath } from 'shared';
import { useParams, useSearchParams } from 'react-router-dom';
import { LeadSection, LeadSectionType } from 'features';
// eslint-disable-next-line no-restricted-imports
import { Tabs } from 'shared/ui/Tabs';
import { SweetShopArticleCategoryTagModel, SweetShopArticleModel } from 'entities';
import { SweetShopArticlesBlockActions } from '../model/sweet-shop-articles-block-actions';
import {
    SweetShopArticlesBlockHeader,
} from './components';
import { useSweetShopArticlesBlockState } from '../model';

/**
 * Функция, которая создаёт компонент лейбла для таба
 *
 * @param {string} label Текст
 * @returns {JSX.Element} Компонент
 */
const createSweetShopArticlesTabsItemLabel = (
    label: string,
) => (
    <Text fontSize="1.5rem">{label.toLocaleUpperCase()}</Text>
);

/**
 * Функция, которая создаёт компонент контента в табе
 *
 * @param {SweetShopArticleModel[]} sweetShopArticles Список позиций кондитерской
 * @returns {JSX.Element} Компонент
 */
const createSweetShopArticlesTabsContentItem = (
    sweetShopArticles: SweetShopArticleModel[],
) => (
    <Grid
        key="all"
        templateColumns="1fr 1fr 1fr"
        gap="2rem"
    >
        {sweetShopArticles.map(({
            uid,
            name: name2,
            description,
            images,

        }) => (
            <ArticleCard
                uid={uid}
                name={name2}
                description={{
                    content: description,
                }}
                image={images?.at(0)}
            />
        ))}
    </Grid>
);

/**
 * Функция, которая создаёт список компонентов для табов
 *
 * @param {SweetShopArticleModel[]} sweetShopArticles Список позиций кондитерской
 * @param {SweetShopArticleCategoryTagModel[]} sweetShopArticleCategoryTags
 * Список тегов для текущей категории
 * @returns {JSX.Element} Параметр items в TabsProps
 */
const createSweetShopArticlesTabsItems = (
    sweetShopArticles: SweetShopArticleModel[],
    sweetShopArticleCategoryTags: SweetShopArticleCategoryTagModel[],
) => ([{
    key: 'all',
    labelComponent: createSweetShopArticlesTabsItemLabel('ВСЕ НАЧИНКИ'),
    contentComponent: createSweetShopArticlesTabsContentItem(sweetShopArticles),
}].concat(
    sweetShopArticleCategoryTags.map(({
        uid,
        name,
    }) => ({
        key: uid,
        labelComponent: createSweetShopArticlesTabsItemLabel(name),
        contentComponent: createSweetShopArticlesTabsContentItem(sweetShopArticles),
    })),
));

/**
 * Мемоизированный хедер блока, тк как он не перерисовывается
 */
const MemoHeader = memo(SweetShopArticlesBlockHeader);

/**
 * Пропсы для компонента блока позиций кондитерской
 */
export type SweetShopArticlesBlockProps = FlexProps & React.RefAttributes<HTMLDivElement>

/**
 * Компонент блока позиций кондитерской
 * @param param0
 * @returns {JSX.Element}
 */
export const SweetShopArticlesBlock = ({
    ...props
}: SweetShopArticlesBlockProps) => {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        sweetShopArticleCategory,
        sweetShopArticleCategoryTags,
        sweetShopArticles,
    } = useSweetShopArticlesBlockState();
    const dispatch = useAppDispath();

    /**
     * Ключ активного таба
     */
    const [currentKey, setCurrentKey] = useState<string>();

    useLayoutEffect(() => {
        if (id) {
            dispatch(SweetShopArticlesBlockActions.getSweetShopArticleCategoryByUid({
                uid: id,
            }));
            dispatch(SweetShopArticlesBlockActions.getSweetShopArticleCategoryTagsByCategoryUid({
                uid: id,
            }));
        }
    }, []);

    useEffect(() => {
        if (searchParams.get('tab') !== currentKey) {
            setCurrentKey(searchParams.get('tab') || 'all');
        }
    }, [searchParams.get('tab')]);

    useEffect(() => {
        if (currentKey && id) {
            dispatch(SweetShopArticlesBlockActions.getSweetShopArticlesByCategoryUidAndTagUid({
                categoryUid: id,
                tagUid: currentKey !== 'all' ? currentKey : undefined,
            }));
            if (currentKey !== searchParams.get('tab')) {
                setSearchParams({
                    tab: currentKey,
                });
            }
        }
    }, [currentKey]);

    /**
     * Мемоизированные табы
     */
    const memoTabsItems = useMemo(() => (
        createSweetShopArticlesTabsItems(
            sweetShopArticles,
            sweetShopArticleCategoryTags,
        )
    ), [sweetShopArticles, sweetShopArticleCategoryTags]);

    return (
        <Flex
            {...props}
            flexDirection="column"
        >
            <MemoHeader
                name={sweetShopArticleCategory?.name || ''}
                description={sweetShopArticleCategory?.description}
                imageSrc={sweetShopArticleCategory?.image?.url || ''}
            />
            <LeadSection
                type={LeadSectionType.Small}
                title="ХОТИТЕ ОФОРМИТЬ ЗАКАЗ??"
                padding="2rem"
                buttonLabel="Задать вопрос"
                background="black"
            />
            <Flex
                flexDirection="column"
                padding="2rem"
                gap="2rem"
            >
                <RichText
                    content={sweetShopArticleCategory?.addictionalDescription}
                    fontSize="2rem"
                />
                <Tabs
                    value={currentKey}
                    onValueChange={(v) => setCurrentKey(v)}
                    items={memoTabsItems}
                />
            </Flex>
        </Flex>
    );
};
