import { z } from 'zod';
import { BestImagesGroupModelSchema } from 'entities/best-images-group';
import { GetBestImagesGroupApiResponseSchema } from '../store';

export const createGetBestImagesGroupFromGetGetBestImagesGroupApiResponse = (
    (response: z.infer< typeof GetBestImagesGroupApiResponseSchema>) => (
        BestImagesGroupModelSchema.parse({
            images: response.data.images?.map(({ url }) => ({
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + url,
            })),
        })
    )
);
