import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCateringFormatByUidApiResponseSchema = z.object({
    data: z.object({
        documentId: z.string(),
        name: z.string(),
        description: z.any().optional().nullable(),
        images: z.array(
            z.object({
                url: z.string(),
            }),
        ).optional().nullable(),
        menus: z.array(
            z.object({
                name: z.string(),
                description: z.any().optional().nullable(),
                warning: z.string().optional().nullable(),
                estimateFile: z.object({
                    url: z.string(),
                }),
                image: z.object({
                    url: z.string(),
                }),
            }),
        ).optional().nullable(),
    }),
});

type GetCateringFormatByUidApiResponse
    = ApiResponse<z.infer<typeof GetCateringFormatByUidApiResponseSchema>>
    | BaseError;

type GetCateringFormatByUidActionRequest = {
    uid: string
}

export const getCateringFormatByUid = createAsyncThunk<
    GetCateringFormatByUidApiResponse,
    GetCateringFormatByUidActionRequest
>(
    'CateringFormatPageActionsGetCateringFormatByUid',
    async ({
        uid,
    }) => apiInstance.get(
        `/catering-formats/${uid}`,
        {
            'populate[images][fields]': 'url',
            'populate[menus][fields]': '*',
            'populate[menus][populate][estimateFile][fields]': 'url',
            'populate[menus][populate][image][fields]': 'url',
        },
        GetCateringFormatByUidApiResponseSchema,
    ),
);
