import { SweetShopArticleCategoryModelSchema, SweetShopArticleCategoryModel } from '../model';
import { SweetShopArticleCategoryDto } from '../api';

export const parseSweetShopArticleCategoryDtoToDomain = (
    dto: SweetShopArticleCategoryDto,
): SweetShopArticleCategoryModel => SweetShopArticleCategoryModelSchema.parse({
    uid: dto.documentId,
    name: dto.name,
    description: dto.description,
    addictionalDescription: dto.addictionalDescription,
    image: {
        // eslint-disable-next-line no-unsafe-optional-chaining
        url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + dto.image?.url,
    },
});
