import { AxiosRequestConfig } from 'axios';
import { FindEventCategoriesResponse, FindEventCategoriesResponseSchema } from './responses/find-event-categories-response';
import { Api } from '../../../api/api';
import BaseError from '../../../utils/base-error';
import { ErrorResponseSchema } from '../../../api/base/responses/error-response';

export class EventCategoryApi extends Api {
    findEventCategories = async (): Promise<BaseError | FindEventCategoriesResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/event-categories`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<FindEventCategoriesResponse>(
            config,
            FindEventCategoriesResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };
}

export const eventCategoryApiInstance = new EventCategoryApi();
