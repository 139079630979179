import {
    Grid,
    GridProps,
    Heading,
    Text,
} from '@chakra-ui/react';
import { Icon, IconsNames } from 'components';
import { OutdoorBarArticleNonIncludedServiceModel } from 'entities';
import React from 'react';

export type OutdoorBarArticleNonIncludedServicesSectionProps = {
    outdoorBarArticleNonIncludedServices: OutdoorBarArticleNonIncludedServiceModel[]
} & GridProps & React.RefAttributes<HTMLDivElement>;

export const OutdoorBarArticleNonIncludedServicesSection
: React.FC<OutdoorBarArticleNonIncludedServicesSectionProps> = ({
    outdoorBarArticleNonIncludedServices,
    ...props
}) => (
    <Grid
        {...props}
    >
        <Grid
            gap="2rem"
            templateColumns="auto 1fr"
            padding="2rem"
            background="#FDEDEB"
        >
            <Icon
                iconName={IconsNames.Warning}
            />
            <Grid>
                <Heading
                    size="md"
                >
                    Что не входит в состав услуги
                </Heading>
                <Grid>
                    {outdoorBarArticleNonIncludedServices.map(({ name }) => (
                        <Text>
                            {`• ${name}`}
                        </Text>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);
