import { z } from 'zod';
import { CulinaryStudioArticleCardModelSchema } from 'entities/culinary-studio-article';
import { GetCulinaryStudioArticleCardsByCategoryUidApiResponseSchema } from '../store';

// eslint-disable-next-line max-len
export const createCulinaryStudioArticleCardsFromGetCulinaryStudioArticleCardsByCategoryUidApiResponse = (
    (response: z.infer< typeof GetCulinaryStudioArticleCardsByCategoryUidApiResponseSchema>) => (
        response.data.map(({
            documentId,
            name,
            images,
            category,
        }) => CulinaryStudioArticleCardModelSchema.parse({
            uid: documentId,
            name,
            image: images?.[0].url && {
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + images[0].url,
            },
            category: category && ({
                uid: category.documentId,
            }),
        }))
    )
);
