import { z } from 'zod';

export const DeliveryArticleCardModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    weight: z.number().optional().nullable(),
    price: z.number().optional().nullable(),
    currency: z.object({
        abbreviation: z.string(),
    }).optional().nullable(),
    image: z.object({
        url: z.string(),
    }).optional().nullable(),
});

export type DeliveryArticleCardModel
= z.infer<typeof DeliveryArticleCardModelSchema>;
