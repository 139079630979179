import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetDeliveryArticleTagsApiResponseSchema = z.object({
    data: z.array(
        z.object({
            documentId: z.string(),
            name: z.string(),
        }),
    ),
});

type GetDeliveryArticleTagsActionResponse
    = ApiResponse<z.infer<typeof GetDeliveryArticleTagsApiResponseSchema>>
    | BaseError;

type GetDeliveryArticleTagsActionRequest = undefined

export const getDeliveryArticleTags = createAsyncThunk<
    GetDeliveryArticleTagsActionResponse,
    GetDeliveryArticleTagsActionRequest
>(
    'DeliveryPageActionsGetDeliveryArticleTags',
    async () => apiInstance.get(
        '/delivery-article-tags',
        {},
        GetDeliveryArticleTagsApiResponseSchema,
    ),
);
