import { createSlice } from '@reduxjs/toolkit';
import { EventCategoryModelSchema } from 'entities/event-category';
import { PortfolioEventModelSchema } from 'entities/portfolio-event';
import BaseError from '../../../utils/base-error';
import { PortfolioEventDetailBlockState } from './portfolio-event-detail-block-state';
import { PortfolioEventDetailBlockActions } from './portfolio-event-detail-block-actions';

const initialState: PortfolioEventDetailBlockState = {
    loaded: false,
};

export const portfolioEventDetailBlockSlice = createSlice({
    name: 'portfolioEventDetailBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            PortfolioEventDetailBlockActions.getPortfolioEventByUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log('LOL2', response);
                        const result = PortfolioEventModelSchema.parse({
                            uid: response.data.documentId,
                            description: response.data.description,
                            name: response.data.name,
                            images: response.data.images?.map((image) => ({
                                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + image.url,
                            })),
                            category: EventCategoryModelSchema.parse({
                                uid: response.data.category.documentId,
                                name: response.data.category.name,
                            }),
                            place: response.data.place,
                            format: response.data.format,
                            guestsCount: response.data.guestsCount,
                            date: response.data.date,
                            organizer: response.data.organizer,
                        });

                        state.portfolioEvent = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
