import {
    getCateringEventFormatByUid,
    GetCateringEventFormatByUidApiResponseSchema,
} from './get-catering-event-format-by-uid';
import {
    getCateringEventsByFormatUid,
    GetCateringEventsByFormatUidApiResponseSchema,
} from './get-catering-events-by-format-uid';

export {
    GetCateringEventFormatByUidApiResponseSchema,
    GetCateringEventsByFormatUidApiResponseSchema,
};

export const CateringEventFormatPageActions = {
    getCateringEventFormatByUid,
    getCateringEventsByFormatUid,
};
