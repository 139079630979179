// eslint-disable-next-line no-restricted-imports
import { BaseEntityApi } from 'shared/api';

export type SweetShopArticleCategoryTagDto = {
    documentId: string,
    name?: string,
    category?: {
        documentId?: string
    },
}

export class SweetShopArticleCategoryTagApi extends BaseEntityApi<SweetShopArticleCategoryTagDto> {
    constructor() {
        super('sweet-shop-article-category-tags');
    }
}

export const sweetShopArticleCategoryTagApiInstance = new SweetShopArticleCategoryTagApi();
