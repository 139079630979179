import { AxiosRequestConfig } from 'axios';
import { FindEventTypesResponse, FindEventTypesResponseSchema } from './responses/find-event-types-response';
import { Api } from '../../../api/api';
import BaseError from '../../../utils/base-error';
import { ErrorResponseSchema } from '../../../api/base/responses/error-response';
import { EventTypeApiFindOneResponse, EventTypeApiFindOneResponseSchema } from './responses/event-type-api-find-one-response';

export class EventTypeApi extends Api {
    findEventTypes = async (): Promise<BaseError | FindEventTypesResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/event-types`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<FindEventTypesResponse>(
            config,
            FindEventTypesResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };

    override findOne = async (
        uid: string,
    ): Promise<BaseError | EventTypeApiFindOneResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/event-types/${uid}`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<EventTypeApiFindOneResponse>(
            config,
            EventTypeApiFindOneResponseSchema,
            ErrorResponseSchema,
        );

        console.log('LOL3', response);

        return response;
    };
}

export const eventTypeApiInstance = new EventTypeApi();
