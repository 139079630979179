import { AxiosRequestConfig } from 'axios';
import { FindCulinaryStudioTypesResponse, FindCulinaryStudioTypesResponseSchema } from './responses/find-culinary-studio-types-response';
import { Api } from '../../../api/api';
import BaseError from '../../../utils/base-error';
import { ErrorResponseSchema } from '../../../api/base/responses/error-response';

export class CulinaryStudioTypeApi extends Api {
    findCulinaryStudioTypes = async (): Promise<BaseError | FindCulinaryStudioTypesResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/culinary-studio-types`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<FindCulinaryStudioTypesResponse>(
            config,
            FindCulinaryStudioTypesResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };
}

export const culinaryStudioTypeApiInstance = new CulinaryStudioTypeApi();
