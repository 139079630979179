import { z } from 'zod';

export const FindAwardsResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        description: z.any().optional(),
        image: z.object({
            url: z.string(),
        }).optional(),
        years: z.array(z.object({
            documentId: z.string(),
            year: z.string(),
        })),
    })),
    meta: z.any().optional(),
});

export type FindAwardsResponse = z.infer<typeof FindAwardsResponseSchema>;
