import { z } from 'zod';

export const FindCulinaryStudioTypesResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
        category: z.object({
            documentId: z.string(),
            name: z.string(),
        }),
    })),
    meta: z.any().optional(),
});

export type FindCulinaryStudioTypesResponse = z.infer<typeof FindCulinaryStudioTypesResponseSchema>;
