import { z } from 'zod';

export const CateringFormatPopularCardModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    shortDescription: z.string().optional().nullable(),
    image: z.object({
        url: z.string(),
    }).optional(),
});

export type CateringFormatPopularCardModel = z.infer<typeof CateringFormatPopularCardModelSchema>;
