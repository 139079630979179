// eslint-disable-next-line no-restricted-imports
import { BaseEntityApi } from 'shared/api';

export type OutdoorBarArticleCategoryDto = {
    documentId?: string,
    name?: string,
}

export class OutdoorBarArticleCategoryEntityApi
    extends BaseEntityApi<OutdoorBarArticleCategoryDto> {
    constructor() {
        super('outdoor-bar-article-categories');
    }
}

export const outdoorBarArticleCategoryEntityApiInstance = new OutdoorBarArticleCategoryEntityApi();
