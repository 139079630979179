import { z } from 'zod';

export const FindArticleCategoriesResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
    })),
    meta: z.any().optional(),
});

export type FindArticleCategoriesResponse = z.infer<typeof FindArticleCategoriesResponseSchema>;
