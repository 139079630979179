import React from 'react';
import cn from 'classnames';
import styles from './TextArea.module.scss';

// eslint-disable-next-line no-shadow
export enum TextAreaTheme {
    Light,
    Dark
}

export type TextAreaProps = {
    className?: string,
    value?: string
    placeholder?: string
    theme?: TextAreaTheme
    onChange?: (value: string) => void
}

export const TextArea = ({
    className,
    value,
    placeholder,
    theme,
    onChange,
}: TextAreaProps) => {
    let textAreaClassName: string;

    switch (theme) {
    case TextAreaTheme.Light: {
        textAreaClassName = styles.textArea_light;
        break;
    }
    default: {
        textAreaClassName = styles.textArea_dark;
    }
    }
    return (
        <textarea
            className={cn(className, styles.textArea, textAreaClassName)}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange && onChange(e.target.value)}
        />
    );
};
