import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './routes';
// import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import { Route } from '../../shared/types/route';
// import { useAppDispath } from '../store/store-hooks';
// import PAGE_ACTIONS from '../../controllers/page/page-actions';
// import { PageId } from '../../shared/types/page-info';
// import Page from '../../pages/Page';

// export const createAppRouter = (
//     routes: Route[],
//     preloadFunc: (pageId: PageId) => Promise<null>,
// ) => {
//     const routesObjects = routes.map(({ pageId, path }) => ({
//         path,
//         loader: async () => preloadFunc(pageId),
//         element: <Page />,
//     }));

//     return createBrowserRouter(routesObjects);
// };

// export type AppRouterProviderProps = {
//     routes: Route[]
// }

const AppRouterProvider = () => <RouterProvider router={createBrowserRouter(routes)} />;

export default AppRouterProvider;
