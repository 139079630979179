import { createSlice } from '@reduxjs/toolkit';
import BaseError from '../../../utils/base-error';
import { CateringEventPageState } from './catering-event-page-state';
import { CateringEventPageActions } from './actions';
import { createCateringEventFromGetCateringEventByUidApiResponse } from '../utils';

const initialState: CateringEventPageState = {
    loaded: false,
};

export const cateringEventPageSlice = createSlice({
    name: 'cateringEventPageSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            CateringEventPageActions.getCateringEventByUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log('LOL2', response);
                        const result = createCateringEventFromGetCateringEventByUidApiResponse(
                            response.data,
                        );

                        state.cateringEvent = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
