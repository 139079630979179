import { createSlice } from '@reduxjs/toolkit';
import { z } from 'zod';
import APP_ACTIONS from './app-actions';
import BaseError from '../../utils/base-error';
import { Icon } from '../../shared/types/icon';
import { Route } from '../../shared/types/route';

type AppState = {
    loaded: boolean
    icons: Icon[]
    routes: Route[]
    modals: {
        id: number,
        modalName: string
    }[],
    dataBunch: {
        [id: string]: any
    },
}

const initialState: AppState = {
    loaded: false,
    icons: [],
    routes: [],
    modals: [],
    dataBunch: {},
};

// eslint-disable-next-line import/prefer-default-export
export const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(APP_ACTIONS.GET_ICONS.fulfilled, (state, action) => {
            const result = action.payload;

            if (result instanceof BaseError) {
                // TODO
            } else {
                const { data } = result;

                // Валидация
                const schema = z.array(
                    z.object({
                        name: z.string(),
                        source: z.string(),
                    }),
                );

                const validatedData = schema.parse(data);

                state.icons = validatedData;
            }
        });
        builder.addCase(APP_ACTIONS.GET_ROUTES.fulfilled, (state, action) => {
            const response = action.payload;

            if (response instanceof BaseError) {
                // TODO
            } else {
                const { data } = response;

                // Валидация
                try {
                    const schema = z.array(
                        z.object({
                            document_id: z.string(),
                            url: z.string(),
                        }).transform((input) => ({
                            documentId: input.document_id,
                            url: input.url,
                        })),
                    );

                    const validatedData = schema.parse(data);

                    state.routes = validatedData.map(({ documentId, url }) => ({
                        pageId: documentId,
                        path: url,
                    }));
                } catch (e) {
                    console.log('APP_ACTIONS.GET_ROUTES', e); // TODO
                }
            }
        });
        builder.addCase(APP_ACTIONS.SET_LOADED, (state, action) => {
            try {
                state.loaded = action.payload.loaded;
            } catch (e) {
                console.log('123', e);
                console.log('error');
            }
        });
        builder.addCase(APP_ACTIONS.OPEN_MODAL.fulfilled, (state, action) => {
            try {
                console.log('OPEN_MODAL');
                state.modals.push({
                    id: Date.now(),
                    modalName: action.payload.modalName,
                });
            } catch (e) {
                console.log('123', e);
                console.log('error');
            }
        });
        builder.addCase(APP_ACTIONS.CLOSE_MODAL, (state, action) => {
            try {
                state.modals = state.modals.filter((modal) => modal.id !== action.payload.id);
            } catch (e) {
                console.log('123', e);
                console.log('error');
            }
        });
        builder.addCase(APP_ACTIONS.SET_VALUE_TO_DATABUNCH, (state, action) => {
            try {
                const { id, value } = action.payload;
                state.dataBunch[id] = value;
            } catch (e) {
                console.log('123', e);
                console.log('error');
            }
        });
    },
});
