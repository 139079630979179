import {
    Flex,
    Grid,
    GridProps,
    Heading,
    Text,
} from '@chakra-ui/react';
import { Icon, IconsNames } from 'components';
import { OutdoorBarArticleIncludedServiceModel } from 'entities';
import React from 'react';
import { RichText } from 'shared';

export type OutdoorBarArticleIncludedServicesSectionProps = {
    outdoorBarArticleIncludedServices: OutdoorBarArticleIncludedServiceModel[]
} & GridProps & React.RefAttributes<HTMLDivElement>;

export const OutdoorBarArticleIncludedServicesSection
: React.FC<OutdoorBarArticleIncludedServicesSectionProps> = ({
    outdoorBarArticleIncludedServices,
    ...props
}) => (
    <Grid
        gap="2rem"
        {...props}
    >
        <Heading
            size="md"
        >
            В состав услуги входит
        </Heading>
        <Flex
            flexDirection="row"
            gap="2rem"
        >
            {outdoorBarArticleIncludedServices.map(({
                name,
                description,
            }) => (
                <Grid
                    width="fit-content"
                    gap="0.25rem"
                >
                    <Grid
                        width="fit-content"
                        gap="0.5rem"
                        templateColumns="auto auto"
                        height="fit-content"
                    >
                        <Icon iconName={IconsNames.Done} />
                        <Text
                            margin="auto"
                            fontSize="1rem"
                            fontWeight={900}
                        >
                            {name}
                        </Text>
                    </Grid>
                    <RichText content={description} />
                </Grid>
            ))}
        </Flex>
    </Grid>
);
