import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    articleCategoryApiInstance,
    articleTagApiInstance,
    cateringFormatApiInstance,
    culinaryStudioArticleCategoryEntityApiInstance,
    culinaryStudioTypeApiInstance,
    eventCategoryApiInstance,
    eventTypeApiInstance,
    outdoorBarArticleCategoryEntityApiInstance,
    sweetShopArticleCategoryApiInstance,
} from '../../../entities';

const getCateringFormats = createAsyncThunk(
    'getCateringFormats',
    async () => {
        const response = await cateringFormatApiInstance.findCateringFormats();
        return response;
    },
);

const getEventCategories = createAsyncThunk(
    'getEventCategories',
    async () => {
        const response = await eventCategoryApiInstance.findEventCategories();
        return response;
    },
);

const getEventTypes = createAsyncThunk(
    'getEventTypes',
    async () => {
        const response = await eventTypeApiInstance.findEventTypes();
        return response;
    },
);

const getSweetShopArticleCategories = createAsyncThunk(
    'getSweetShopArticleCategories',
    async () => {
        const response = await sweetShopArticleCategoryApiInstance.find();
        return response;
    },
);

const getArticleCategories = createAsyncThunk(
    'getArticleCategories',
    async () => {
        const response = await articleCategoryApiInstance.findArticleCategories();
        return response;
    },
);

const getArticleTags = createAsyncThunk(
    'getArticleTags',
    async () => {
        const response = await articleTagApiInstance.findArticleTags();
        return response;
    },
);

const getCulinaryStudioCategories = createAsyncThunk(
    'getCulinaryStudioCategories',
    async () => {
        const response = await culinaryStudioArticleCategoryEntityApiInstance.find();
        return response;
    },
);

const getCulinaryStudioTypes = createAsyncThunk(
    'getCulinaryStudioTypes',
    async () => {
        const response = await culinaryStudioTypeApiInstance.findCulinaryStudioTypes();
        return response;
    },
);

const getOutdoorBarArticleCategories = createAsyncThunk(
    'getOutdoorBarArticleCategories',
    async () => {
        const response = await outdoorBarArticleCategoryEntityApiInstance.find({
            '': '',
        });
        return response;
    },
);

const NavigationBlockActions = {
    getCateringFormats,
    getEventCategories,
    getEventTypes,
    getSweetShopArticleCategories,
    getArticleCategories,
    getArticleTags,
    getCulinaryStudioCategories,
    getCulinaryStudioTypes,
    getOutdoorBarArticleCategories,
};

export default NavigationBlockActions;
