import { Flex, Image, Text } from '@chakra-ui/react';
import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BlockHeader, RichText, useAppDispath } from 'shared';
import { LeadSection, LeadSectionType } from 'features';
import { PortfolioEventDetailBlockActions, usePortfolioEventDetailBlockState } from '../slice';

export const PortfolioEventDetailBlock = () => {
    const { id } = useParams();
    const dispatch = useAppDispath();
    const { portfolioEvent } = usePortfolioEventDetailBlockState();

    useLayoutEffect(() => {
        if (id) dispatch(PortfolioEventDetailBlockActions.getPortfolioEventByUid({ uid: id }));
    }, []);

    console.log('EventTypeBlock', portfolioEvent);

    return (
        <Flex
            flexDirection="column"
        >
            <BlockHeader
                title={portfolioEvent?.name || ''}
                imageSrc={portfolioEvent?.images?.at(0)?.url || ''}
            />
            <LeadSection
                type={LeadSectionType.Small}
                title="ХОТИТЕ ТАКОЕ ЖЕ МЕРОПРИЯТИЕ?"
                padding="2rem"
                buttonLabel="Задать вопрос"
            />
            <Flex
                flexDirection="row"
                padding="2rem"
                gap="1rem"
            >
                <Flex
                    flexDirection="row"
                    gap="1rem"
                    flex={3}
                >
                    <Flex
                        flexDirection="column"
                        flex={1}
                        gap="1rem"
                    >
                        {portfolioEvent?.images?.filter((_, i) => i % 2 === 0).map(({ url }) => (
                            <Image
                                src={url}
                                width="100%"
                            />
                        ))}
                    </Flex>
                    <Flex
                        flexDirection="column"
                        flex={1}
                        gap="1rem"
                    >
                        {portfolioEvent?.images?.filter((_, i) => i % 2 === 1).map(({ url }) => (
                            <Image
                                src={url}
                                width="100%"
                            />
                        ))}
                    </Flex>
                </Flex>
                <Flex
                    flexDirection="column"
                    flex={2}
                    border="3px solid #E74C3C"
                    height="fit-content"
                    padding="1rem"
                >
                    <Text>
                        <strong>
                            Место проведения:
                        </strong>
                        {' '}
                        {portfolioEvent?.place}
                    </Text>
                    <Text>
                        <strong>
                            Формат:
                        </strong>
                        {' '}
                        {portfolioEvent?.format}
                    </Text>
                    <Text>
                        <strong>
                            Количество гостей:
                        </strong>
                        {' '}
                        {portfolioEvent?.guestsCount}
                    </Text>
                    <Text>
                        <strong>
                            Дата мероприятия:
                        </strong>
                        {' '}
                        {portfolioEvent?.date?.toLocaleDateString()}
                    </Text>
                    <Text>
                        <strong>
                            Организатор:
                        </strong>
                        {' '}
                        {portfolioEvent?.organizer}
                    </Text>
                    <RichText
                        marginTop="1rem"
                        fontSize="1rem"
                        content={portfolioEvent?.description}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};
