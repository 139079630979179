import { EventCategoryModelSchema } from 'entities/event-category';
import { z } from 'zod';

export const PortfolioEventModelSchema = z.object({
    uid: z.string(),
    description: z.any().optional(),
    name: z.string(),
    images: z.array(z.object({
        url: z.string(),
    })).optional(),
    category: EventCategoryModelSchema,
    place: z.string().optional().nullable(),
    format: z.string().optional().nullable(),
    guestsCount: z.number().optional().nullable(),
    date: z.date().optional().nullable(),
    organizer: z.string().optional().nullable(),
});

export type PortfolioEventModel = z.infer<typeof PortfolioEventModelSchema>;
