import { SweetShopArticleCategoryTagModelSchema, SweetShopArticleCategoryTagModel } from '../model';
import { SweetShopArticleCategoryTagDto } from '../api';

export const parseSweetShopArticleCategoryTagDtoToDomain = (
    dto: SweetShopArticleCategoryTagDto,
): SweetShopArticleCategoryTagModel => SweetShopArticleCategoryTagModelSchema.parse({
    uid: dto.documentId,
    name: dto.name,
    category: {
        uid: dto.category?.documentId,
    },
});
