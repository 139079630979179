import { z } from 'zod';
import {
    CulinaryStudioArticleIncludedServiceModelSchema,
    CulinaryStudioArticleNonIncludedServiceModelSchema,
    CulinaryStudioArticlePortionModelSchema,
    CulinaryStudioArticleRecommendationModelSchema,
    CulinaryStudioArticleSetModelSchema,
} from './components';

export const CulinaryStudioArticleModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any().optional().nullable(),
    category: z.object({
        uid: z.string(),
        name: z.string(),
    }).optional().nullable(),
    images: z.array(z.object({
        url: z.string(),
    })).optional().nullable(),
    portions: z.array(
        CulinaryStudioArticlePortionModelSchema,
    ).optional().nullable(),
    sets: z.array(
        CulinaryStudioArticleSetModelSchema,
    ).optional().nullable(),
    includedServices: z.array(
        CulinaryStudioArticleIncludedServiceModelSchema,
    ).optional().nullable(),
    nonIncludedServices: z.array(
        CulinaryStudioArticleNonIncludedServiceModelSchema,
    ).optional().nullable(),
    recommendations: z.array(
        CulinaryStudioArticleRecommendationModelSchema,
    ).optional().nullable(),
});

export type CulinaryStudioArticleModel = z.infer<typeof CulinaryStudioArticleModelSchema>;
