import {
    AwardsBlock,
    ContactsBlock,
    CrewMembersBlock,
    DegustationBlock,
    FAQsBlock,
    Footer,
    Header,
    ImagesBlock,
    NavigationBlock,
    PortfolioBlock,
    TopHeader,
} from 'widgets';
import { LeadSection, PageLayout } from 'components';
import React, { memo, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispath } from 'shared';
import { CateringFormatPageActions, useCateringFormatPageState } from '../store';
import { CateringFormatMain } from './components';

const MemoTopHeader = memo(TopHeader);
const MemoHeader = memo(Header);
const MemoFooter = memo(Footer);
const MemoAwardsBlock = memo(AwardsBlock);
const MemoCrewMembersBlock = memo(CrewMembersBlock);
const MemoPortfolioBlock = memo(PortfolioBlock);
const MemoFAQsBlock = memo(FAQsBlock);
const MemoDegustationBlock = memo(DegustationBlock);
const MemoImagesBlock = memo(ImagesBlock);
const MemoContactsBlock = memo(ContactsBlock);
const MemoNavigationBlock = memo(NavigationBlock);
const MemoLeadSection = memo(LeadSection);
const MemoCateringFormatMain = memo(CateringFormatMain);

export const CateringFormatPage = () => {
    const { id } = useParams();
    const dispatch = useAppDispath();
    const { cateringFormat } = useCateringFormatPageState();

    useLayoutEffect(() => {
        if (id) dispatch(CateringFormatPageActions.getCateringFormatByUid({ uid: id }));
    }, []);
    return (
        <PageLayout>
            <MemoTopHeader />
            <MemoHeader />
            <MemoLeadSection
                title="ЕСТЬ ВОПРОСЫ ПО ДОСТАВКЕ?"
                subtitle="Наши менеджеры Вам помогут"
                sidebar={{
                    title: 'СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ',
                }}
            />
            <MemoCateringFormatMain cateringFormat={cateringFormat} />
            <MemoAwardsBlock />
            <MemoImagesBlock />
            <MemoDegustationBlock />
            <MemoCrewMembersBlock />
            <MemoPortfolioBlock />
            <MemoContactsBlock />
            <MemoFAQsBlock />
            <MemoNavigationBlock />
            <MemoFooter />
        </PageLayout>
    );
};
