import {
    getCateringEventByUid,
    GetCateringEventByUidApiResponseSchema,
} from './get-catering-event-by-uid';

export {
    GetCateringEventByUidApiResponseSchema,
};

export const CateringEventPageActions = {
    getCateringEventByUid,
};
