import { z } from 'zod';

export const LeadFormModelSchema = z.object({
    image: z.object({
        url: z.string(),
    }).optional().nullable(),
    commercialOfferFile: z.object({
        url: z.string(),
    }).optional().nullable(),
});

export type LeadFormModel
= z.infer<typeof LeadFormModelSchema>;
