import { z } from 'zod';

export const EventTypeApiFindOneResponseSchema = z.object({
    data: z.object({
        documentId: z.string(),
        name: z.string(),
        description: z.any(),
        category: z.object({
            documentId: z.string(),
            name: z.string(),
        }),
        images: z.array(z.object({
            url: z.string(),
        })).nullable().optional(),
    }),
    meta: z.any().optional(),
});

export type EventTypeApiFindOneResponse
    = z.infer<typeof EventTypeApiFindOneResponseSchema>;
