import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { Icon, IconsNames, Button } from '../../components';

export type HeaderProps = {
    className?: string
}

export const Header = ({
    className,
}: HeaderProps) => (
    <div className={cn(className, styles.header)}>
        <div className={styles.header__bottomContainer}>
            <Icon className={styles.header__logo} iconName={IconsNames.Logo} />

            <Button
                className={cn(styles.header__menuButton, styles.button, styles.button_menu)}
                onClick={() => console.log('')}
            >
                <Icon
                    iconName={IconsNames.Burger}
                />
                <span>
                    Меню
                </span>
            </Button>
            <Link to="/catering">
                Кейтеринг
            </Link>
            <Link to="/delivery">
                Доставка блюд
            </Link>
            <Link to="/culinary-studio">
                Кулинарная студия
            </Link>
            <Link to="/outdoor-bars">
                Выездные бары
            </Link>
            <Link to="/sweet-shop">
                Кондитерская
            </Link>
        </div>
    </div>
);

export const TopHeader = ({
    className,
}: HeaderProps) => (
    <div className={cn(className, styles.topHeader)}>
        <div className={styles.header__topContainer}>
            <div className={styles.header__shortNavigation}>
                <Link to="/home">
                    О нас
                </Link>
                <Link to="/home">
                    Сотрудничество
                </Link>
                <Link to="/home">
                    Контакты
                </Link>
            </div>
            <div className={styles.header__contacts}>
                <div className={styles.iconText}>
                    <Icon iconName={IconsNames.Phone} />
                    <span>
                        +7 (499) 647-61-64
                    </span>
                </div>
                <div className={styles.iconText}>
                    <Icon iconName={IconsNames.Map} />
                    <span>
                        Москва, ул. Зорге, 7г
                    </span>
                </div>
                <div className={styles.iconText}>
                    <Icon iconName={IconsNames.Mail} />
                    <span>
                        info@corsocatering.ru
                    </span>
                </div>
            </div>
        </div>
        <div className={styles.divider_horizontal} />
    </div>
);
