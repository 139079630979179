import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCateringEventFormatByUidApiResponseSchema = z.object({
    data: z.object({
        documentId: z.string(),
        name: z.string(),
        description: z.any(),
        image: z.object({
            url: z.string(),
        }).optional().nullable(),
        category: z.object({
            name: z.string(),
        }).optional().nullable(),
    }),
});

type GetCateringEventFormatByUidActionResponse
    = ApiResponse<z.infer<typeof GetCateringEventFormatByUidApiResponseSchema>>
    | BaseError;

type GetCateringEventFormatByUidActionRequest = {
    uid: string,
}

export const getCateringEventFormatByUid = createAsyncThunk<
    GetCateringEventFormatByUidActionResponse,
    GetCateringEventFormatByUidActionRequest
>(
    'CateringEventFormatActionsGetCateringEventFormatByUid',
    async ({
        uid,
    }) => apiInstance.get(
        `/catering-event-formats/${uid}`,
        {
            'populate[image][fields]': 'url',
            'populate[category][fields]': '*',
        },
        GetCateringEventFormatByUidApiResponseSchema,
    ),
);
