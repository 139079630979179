import React from 'react';
import { Flex, Heading, Image } from '@chakra-ui/react';
import { RichText } from './RichText';

type BlockHeaderProps = {
    title: string,
    description?: any,
    imageSrc: string
}

export const BlockHeader: React.FC<BlockHeaderProps> = ({
    title,
    description,
    imageSrc,
}) => (
    <Flex
        flexDirection="column"
        position="relative"
        aspectRatio="2 / 1"
    >
        <Image
            src={imageSrc}
            position="absolute"
            width="100%"
            height="100%"
        />
        <Flex
            flexDirection="column"
            gap="1rem"
            margin="auto auto 0 0"
            width="50%"
            padding="2rem"
            zIndex={1}
        >
            <Heading
                colorScheme="variant"
                size="lg"
            >
                {title}
            </Heading>
            <RichText
                content={description}
                colorScheme="variant"
                fontSize="2rem"
            />
        </Flex>
    </Flex>
);
