import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    outdoorBarArticleCategoryEntityApiInstance,
    outdoorBarArticleEntityApiInstance,
} from '../../../entities';

const getOutdoorBarArticleCategories = createAsyncThunk(
    'getOutdoorBarArticleCategories',
    async () => {
        const response = await outdoorBarArticleCategoryEntityApiInstance.find();
        return response;
    },
);

type GetOutdoorBarsByCategoryUidRequest = {
    typeUid: string
}
/**
 * Запрос на получение списка выездных баров с фильтрацией по uid типа
 */
export const getOutdoorBarArticleCardsByCategoryUid = createAsyncThunk(
    'getOutdoorBarsCardsByCategoryUid',
    async ({
        typeUid,
    }: GetOutdoorBarsByCategoryUidRequest) => (
        outdoorBarArticleEntityApiInstance.find({
            'filters[category][documentId][$eq]': typeUid,
            'fields[0]': 'name',
            'fields[1]': 'description',
            'populate[images][fields][0]': 'url',
            'populate[category][fields][0]': 'documentId',
        })
    ),
);

export const OutdoorBarsBlockActions = {
    getOutdoorBarArticleCategories,
    getOutdoorBarArticleCardsByCategoryUid,
};
