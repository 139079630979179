// eslint-disable-next-line no-restricted-imports
import { BaseEntityApi } from 'shared/api';

export class OutdoorBarArticleEntityApi extends BaseEntityApi {
    constructor() {
        super('outdoor-bar-articles');
    }
}

export const outdoorBarArticleEntityApiInstance = new OutdoorBarArticleEntityApi();
