import { AxiosRequestConfig } from 'axios';
import { FindArticleTagsResponse, FindArticleTagsResponseSchema } from './responses/find-article-tags-response';
import { Api } from '../../../api/api';
import BaseError from '../../../utils/base-error';
import { ErrorResponseSchema } from '../../../api/base/responses/error-response';

export class ArticleTagApi extends Api {
    findArticleTags = async (): Promise<BaseError | FindArticleTagsResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/article-tags`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<FindArticleTagsResponse>(
            config,
            FindArticleTagsResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };
}

export const articleTagApiInstance = new ArticleTagApi();
