import { z } from 'zod';
import {
    LeadFormModelSchema,
} from 'entities/lead-form';
import { GetLeadFormApiResponseSchema } from '../store';

export const createLeadFormFromGetLeadFormApiResponse = (
    (response: z.infer< typeof GetLeadFormApiResponseSchema>) => (
        LeadFormModelSchema.parse({
            image: response.data.image && {
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + response.data.image.url,
            },
            commercialOfferFile: response.data.commercialOfferFile && {
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST!
                + response.data.commercialOfferFile.url,
            },
        })
    )
);
