import axios, { AxiosRequestConfig } from 'axios';
import BaseError from 'utils/base-error';
import { z } from 'zod';

export type ApiResponse<T = any> = {
    data: T
    requestParams: object
}

interface IApi {
    host: string

    get<T = any>(
        path: string,
        params?: object,
        responseShema?: z.ZodTypeAny
    ): Promise<ApiResponse<T> | BaseError>
}

export class Api implements IApi {
    host: string;

    constructor(
        host: string,
    ) {
        this.host = host;
    }

    async get<T = any>(
        path: string,
        params?: object,
        responseShema?: z.ZodTypeAny,
    ): Promise<ApiResponse<T> | BaseError> {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: this.host + path,
            params,
        };

        try {
            const { status, statusText, data } = await axios(config);

            if ([200, 201].includes(status)) {
                if (responseShema) {
                    const parcedResponse = responseShema.parse(data);
                    console.log('Api.get', responseShema);
                    return {
                        data: parcedResponse,
                        requestParams: {
                            path,
                            params,
                        },
                    };
                }
                return {
                    data,
                    requestParams: {
                        path,
                        params,
                        responseShema,
                    },
                };
            }
            return new BaseError(status, statusText);
        } catch (e) {
            console.log('ARI ERROR', e);
            if (e instanceof z.ZodError) return new BaseError(500, e.message);
            return new BaseError(500, String(e));
        }
    }
}

export const ApiFactory = (() => {
    let instance: Api | null = null;
    return {
        getInstance: () => {
            if (instance === null) {
                instance = new Api(
                    process.env.REACT_APP_STRAPI_HOST!,
                );
            }
            return instance;
        },
    };
})();
