import { AxiosRequestConfig } from 'axios';
import { Api } from '../api';
import { FindFAQsResponse, FindFAQsResponseSchema } from './responses/find-faqs-response';
import { ErrorResponseSchema } from '../base/responses/error-response';
import BaseError from '../../utils/base-error';

export class FAQsApi extends Api {
    findFAQs = async (): Promise<BaseError | FindFAQsResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/faqs`,
        };
        const response = await this.makeCrud<FindFAQsResponse>(
            config,
            FindFAQsResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };
}

export const fAQsApiInstance = new FAQsApi();
