import { createSlice } from '@reduxjs/toolkit';
import BaseError from '../../../utils/base-error';
import { CateringEventFormatPageState } from './catering-event-format-page-state';
import { CateringEventFormatPageActions } from './actions';
import {
    createCateringEventFormatFromGetCateringEventFormatByUidApiResponse,
    createCateringEventsFromGetCateringEventsByFormatUidApiResponse,
} from '../utils';

const initialState: CateringEventFormatPageState = {
    loaded: false,
    cateringEvents: [],
};

export const cateringEventFormatSlice = createSlice({
    name: 'cateringEventFormatSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            CateringEventFormatPageActions.getCateringEventFormatByUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log('LOL2', response);
                        // eslint-disable-next-line max-len
                        const result = createCateringEventFormatFromGetCateringEventFormatByUidApiResponse(
                            response.data,
                        );

                        state.cateringEventFormat = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
        builder.addCase(
            CateringEventFormatPageActions.getCateringEventsByFormatUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log('LOL2', response);
                        // eslint-disable-next-line max-len
                        const result = createCateringEventsFromGetCateringEventsByFormatUidApiResponse(
                            response.data,
                        );

                        state.cateringEvents = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
