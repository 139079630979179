import {
    getCulinaryStudioArticleCategories,
    GetCulinaryStudioArticleCategoriesApiResponseSchema,
} from './get-culinary-studio-article-categories';
import {
    getCulinaryStudioArticleCardsByCategoryUid,
    GetCulinaryStudioArticleCardsByCategoryUidApiResponseSchema,
} from './get-culinary-studio-article-cards-by-category-uid';

export {
    GetCulinaryStudioArticleCategoriesApiResponseSchema,
    GetCulinaryStudioArticleCardsByCategoryUidApiResponseSchema,
};

export const CulinaryStudioPageActions = {
    getCulinaryStudioArticleCategories,
    getCulinaryStudioArticleCardsByCategoryUid,
};
