import { createSlice } from '@reduxjs/toolkit';
import { PortfolioBlockState } from './portfolio-block-state';
import PortfolioBlockActions from './portfolio-block-actions';
import BaseError from '../../../utils/base-error';
import { PortfolioBlockInfoModelSchema } from '../../../models/portfolio-block-info';
import { PortfolioEventModelSchema } from '../../../models/portfolio-event';

const initialState: PortfolioBlockState = {
    loaded: false,
};

// eslint-disable-next-line import/prefer-default-export
export const portfolioBlockSlice = createSlice({
    name: 'portfolioBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(PortfolioBlockActions.getPortfolioBlockInfo.fulfilled, (state, action) => {
            const response = action.payload;

            if (response instanceof BaseError) {
                // TODO
            } else {
                // Валидация
                try {
                    const { documentId, portfolioEvents, image } = response.data;
                    const result = PortfolioBlockInfoModelSchema.parse({
                        uid: documentId,
                        portfolioEvents: portfolioEvents.map(({
                            documentId: documentId2,
                            description,
                            name,
                            images,
                        }) => PortfolioEventModelSchema.parse({
                            uid: documentId2,
                            description,
                            name,
                            images: images?.map((image2) => ({
                                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + image2.url,
                            })),
                        })),
                        image: image && {
                            url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + image.url,
                        },
                    });

                    state.info = result;
                    state.loaded = true;
                } catch (e) {
                    // return new BaseError(400, String(e));
                    // TODO
                }
            }
        });
    },
});
