import { createAsyncThunk } from '@reduxjs/toolkit';
import { portfolioBlockApiInstance } from '../../../api';

const getPortfolioBlockInfo = createAsyncThunk(
    'getPortfolioBlockInfo',
    async () => {
        const response = await portfolioBlockApiInstance.findPortfolioBlockInfo();
        return response;
    },
);

const PortfolioBlockActions = {
    getPortfolioBlockInfo,
};

export default PortfolioBlockActions;
