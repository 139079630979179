import { AxiosRequestConfig } from 'axios';
import { Api } from '../api';
import { FindCrewMembersResponse, FindCrewMembersResponseSchema } from './responses/find-crew-members-response';
import { ErrorResponseSchema } from '../base/responses/error-response';
import BaseError from '../../utils/base-error';

export class CrewMembersApi extends Api {
    findCrewMembers = async (): Promise<BaseError | FindCrewMembersResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/crew-members`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<FindCrewMembersResponse>(
            config,
            FindCrewMembersResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };
}

export const crewMembersApiInstance = new CrewMembersApi();
