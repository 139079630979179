import { createSlice } from '@reduxjs/toolkit';
import BaseError from 'utils/base-error';
import { z } from 'zod';
import { HomeBlockState } from './lead-section-state';
import { LeadSectionActions } from './actions';
import { createLeadFormFromGetLeadFormApiResponse } from '../utils';

const initialState: HomeBlockState = {
    loaded: false,
};

export const leadSectionSlice = createSlice({
    name: 'leadSectionSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            LeadSectionActions.getLeadForm.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    console.log('deliveryBlockSlice BaseError', response);
                } else {
                    try {
                        console.log('deliveryBlockSlice', response);
                        // eslint-disable-next-line max-len
                        state.leadForm = createLeadFormFromGetLeadFormApiResponse(
                            response.data,
                        );
                    } catch (e) {
                        if (e instanceof z.ZodError) {
                            console.log('DeliveryBlockActions.getDeliveryArticlesByCategoryAndTag', e);
                        }
                    }
                }
            },
        );
    },
});
