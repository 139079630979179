import { z } from 'zod';

export const GetIconsResponseSchema = z.object({
    data: z.array(z.object({
        name: z.string(),
        source: z.string(),
    })),
    meta: z.any(),
});

export type GetIconsResponse = z.infer<typeof GetIconsResponseSchema>;
