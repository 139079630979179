import cn from 'classnames';
import React, { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabsType, useAppDispath } from 'shared';
import { Image } from '@chakra-ui/react';
import styles from './PortfolioBlock.module.scss';
import { usePortfolioBlockState } from '../model/portfolio-block-hooks';
import PortfolioBlockActions from '../model/portfolio-block-actions';
import { Icon, IconsNames } from '../../../components';
import { PortfolioEventModel } from '../../../models/portfolio-event';

const createTabsFromPortoflioEvents = (portfolioEvents?: PortfolioEventModel[]) => {
    let tabs = [
        {
            key: 0,
            label: 'Все',
            element: (
                <div key={0} className={styles.portfolioBlock__board}>
                    <Link reloadDocument to={`/portfolio-events/${portfolioEvents?.at(0)?.uid}`}>
                        <Image src={portfolioEvents?.at(0)?.images?.at(0)?.url || ''} />
                    </Link>
                    <Link reloadDocument to={`/portfolio-events/${portfolioEvents?.at(1)?.uid}`}>
                        <Image src={portfolioEvents?.at(1)?.images?.at(0)?.url || ''} />
                    </Link>
                    <Link reloadDocument to={`/portfolio-events/${portfolioEvents?.at(2)?.uid}`}>
                        <Image src={portfolioEvents?.at(2)?.images?.at(0)?.url || ''} />
                    </Link>
                    <Link reloadDocument to={`/portfolio-events/${portfolioEvents?.at(3)?.uid}`}>
                        <Image src={portfolioEvents?.at(3)?.images?.at(0)?.url || ''} />
                    </Link>
                </div>
            ),
        },
    ];

    if (portfolioEvents) {
        const portfolioEventsTabs = portfolioEvents.map(({ name, uid }, index) => ({
            key: index + 1,
            element: (
                <div key={uid} className={styles.portfolioBlock__board}>
                    <Link reloadDocument to={`/portfolio-events/${portfolioEvents?.at(index)?.uid}`}>
                        <Image src={portfolioEvents?.at(index)?.images?.at(0)?.url || ''} />
                    </Link>
                    <Link reloadDocument to={`/portfolio-events/${portfolioEvents?.at(index)?.uid}`}>
                        <Image src={portfolioEvents?.at(index)?.images?.at(1)?.url || ''} />
                    </Link>
                    <Link reloadDocument to={`/portfolio-events/${portfolioEvents?.at(index)?.uid}`}>
                        <Image src={portfolioEvents?.at(index)?.images?.at(2)?.url || ''} />
                    </Link>
                    <Link reloadDocument to={`/portfolio-events/${portfolioEvents?.at(index)?.uid}`}>
                        <Image src={portfolioEvents?.at(index)?.images?.at(3)?.url || ''} />
                    </Link>
                </div>
            ),
            label: name,
        }));
        tabs = tabs.concat(portfolioEventsTabs);
    }
    return tabs;
};

export type PortfolioBlockProps = {
    className?: string
}

export const PortfolioBlock = ({
    className,
}: PortfolioBlockProps) => {
    const { info } = usePortfolioBlockState();
    const dispatch = useAppDispath();

    useLayoutEffect(() => {
        dispatch(PortfolioBlockActions.getPortfolioBlockInfo());
    }, []);

    return (
        <div className={cn(className, styles.portfolioBlock)}>
            <div className={styles.portfolioBlock__sidebar}>
                <Image
                    className={styles.portfolioBlock__image}
                    src={info?.image?.url || ''}
                />
            </div>
            <div className={styles.portfolioBlock__main}>
                <span className={styles.portfolioBlock__title}>
                    НАШЕ
                    <br />
                    ПОРТФОЛИО
                </span>
                <Tabs
                    className={styles.portfolioBlock__tabs}
                    type={TabsType.Small}
                    items={createTabsFromPortoflioEvents(info?.portfolioEvents)}
                    startKey={0}
                />
                <Link to="/portfolio-events">
                    <div className={styles.portfolioBlock__linkToPortfolioInner}>
                        <span>Посмотреть все проекты</span>
                        <Icon
                            className={styles.portfolioBlock__linkIcon}
                            iconName={IconsNames.ArrowRight}
                        />
                    </div>
                </Link>
            </div>
        </div>
    );
};
