import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCateringEventFormatsByCategoryUidApiResponseSchema = z.object({
    data: z.array(
        z.object({
            documentId: z.string(),
            name: z.string(),
            description: z.any().optional().nullable(),
            image: z.object({
                url: z.string(),
            }).optional().nullable(),
        }),
    ),
});

type GetCateringEventFormatsByCategoryUidApiResponse
    = ApiResponse<z.infer<typeof GetCateringEventFormatsByCategoryUidApiResponseSchema>>
    | BaseError;

type GetCateringEventFormatsByCategoryUidActionRequest = {
    uid: string
}

export const getCateringEventFormatsByCategoryUid = createAsyncThunk<
    GetCateringEventFormatsByCategoryUidApiResponse,
    GetCateringEventFormatsByCategoryUidActionRequest
>(
    'CateringPageActionsGetCateringEventFormatsByCategoryUid',
    async ({
        uid,
    }) => apiInstance.get(
        '/catering-event-formats',
        {
            'filters[category][documentId][$eq]': uid,
            'populate[image][fields]': 'url',
        },
        GetCateringEventFormatsByCategoryUidApiResponseSchema,
    ),
);
