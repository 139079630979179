import { createSlice } from '@reduxjs/toolkit';
import BaseError from 'utils/base-error';
import { z } from 'zod';
import { HomePageState } from './home-page-state';
import { HomePageActions } from './actions';
import {
    createCompanyInformationFromGetCompanyInformationApiResponse,
    createPopularServicesGroupFromGetPopularServicesGroupApiResponse,
} from '../utils';

const initialState: HomePageState = {
    loaded: false,
};

export const homePageSlice = createSlice({
    name: 'homePageSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            HomePageActions.getPopularServicesGroup.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    console.log('deliveryPageSlice BaseError', response);
                } else {
                    try {
                        console.log('deliveryPageSlice', response);
                        // eslint-disable-next-line max-len
                        state.popularServicesGroup = createPopularServicesGroupFromGetPopularServicesGroupApiResponse(
                            response.data,
                        );
                    } catch (e) {
                        if (e instanceof z.ZodError) {
                            console.log('DeliveryPageActions.getDeliveryArticlesByCategoryAndTag', e);
                        }
                    }
                }
            },
        );
        builder.addCase(
            HomePageActions.getCompanyInformation.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    console.log('deliveryPageSlice BaseError', response);
                } else {
                    try {
                        console.log('deliveryPageSlice', response);
                        // eslint-disable-next-line max-len
                        state.companyInformation = createCompanyInformationFromGetCompanyInformationApiResponse(
                            response.data,
                        );
                    } catch (e) {
                        if (e instanceof z.ZodError) {
                            console.log('DeliveryPageActions.getDeliveryArticlesByCategoryAndTag', e);
                        }
                    }
                }
            },
        );
    },
});
