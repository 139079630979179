import { z } from 'zod';
import { DeliveryArticleCardModelSchema } from 'entities/delivery-article';
import { GetDeliveryArticlesByCategoryAndTagApiResponseSchema } from '../model/actions';

export const createDeliveryArticleCardsFromGetDeliveryArticlesByCategoryAndTagApiResponse = (
    (response: z.infer< typeof GetDeliveryArticlesByCategoryAndTagApiResponseSchema>) => (
        response.data.map(({
            documentId,
            name,
            weight,
            price,
            currency,
            images,

        }) => DeliveryArticleCardModelSchema.parse({
            uid: documentId,
            name,
            weight,
            price,
            currency: currency && {
                abbreviation: currency.abbreviation,
            },
            image: images && {
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + String(images[0].url),
            },
        }))
    )
);
