import { z } from 'zod';
import { CateringEventModelSchema } from 'entities/catering-event';
import { GetCateringEventsByFormatUidApiResponseSchema } from '../store';

export const createCateringEventsFromGetCateringEventsByFormatUidApiResponse = (
    (response: z.infer< typeof GetCateringEventsByFormatUidApiResponseSchema>) => (
        response.data.map(({
            documentId,
            name,
            description,
            images,
            format,
        }) => CateringEventModelSchema.parse({
            uid: documentId,
            name,
            description,
            images: images?.map(({ url }) => ({
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + url,
            })),
            format: format && ({
                name: format.name,
            }),
        }))
    )
);
