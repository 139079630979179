import {
    getDeliveryArticlesByCategoryAndTag,
    GetDeliveryArticlesByCategoryAndTagApiResponseSchema,
} from './get-delivery-articles-by-category-and-tag';
import {
    getDeliveryArticleCategories,
    GetDeliveryArticleCategoriesApiResponseSchema,
} from './get-delivery-article-categories';
import {
    getDeliveryArticleTags,
    GetDeliveryArticleTagsApiResponseSchema,
} from './get-delivery-article-tags';

export {
    GetDeliveryArticlesByCategoryAndTagApiResponseSchema,
    GetDeliveryArticleCategoriesApiResponseSchema,
    GetDeliveryArticleTagsApiResponseSchema,
};

export const DeliveryPageActions = {
    getDeliveryArticlesByCategoryAndTag,
    getDeliveryArticleCategories,
    getDeliveryArticleTags,
};
