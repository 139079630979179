import { z } from 'zod';

export type Markup = {
    cmp: string,
    items?: Markup[]
    options?: any
    style?: any,
    datasource?: {
        name: string,
        attributes: string[]
    }
    [key: string]: any
};

export const MarkupSchema: z.ZodType<Markup> = z.lazy(() => z.object({
    cmp: z.string(),
    items: z.array(MarkupSchema).optional(),
    options: z.any(),
    style: z.any(),
    datasource: z.object({
        name: z.string(),
        attributes: z.array(z.string()),
    }).optional(),
}));

export const MarkupTextOptionsSchema = z.object({
    text: z.string().or(z.number()).optional(),
    link: z.string().optional(),
    isList: z.boolean().optional(),
});

export type MarkupTextOptions = z.infer<typeof MarkupTextOptionsSchema>;

export const MarkupRichTextOptionsSchema = z.object({
    content: z.any(),
});

export type MarkupRichTextOptions = z.infer<typeof MarkupRichTextOptionsSchema>;

export const MarkupImageOptionsSchema = z.object({
    url: z.string(),
});

export type MarkupImageOptions = z.infer<typeof MarkupImageOptionsSchema>;

export const MarkupInputOptionsSchema = z.object({
    placeholder: z.string().optional(),
    key: z.string().optional(),
});

export type MarkupInputOptions = z.infer<typeof MarkupInputOptionsSchema>;

export const MarkupTabsGroupOptionsSchema = z.object({
    start: z.number(),
});

export type MarkupTabsGroupOptions = z.infer<typeof MarkupTabsGroupOptionsSchema>;

export const MarkupTabOptionsSchema = z.object({
    label: z.string(),
    selectedClassName: z.any().optional(),
});

export type MarkupTabOptions = z.infer<typeof MarkupTabOptionsSchema>;
