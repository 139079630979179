import { z } from 'zod';
import {
    PopularServicesGroupCateringModelSchema,
    PopularServicesGroupCulinaryStudioModelSchema,
    PopularServicesGroupDeliveryModelSchema,
    PopularServicesGroupModelSchema,
    PopularServicesGroupOutdoorBarModelSchema,
    PopularServicesGroupSweetShopModelSchema,
} from 'entities/popular-services-group';
import { GetPopularServicesGroupApiResponseSchema } from '../store';

export const createPopularServicesGroupFromGetPopularServicesGroupApiResponse = (
    (response: z.infer< typeof GetPopularServicesGroupApiResponseSchema>) => (
        PopularServicesGroupModelSchema.parse({
            catering: PopularServicesGroupCateringModelSchema.parse({
                image: response.data.catering?.image && {
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST!
                    + response.data.catering.image.url,
                },
                formats: response.data.catering?.formats?.map(({
                    documentId,
                    name,
                    shortDescription,
                }) => ({
                    uid: documentId,
                    name,
                    shortDescription,
                })),
            }),
            delivery: PopularServicesGroupDeliveryModelSchema.parse({
                image: response.data.delivery?.image && {
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST!
                    + response.data.delivery.image.url,
                },
                articleCategories: response.data.delivery?.articleCategories?.map(({
                    documentId,
                    name,
                    shortDescription,
                }) => ({
                    uid: documentId,
                    name,
                    shortDescription,
                })),
            }),
            culinaryStudio: PopularServicesGroupCulinaryStudioModelSchema.parse({
                image: response.data.culinaryStudio?.image && {
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST!
                    + response.data.culinaryStudio.image.url,
                },
                articleCategories: response.data.culinaryStudio?.articleCategories?.map(({
                    documentId,
                    name,
                    shortDescription,
                }) => ({
                    uid: documentId,
                    name,
                    shortDescription,
                })),
            }),
            outdoorBar: PopularServicesGroupOutdoorBarModelSchema.parse({
                image: response.data.outdoorBar?.image && {
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST!
                    + response.data.outdoorBar.image.url,
                },
                articleCategories: response.data.outdoorBar?.articleCategories?.map(({
                    documentId,
                    name,
                    shortDescription,
                }) => ({
                    uid: documentId,
                    name,
                    shortDescription,
                })),
            }),
            sweetShop: PopularServicesGroupSweetShopModelSchema.parse({
                image: response.data.sweetShop?.image && {
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST!
                    + response.data.sweetShop.image.url,
                },
                articleCategories: response.data.sweetShop?.articleCategories?.map(({
                    documentId,
                    name,
                    shortDescription,
                }) => ({
                    uid: documentId,
                    name,
                    shortDescription,
                })),
            }),
        })
    )
);
