import React, { useLayoutEffect } from 'react';
import {
    Button,
    Grid,
    GridProps,
    Text,
} from '@chakra-ui/react';
import { RichText, SimpleCarousel, useAppDispath } from 'shared';
import { useParams } from 'react-router-dom';
import { Icon, IconsNames } from 'components';
import { LeadSection, LeadSectionType } from 'features';
import { DeliveryArticlePageActions, useDeliveryArticlePageState } from '../../store';
import { DeliveryArticlePageMainHeader } from './DeliveryArticlePageMainHeader';
import { DeliveryArticlePageMainElementsSection } from './DeliveryArticlePageMainElementsSection';
import { DeliveryArticlePageMainRecommendationsSection } from './DeliveryArticlePageMainRecommendationsSection';

export type DeliveryArticlePageMainProps = GridProps & React.RefAttributes<HTMLDivElement>

export const DeliveryArticlePageMain: React.FC<DeliveryArticlePageMainProps> = ({
    ...props
}) => {
    const dispatch = useAppDispath();
    const { id } = useParams();
    const { deliveryArticle } = useDeliveryArticlePageState();

    useLayoutEffect(() => {
        if (id) {
            dispatch(DeliveryArticlePageActions.getDeliveryArticleByUid({
                uid: id,
            }));
        }
    }, []);

    return (
        <Grid
            gap="2rem"
            {...props}
        >
            <DeliveryArticlePageMainHeader
                title={deliveryArticle?.name || ''}
                padding="2rem 2rem 0 2rem"
            />
            <Grid
                templateColumns="1fr 1fr"
                padding="0 2rem"
                gap="2rem"
            >
                <SimpleCarousel
                    images={deliveryArticle?.images?.map(({ url }) => url) || []}
                />
                <Grid
                    gap="2rem"
                    templateRows="auto 1fr auto"
                >
                    <DeliveryArticlePageMainElementsSection
                        elements={deliveryArticle?.elements || []}
                    />
                    <RichText
                        content={deliveryArticle?.description}
                    />
                    <Grid
                        templateColumns="1fr auto"
                    >
                        <Grid>
                            <Text fontSize="1rem">
                                Вес:
                                {' '}
                                {deliveryArticle?.weight}
                                г
                            </Text>
                            <Text fontSize="2rem" fontWeight={900}>
                                {deliveryArticle?.price}
                                {deliveryArticle?.currency?.abbreviation}
                            </Text>
                        </Grid>
                        <Button
                            gap="1rem"
                        >
                            <Icon
                                iconName={IconsNames.Plus}
                            />
                            <Text
                                fontSize="1rem"
                            >
                                Добавить в заказ
                            </Text>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <LeadSection
                type={LeadSectionType.Small}
                title="НЕ МОЖЕТЕ ОПРЕДЕЛИТЬСЯ?"
                buttonLabel="Помогите выбрать"
                padding="2rem"
            />
            {deliveryArticle?.recommendations && (
                <DeliveryArticlePageMainRecommendationsSection
                    padding="0 2rem 2rem 2rem"
                    deliveryArticleRecommendations={deliveryArticle.recommendations}
                />
            )}
        </Grid>
    );
};
