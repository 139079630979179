import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    sweetShopArticleCategoryApiInstance,
} from '../../../entities';

const getSweetShopArticleCategories = createAsyncThunk(
    'sweetShopBlockActionsGetSweetShopArticleCategories',
    async () => sweetShopArticleCategoryApiInstance.find({
        'fields[0]': 'name',
        'fields[1]': 'description',
        'populate[image][fields][0]': 'url',
    }),
);

export const SweetShopBlockActions = {
    getSweetShopArticleCategories,
};
