import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetDeliveryArticleCategoriesApiResponseSchema = z.object({
    data: z.array(
        z.object({
            documentId: z.string(),
            name: z.string(),
        }),
    ),
});

type GetDeliveryArticleCategoriesActionResponse
    = ApiResponse<z.infer<typeof GetDeliveryArticleCategoriesApiResponseSchema>>
    | BaseError;

type GetDeliveryArticleCategoriesActionRequest = undefined

export const getDeliveryArticleCategories = createAsyncThunk<
    GetDeliveryArticleCategoriesActionResponse,
    GetDeliveryArticleCategoriesActionRequest
>(
    'DeliveryPageActionsGetDeliveryArticleCategories',
    async () => apiInstance.get(
        '/delivery-article-categories',
        {},
        GetDeliveryArticleCategoriesApiResponseSchema,
    ),
);
