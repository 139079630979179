import { z } from 'zod';

export const PopularServicesGroupCulinaryStudioModelSchema = z.object({
    image: z.object({
        url: z.string(),
    }).optional().nullable(),
    articleCategories: z.array(
        z.object({
            uid: z.string(),
            name: z.string(),
            shortDescription: z.string().optional().nullable(),
        }),
    ).optional().nullable(),
});

export type PopularServicesGroupCulinaryStudioModel
= z.infer<typeof PopularServicesGroupCulinaryStudioModelSchema>;
