import { store } from 'shared';
import React from 'react';
import { Provider } from 'react-redux';

// eslint-disable-next-line no-undef, import/prefer-default-export
export const withRedux = (children: () => JSX.Element): JSX.Element => (
    <Provider store={store}>
        {children()}
    </Provider>
);
