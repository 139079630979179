import { z } from 'zod';

export const OutdoorBarArticleCategoryPopularCardModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    shortDescription: z.string().optional().nullable(),
    image: z.object({
        url: z.string(),
    }).optional(),
});

export type OutdoorBarArticleCategoryPopularCardModel
= z.infer<typeof OutdoorBarArticleCategoryPopularCardModelSchema>;
