import {
    getCulinaryStudioArticleByUid,
    GetCulinaryStudioArticleByUidApiResponseSchema,
} from './get-culinary-studio-article-by-uid';

export {
    GetCulinaryStudioArticleByUidApiResponseSchema,
};

export const CulinaryStudioArticlePageActions = {
    getCulinaryStudioArticleByUid,
};
