import { z } from 'zod';

export const PortfolioEventApiFindCardByCategoryUidResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
        description: z.any(),
        images: z.array(z.object({
            url: z.string(),
        })).nullable().optional(),
        category: z.object({
            documentId: z.string(),
            name: z.string(),
        }),
    })),
    meta: z.any().optional(),
});

export type PortfolioEventApiFindCardByCategoryUidResponse
    = z.infer<typeof PortfolioEventApiFindCardByCategoryUidResponseSchema>;
