import { defineRecipe } from '@chakra-ui/react';

export const containerRecipe = defineRecipe({
    variants: {
        colorScheme: {
            primary: {
                color: 'black',

                _hover: {

                },
            },
            variant: {
                color: 'white',

                _hover: {

                },
            },
        },
    },
});
