import { createSlice } from '@reduxjs/toolkit';
import { z } from 'zod';
import { CateringPageState } from './catering-page-state';
import BaseError from '../../../utils/base-error';
import { CateringPageActions } from './actions';
import { createCateringEventCategoriesFromGetCateringEventCategoriesApiResponse, createCateringEventFormatsFromGetCateringEventFormatsApiResponse, createCateringFormatsFromGetCateringFormatsApiResponse } from '../utils';

const initialState: CateringPageState = {
    loaded: false,
    cateringFormats: [],
    cateringEventCategories: [],
    cateringEventFormats: [],
};

export const cateringPageSlice = createSlice({
    name: 'cateringPageSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            CateringPageActions.getCateringFormats.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    console.log('deliveryBlockSlice BaseError', response);
                } else {
                    try {
                        // eslint-disable-next-line max-len
                        state.cateringFormats = createCateringFormatsFromGetCateringFormatsApiResponse(
                            response.data,
                        );
                    } catch (e) {
                        if (e instanceof z.ZodError) {
                            console.log('DeliveryBlockActions.getDeliveryArticlesByCategoryAndTag', e.message);
                        }
                    }
                }
            },
        );
        builder.addCase(
            CateringPageActions.getCateringEventCategories.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    console.log('deliveryBlockSlice BaseError', response);
                } else {
                    try {
                        // eslint-disable-next-line max-len
                        state.cateringEventCategories = createCateringEventCategoriesFromGetCateringEventCategoriesApiResponse(
                            response.data,
                        );
                    } catch (e) {
                        if (e instanceof z.ZodError) {
                            console.log('DeliveryBlockActions.getDeliveryArticlesByCategoryAndTag', e.message);
                        }
                    }
                }
            },
        );
        builder.addCase(
            CateringPageActions.getCateringEventFormatsByCategoryUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    console.log('deliveryBlockSlice BaseError', response);
                } else {
                    try {
                        // eslint-disable-next-line max-len
                        state.cateringEventFormats = createCateringEventFormatsFromGetCateringEventFormatsApiResponse(
                            response.data,
                        );
                    } catch (e) {
                        if (e instanceof z.ZodError) {
                            console.log('DeliveryBlockActions.getDeliveryArticlesByCategoryAndTag', e.message);
                        }
                    }
                }
            },
        );
    },
});
