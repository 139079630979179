import {
    Grid,
    GridProps,
    Heading,
    Text,
} from '@chakra-ui/react';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { RichText } from 'shared';

export type CulinaryStudioArticlePageMainPortionCardProps = {
    value: number
    measure: string
    description: any
    weight?: number
    price?: number
    currency?: string
    isActive: boolean
} & GridProps & React.RefAttributes<HTMLDivElement>

export const CulinaryStudioArticlePageMainPortionCard
: React.FC<CulinaryStudioArticlePageMainPortionCardProps> = ({
    value,
    measure,
    description,
    weight,
    price,
    currency,
    isActive,
    ...props
}) => (
    <Grid
        templateRows="auto 1fr auto"
        {...props}
        background={isActive ? '#F8C9C4' : 'transparent'}
        border="3px solid #E74C3C"
        padding="1.25rem"
        gap="1rem"
    >
        <Heading
            size="sm"
        >
            {value}
            {' '}
            {measure.toLocaleUpperCase()}
        </Heading>
        <RichText
            fontSize="1rem"
            textAlign="justify"
            content={description}
        />
        <Grid>
            <Text
                fontSize="1rem"
            >
                Вес:
                {' '}
                {weight}
                {' г'}
            </Text>
            <Text
                fontSize="2.25rem"
                fontWeight="900"
            >
                {price}
                {' '}
                {currency}
            </Text>
        </Grid>
    </Grid>
);
