import React, {
    memo,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import {
    Flex,
    FlexProps,
    Grid,
    Text,
} from '@chakra-ui/react';
import { GridCard, useAppDispath } from 'shared';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Tabs } from 'shared/ui/Tabs';
import { CulinaryStudioArticleCardModel, CulinaryStudioArticleCategoryModel } from 'entities';
import {
    CulinaryStudioPageActions,
    useCulinaryStudioPageState,
} from '../../store';
import { CulinaryStudioPageMainHeader } from './CulinaryStudioPageMainHeader';

/**
 * Мемоизированный хедер блока, тк как он не перерисовывается
 */
const MemoHeader = memo(CulinaryStudioPageMainHeader);

/**
 * Функция, которая создаёт компонент лейбла для таба
 *
 * @param {string} label Текст
 * @returns {JSX.Element} Компонент
 */
const createCulinaryStudioArticlesTabsItemLabel = (
    label: string,
) => (
    <Text fontSize="1.5rem">{label.toLocaleUpperCase()}</Text>
);

/**
 * Функция, которая создаёт компонент контента в табе
 *
 * @param {CulinaryStudioArticleModel[]} culinaryStudioArticles Список позиций кондитерской
 * @returns {JSX.Element} Компонент
 */
const createCulinaryStudioArticlesTabsContentItem = (
    culinaryStudioArticles: CulinaryStudioArticleCardModel[],
) => (
    <Grid
        key="all"
        templateColumns="1fr 1fr 1fr 1fr"
        gap="2rem"
    >
        {culinaryStudioArticles.map(({
            uid,
            name: name2,
            image,

        }) => (
            <GridCard
                label={name2}
                to={`/culinary-studio/${uid}`}
                src={image?.url}
                aspectRatio="1 / 1"
            />
        ))}
    </Grid>
);

/**
 * Функция, которая создаёт список компонентов для табов
 *
 * @param {CulinaryStudioArticleModel[]} culinaryStudioArticles Список позиций кондитерской
 * @param {CulinaryStudioArticleCategoryTagModel[]} sweetShopArticleCategoryTags
 * Список тегов для текущей категории
 * @returns {JSX.Element} Параметр items в TabsProps
 */
const createCulinaryStudioArticlesTabsItems = (
    culinaryStudioArticles: CulinaryStudioArticleCardModel[],
    culinaryStudioArticleCategory: CulinaryStudioArticleCategoryModel[],
) => culinaryStudioArticleCategory.map(({
    uid,
    name,
}) => ({
    key: uid,
    labelComponent: createCulinaryStudioArticlesTabsItemLabel(name),
    contentComponent: createCulinaryStudioArticlesTabsContentItem(culinaryStudioArticles),
}));

export type CulinaryStudioPageMainProps = FlexProps & React.RefAttributes<HTMLDivElement>

export const CulinaryStudioPageMain = (props: CulinaryStudioPageMainProps) => {
    const {
        culinaryStudioArticleCategories,
        culinaryStudioArticleCards,
    } = useCulinaryStudioPageState();
    const dispatch = useAppDispath();
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTabKey, setSelectedTabKey] = useState(searchParams.get('tab') || undefined);

    useEffect(() => {
        const urlTab = searchParams.get('tab');
        if (urlTab && urlTab !== selectedTabKey) {
            setSelectedTabKey(urlTab);
        }
    }, [searchParams.get('tab')]);

    useLayoutEffect(() => {
        dispatch(CulinaryStudioPageActions.getCulinaryStudioArticleCategories());
    }, []);

    useEffect(() => {
        if (!selectedTabKey) {
            setSelectedTabKey(culinaryStudioArticleCategories.at(0)?.uid);
        }
    }, [culinaryStudioArticleCategories]);

    useEffect(() => {
        if (selectedTabKey) {
            dispatch(CulinaryStudioPageActions.getCulinaryStudioArticleCardsByCategoryUid({
                categoryUid: selectedTabKey,
            }));
            if (selectedTabKey !== searchParams.get('tab')) {
                setSearchParams({
                    tab: selectedTabKey,
                });
            }
        }
    }, [selectedTabKey]);

    /**
     * Мемоизированные табы
     */
    const memoTabsItems = useMemo(() => (
        createCulinaryStudioArticlesTabsItems(
            culinaryStudioArticleCards,
            culinaryStudioArticleCategories,
        )
    ), [culinaryStudioArticleCards, culinaryStudioArticleCategories]);

    console.log('KJH', culinaryStudioArticleCards);
    return (
        <Flex
            {...props}
            flexDirection="column"
            gap="2rem"
            padding="2rem"
        >
            <MemoHeader />
            <Tabs
                value={selectedTabKey}
                onValueChange={(v) => setSelectedTabKey(v)}
                items={memoTabsItems}
            />
        </Flex>
    );
};
