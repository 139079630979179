import { z } from 'zod';
import { CateringEventFormatModelSchema } from 'entities/catering-event-format';
import { GetCateringEventFormatByUidApiResponseSchema } from '../store';

export const createCateringEventFormatFromGetCateringEventFormatByUidApiResponse = (
    (response: z.infer< typeof GetCateringEventFormatByUidApiResponseSchema>) => (
        CateringEventFormatModelSchema.parse({
            uid: response.data.documentId,
            name: response.data.name,
            description: response.data.description,
            image: response.data.image && ({
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + response.data.image.url,
            }),
            category: response.data.category && ({
                name: response.data.category.name,
            }),
        })
    )
);
