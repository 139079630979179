import { z } from 'zod';
import { DeliveryArticleRecommendationModelSchema } from './delivery-article-recommendation-model';

export const DeliveryArticleModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any().optional().nullable(),
    weight: z.number().optional().nullable(),
    price: z.number().optional().nullable(),
    images: z.array(
        z.object({
            url: z.string(),
        }),
    ).optional().nullable(),
    elements: z.array(
        z.object({
            name: z.string(),
            value: z.number(),
            meassure: z.string(),
        }),
    ).optional().nullable(),
    currency: z.object({
        name: z.string(),
        abbreviation: z.string(),
    }).optional().nullable(),
    category: z.object({
        name: z.string(),
    }).optional().nullable(),
    tags: z.array(
        z.object({
            name: z.string(),
        }),
    ).optional().nullable(),
    recommendations: z.array(
        DeliveryArticleRecommendationModelSchema,
    ).optional().nullable(),
});

export type DeliveryArticleModel
= z.infer<typeof DeliveryArticleModelSchema>;
