import { AxiosRequestConfig } from 'axios';
import { Api } from '../api';
import { FindPortfolioBlockResponse, FindPortfolioBlockResponseSchema } from './responses/find-porfolio-block-response';
import { ErrorResponseSchema } from '../base/responses/error-response';
import BaseError from '../../utils/base-error';

export class PortfolioBlockApi extends Api {
    findPortfolioBlockInfo = async (): Promise<BaseError | FindPortfolioBlockResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/portfolio-block`,
            params: {
                'populate[portfolioEvents][populate]': 'images',
                populate: 'image',
            },
        };
        const response = await this.makeCrud<FindPortfolioBlockResponse>(
            config,
            FindPortfolioBlockResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };
}

export const portfolioBlockApiInstance = new PortfolioBlockApi();
