import cn from 'classnames';
import React, { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispath } from 'shared';
import styles from './NavigationBlock.module.scss';
import { useNavigationBlockState } from '../model/navigation-block-hooks';
import NavigationBlockActions from '../model/navigation-block-actions';

export type NavigationBlockProps = {
    className?: string
}

export const NavigationBlock = ({
    className,
}: NavigationBlockProps) => {
    const {
        cateringFormats,
        eventCategories,
        eventTypes,
        sweetShopArticleCategories,
        articleCategories,
        articleTags,
        culinaryStudioCategories,
        culinaryStudioTypes,
        outdoorBarArticleCategories,
    } = useNavigationBlockState();
    const dispatch = useAppDispath();

    useLayoutEffect(() => {
        dispatch(NavigationBlockActions.getCateringFormats());
        dispatch(NavigationBlockActions.getEventCategories());
        dispatch(NavigationBlockActions.getEventTypes());
        dispatch(NavigationBlockActions.getSweetShopArticleCategories());
        dispatch(NavigationBlockActions.getArticleCategories());
        dispatch(NavigationBlockActions.getArticleTags());
        dispatch(NavigationBlockActions.getCulinaryStudioCategories());
        dispatch(NavigationBlockActions.getCulinaryStudioTypes());
        dispatch(NavigationBlockActions.getOutdoorBarArticleCategories());
    }, []);

    return (
        <div className={cn(className, styles.navigationBlock)}>
            <div className={styles.navigationBlock__main}>
                <div className={styles.navigationBlock__cateringSection}>
                    <h2>
                        <Link to="/catering">
                            Кейтеринг
                        </Link>
                    </h2>
                    <div>
                        <div>
                            <Link to="/catering?tab=formats">
                                Форматы
                            </Link>
                            <ul>
                                {cateringFormats.map(({ name, uid }) => (
                                    <li>
                                        <Link to={`/catering/formats/${uid}`}>
                                            {name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {eventCategories.map(({ name, uid }) => (
                            <div key={uid}>
                                <div>
                                    <Link to={`/catering?tab=${uid}`}>
                                        {name}
                                    </Link>
                                    <ul>
                                        {eventTypes.filter(
                                            (eventType) => eventType.category.name === name,
                                        ).map((eventType) => (
                                            <li>
                                                <Link to={`/catering/events/${uid}/${eventType.uid}`}>
                                                    {eventType.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.navigationBlock__sweetShopSection}>
                    <h2>
                        <Link to="/sweet-shop">
                            Кондитерская Corso
                        </Link>
                    </h2>
                    <div>
                        {sweetShopArticleCategories.map(({ name, uid }) => (
                            <Link to={`/sweet-shop/${uid}`}>
                                {name}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className={styles.navigationBlock__deliverySection}>
                    <h2>
                        <Link to="/delivery" className={styles.navigationBlock__title}>
                            Доставка
                        </Link>
                    </h2>
                    <div>
                        <ul>
                            {articleCategories.map(({ name, uid }) => (
                                <li>
                                    <Link to={`/delivery?tab=${uid}&tag=all`}>
                                        {name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <div>
                            <span>
                                Поводы
                            </span>
                            <div>
                                {articleTags.map(({ uid, name }) => (
                                    <Link to={`/delivery?tab=${articleCategories.at(0)?.uid}&tag=${uid}`}>
                                        {name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.navigationBlock__culinaryStudioSection}>
                    <h2>
                        <Link to="/culinary-studio">
                            Кулинарная студия
                        </Link>
                    </h2>
                    <div>
                        {culinaryStudioCategories.map(({ name, uid }) => (
                            <div key={uid}>
                                <div>
                                    <Link to={`/culinary-studio?tab=${uid}`}>
                                        {name}
                                    </Link>
                                    <div>
                                        {culinaryStudioTypes.filter(
                                            (culinaryStudioType) => (
                                                culinaryStudioType.category.name === name
                                            ),
                                        ).map((culinaryStudioType) => (
                                            <Link to={`/culinary-studio/${uid}/${culinaryStudioType.uid}`}>
                                                {culinaryStudioType.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.navigationBlock__outdoorBarsSection}>
                    <h2>
                        <Link to="/outdoor-bars">
                            Выездные бары
                        </Link>
                    </h2>
                    <div>
                        {outdoorBarArticleCategories.map(({ name, uid }) => (
                            <Link to={`/outdoor-bars?tab=${uid}`}>
                                {name}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
