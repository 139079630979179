import { z } from 'zod';

export const CulinaryStudioArticleCategoryPopularCardModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    shortDescription: z.string().optional().nullable(),
    image: z.object({
        url: z.string(),
    }).optional(),
});

export type CulinaryStudioArticleCategoryPopularCardModel
= z.infer<typeof CulinaryStudioArticleCategoryPopularCardModelSchema>;
