import { defineRecipe } from '@chakra-ui/react';

export const buttonRecipe = defineRecipe({
    base: {
        borderRadius: 0,
        transition: '0.25s',
    },
    variants: {
        size: {
            sm: {},
            md: {
                fontSize: '1.25rem',
                padding: '1.5rem',
            },
            lg: {
                fontSize: '2rem',
                fontWeight: 'bold',
                padding: '1rem',
            },
        },
        colorScheme: {
            primary: {
                bg: 'black',

                _hover: {

                },
            },
            accent: {
                bg: '#E74C3C',
                color: 'white',

                _hover: {

                },
            },
        },
    },
});
