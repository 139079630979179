// eslint-disable-next-line no-restricted-imports
import { BaseEntityApi } from 'shared/api';

export type OutdoorBarArticleElementsSetDto = {
    documentId?: string,
    name?: string,
    elements?: {
        documentId: string,
    }[]
}

export class OutdoorBarArticleElementsSetEntityApi
    extends BaseEntityApi<OutdoorBarArticleElementsSetDto> {
    constructor() {
        super('outdoor-bar-article-categories');
    }
}

export const outdoorBarArticleElementsSetEntityApiInstance = new
OutdoorBarArticleElementsSetEntityApi();
