import React, {
    memo,
    useEffect,
    useState,
} from 'react';
import {
    Flex,
    FlexProps,
    Grid,
    Tabs,
    Text,
} from '@chakra-ui/react';
import { GridCard, useAppDispath } from 'shared';
import { useSearchParams } from 'react-router-dom';
import { CateringEventCategoryModel, CateringEventFormatModel, CateringFormatModel } from 'entities';
import { CateringPageActions } from '../../store';
import { CateringPageMainHeader } from './CateringPageMainHeader';

/**
 * Мемоизированный хедер блока, тк как он не перерисовывается
 */
const MemoHeader = memo(CateringPageMainHeader);

export type CateringPageMainProps = {
    cateringEventCategories: CateringEventCategoryModel[],
    cateringFormats: CateringFormatModel[],
    cateringEventFormats: CateringEventFormatModel[],
} & FlexProps & React.RefAttributes<HTMLDivElement>

export const CateringPageMain: React.FC<CateringPageMainProps> = ({
    cateringEventCategories,
    cateringFormats,
    cateringEventFormats,
    ...props
}) => {
    const dispatch = useAppDispath();

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTab, setSelectedTab] = useState(searchParams.get('tab') || 'formats');

    const handleTabChange = (key: string) => {
        setSearchParams({
            tab: key,
        });
        setSelectedTab(key);
    };

    useEffect(() => {
        if (searchParams.get('tab') !== selectedTab) {
            setSelectedTab(searchParams.get('tab') || 'formats');
        }
    }, [searchParams.get('tab')]);

    useEffect(() => {
        if (selectedTab && selectedTab !== 'formats') {
            dispatch(CateringPageActions.getCateringEventFormatsByCategoryUid({
                uid: selectedTab,
            }));
        }
    }, [selectedTab]);

    return (
        <Flex
            {...props}
            flexDirection="column"
            gap="2rem"
            padding="2rem"
        >
            <MemoHeader />
            <Tabs.Root
                visual="plain"
                size="lg"
                variant="plain"
                value={selectedTab}
                onValueChange={(e: any) => handleTabChange(e.value)}
            >
                <Flex overflow="scroll" scrollbar="hidden">
                    <Tabs.List>
                        <Tabs.Trigger key="formats" value="formats">
                            <Text
                                fontSize="1.5rem"
                            >
                                ФОРМАТЫ
                            </Text>
                        </Tabs.Trigger>
                        {cateringEventCategories.map(({ uid, name }) => (
                            <Tabs.Trigger key={uid} value={uid}>
                                <Text
                                    fontSize="1.5rem"
                                >
                                    {name.toLocaleUpperCase()}
                                </Text>
                            </Tabs.Trigger>
                        ))}
                    </Tabs.List>
                </Flex>
                <Tabs.Content value="formats" key="formats" marginTop="2rem">
                    <Grid gap="2rem" templateColumns="1fr 1fr 1fr 1fr">
                        {cateringFormats.map(({ uid, name, images }) => (
                            <GridCard
                                flex="1 0 21%"
                                aspectRatio="1 / 1"
                                label={name}
                                to={`/catering/formats/${uid}`}
                                src={images?.at(0)?.url}
                            />
                        ))}
                    </Grid>
                </Tabs.Content>
                {cateringEventCategories.map(({ uid }) => (
                    <Tabs.Content value={uid} key={uid} marginTop="2rem">
                        <Grid gap="2rem" templateColumns="1fr 1fr 1fr 1fr">
                            {cateringEventFormats.map(({ uid: uid2, name, image }) => (
                                <GridCard
                                    flex="1 0 21%"
                                    aspectRatio="1 / 1"
                                    label={name}
                                    to={`/catering/event-format/${uid2}`}
                                    src={image?.url}
                                />
                            ))}
                        </Grid>
                    </Tabs.Content>
                ))}
            </Tabs.Root>
        </Flex>
    );
};
