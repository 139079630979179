import {
    Flex,
    Grid,
    Heading,
    Image,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from './RichText';

type TopicCardProps = {
    to: string,
    title: string,
    description: any,
    imagesSrcs: string[]
    imagesContainerType: 1 | 2
}

export const TopicCard: React.FC<TopicCardProps> = ({
    to,
    title,
    description,
    imagesSrcs,
    imagesContainerType,
}) => (
    <Grid
        gap="2rem"
        templateColumns="5fr 3fr"
    >
        <Grid
            gap="1rem"
            templateColumns={imagesContainerType === 1 ? '3fr 2fr' : '2fr 3fr'}
        >
            <Image
                src={imagesSrcs.at(0)}
                width="100%"
                height="100%"
                objectFit="cover"
                aspectRatio={(imagesContainerType === 1) ? '1 / 1' : undefined}
            />
            <Image
                src={imagesSrcs.at(1)}
                width="100%"
                height="100%"
                objectFit="cover"
                aspectRatio={(imagesContainerType === 2) ? '1 / 1' : undefined}
            />
        </Grid>
        <Flex
            flexDirection="column"
            gap="1rem"
        >
            <Link to={to}>
                <Heading
                    size="sm"
                    textDecoration="underline"
                >
                    {title}
                </Heading>
            </Link>
            <RichText
                fontSize="1rem"
                content={description}
                lineClamp={5}
            />
        </Flex>
    </Grid>
);
