import { defineRecipe } from '@chakra-ui/react';

export const textRecipe = defineRecipe({
    base: {
        padding: 0,
        margin: 0,
    },
    variants: {
        size: {
            lg: {
                fontSize: '2rem',
            },
            md: {
                fontSize: '1.5rem',
            },
        },
        colorScheme: {
            primary: {
                color: 'black',

                _hover: {

                },
            },
            variant: {
                color: 'white',

                _hover: {

                },
            },
        },
    },
});
