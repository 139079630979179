import { createSlice } from '@reduxjs/toolkit';
import BaseError from '../../../utils/base-error';
import { CateringFormatPageState } from './catering-format-page-state';
import { CateringFormatPageActions } from './actions';
import { createCateringFormatFromGetCateringFormatByUidApiResponse } from '../utils';

const initialState: CateringFormatPageState = {
    loaded: false,
};

export const cateringFormatPageSlice = createSlice({
    name: 'cateringFormatPageSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            CateringFormatPageActions.getCateringFormatByUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log('LOL2', response);
                        const result = createCateringFormatFromGetCateringFormatByUidApiResponse(
                            response.data,
                        );

                        state.cateringFormat = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
