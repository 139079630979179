import { z } from 'zod';

export const AwardModelSchema = z.object({
    uid: z.string(),
    description: z.any().optional(),
    image: z.object({
        url: z.string(),
    }).optional(),
    years: z.array(z.number()),
});

export type AwardModel = z.infer<typeof AwardModelSchema>;
