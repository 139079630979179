import { createSlice } from '@reduxjs/toolkit';
import { CulinaryStudioPageState } from './state';
import { CulinaryStudioPageActions } from './actions';
import BaseError from '../../../utils/base-error';
import {
    createCulinaryStudioArticleCardsFromGetCulinaryStudioArticleCardsByCategoryUidApiResponse,
    createCulinaryStudioArticleCategoriesFromGetCulinaryStudioArticleCategoriesApiResponse,
} from '../utils';

const initialState: CulinaryStudioPageState = {
    loaded: false,
    culinaryStudioArticleCategories: [],
    culinaryStudioArticleCards: [],
};

export const culinaryStudioPageSlice = createSlice({
    name: 'culinaryStudioPageSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /**
         * Обработчик получения списка типов баров (коллекция culinaryStudioArticleCategory)
         */
        builder.addCase(
            CulinaryStudioPageActions.getCulinaryStudioArticleCategories.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        // eslint-disable-next-line max-len
                        const result = createCulinaryStudioArticleCategoriesFromGetCulinaryStudioArticleCategoriesApiResponse(
                            response.data,
                        );

                        state.culinaryStudioArticleCategories = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
        /**
         * Обработчик получения списка карточек
         * выездных баров по типу (коллекция CulinaryStudioArticle)
         */
        builder.addCase(
            CulinaryStudioPageActions.getCulinaryStudioArticleCardsByCategoryUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        // eslint-disable-next-line max-len
                        const result = createCulinaryStudioArticleCardsFromGetCulinaryStudioArticleCardsByCategoryUidApiResponse(
                            response.data,
                        );
                        state.culinaryStudioArticleCards = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
