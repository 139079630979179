import { createSlice } from '@reduxjs/toolkit';
import { NavigationBlockState } from './navigation-block-state';
import NavigationBlockActions from './navigation-block-actions';
import BaseError from '../../../utils/base-error';
import {
    ArticleCategoryModelSchema,
    ArticleTagModelSchema,
    CateringFormatModelSchema,
    CulinaryStudioArticleCategoryModelSchema,
    CulinaryStudioTypeModelSchema,
    EventCategoryModelSchema,
    EventTypeModelSchema,
    parseOutdoorBarArticleCategoryDtoToDomain,
    SweetShopArticleCategoryModelSchema,
} from '../../../entities';

const initialState: NavigationBlockState = {
    loaded: false,
    cateringFormats: [],
    eventCategories: [],
    eventTypes: [],
    sweetShopArticleCategories: [],
    articleCategories: [],
    articleTags: [],
    culinaryStudioCategories: [],
    culinaryStudioTypes: [],
    outdoorBarArticleCategories: [],
};

// eslint-disable-next-line import/prefer-default-export
export const navigationBlockSlice = createSlice({
    name: 'navigationBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(NavigationBlockActions.getCateringFormats.fulfilled, (state, action) => {
            const response = action.payload;

            if (response instanceof BaseError) {
                // TODO
            } else {
                // Валидация
                try {
                    const result = response.data.map(({
                        documentId,
                        description,
                        images,
                        name,
                    }) => CateringFormatModelSchema.parse({
                        uid: documentId,
                        description,
                        images: images?.map(({ url }) => ({
                            url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + url,
                        })),
                        name,
                    }));

                    state.cateringFormats = result;
                    state.loaded = true;
                } catch (e) {
                    // return new BaseError(400, String(e));
                    // TODO
                }
            }
        });
        builder.addCase(NavigationBlockActions.getEventCategories.fulfilled, (state, action) => {
            const response = action.payload;

            if (response instanceof BaseError) {
                // TODO
            } else {
                // Валидация
                try {
                    const result = response.data.map(({
                        documentId,
                        name,
                    }) => EventCategoryModelSchema.parse({
                        uid: documentId,
                        name,
                    }));

                    state.eventCategories = result;
                    state.loaded = true;
                } catch (e) {
                    // return new BaseError(400, String(e));
                    // TODO
                }
            }
        });
        builder.addCase(NavigationBlockActions.getEventTypes.fulfilled, (state, action) => {
            const response = action.payload;

            if (response instanceof BaseError) {
                // TODO
            } else {
                // Валидация
                try {
                    const result = response.data.map(({
                        documentId,
                        name,
                        category,
                    }) => EventTypeModelSchema.parse({
                        uid: documentId,
                        name,
                        category: EventCategoryModelSchema.parse({
                            uid: category.documentId,
                            name: category.name,
                        }),
                    }));

                    state.eventTypes = result;
                    state.loaded = true;
                } catch (e) {
                    // return new BaseError(400, String(e));
                    // TODO
                }
            }
        });
        builder.addCase(
            NavigationBlockActions.getSweetShopArticleCategories.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        const result = response.data.map(({
                            documentId,
                            name,
                            description,
                            image,
                        }) => SweetShopArticleCategoryModelSchema.parse({
                            uid: documentId,
                            name,
                            description,
                            image: image && {
                                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + image.url,
                            },
                        }));

                        state.sweetShopArticleCategories = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
        builder.addCase(
            NavigationBlockActions.getArticleCategories.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        const result = response.data.map(({
                            documentId,
                            name,
                        }) => ArticleCategoryModelSchema.parse({
                            uid: documentId,
                            name,
                        }));

                        state.articleCategories = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
        builder.addCase(
            NavigationBlockActions.getArticleTags.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        const result = response.data.map(({
                            documentId,
                            name,
                        }) => ArticleTagModelSchema.parse({
                            uid: documentId,
                            name,
                        }));

                        state.articleTags = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
        builder.addCase(
            NavigationBlockActions.getCulinaryStudioCategories.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        const result = response.data.map(({
                            documentId,
                            name,
                        }) => CulinaryStudioArticleCategoryModelSchema.parse({
                            uid: documentId,
                            name,
                        }));

                        state.culinaryStudioCategories = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
        builder.addCase(
            NavigationBlockActions.getCulinaryStudioTypes.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        const result = response.data.map(({
                            documentId,
                            name,
                            category,
                        }) => CulinaryStudioTypeModelSchema.parse({
                            uid: documentId,
                            name,
                            category: CulinaryStudioArticleCategoryModelSchema.parse({
                                uid: category.documentId,
                                name: category.name,
                            }),
                        }));

                        state.culinaryStudioTypes = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
        builder.addCase(
            NavigationBlockActions.getOutdoorBarArticleCategories.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        const result = response.data.map(
                            (dto) => parseOutdoorBarArticleCategoryDtoToDomain(dto),
                        );

                        state.outdoorBarArticleCategories = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
