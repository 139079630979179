import { FlexProps } from '@chakra-ui/react';
import React from 'react';
import { BlockHeader } from 'shared';

/**
 * Пропсы для компонента хедера компонента блока
 * позиций кондитерской
 */
export type SweetShopArticlesBlockHeaderProps = {
    name: string;
    description?: any;
    imageSrc: string;
} & FlexProps & React.RefAttributes<HTMLDivElement>

export const SweetShopArticlesBlockHeader: React.FC<SweetShopArticlesBlockHeaderProps> = ({
    name,
    description,
    imageSrc,
}) => (
    <BlockHeader
        title={name}
        description={description || []}
        imageSrc={imageSrc}
    />
);
