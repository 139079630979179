import { z } from 'zod';

export const CateringEventModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any(),
    images: z.array(
        z.object({
            url: z.string(),
        }),
    ).nullable().optional(),
    format: z.object({
        name: z.string(),
    }).nullable().optional(),
});

export type CateringEventModel = z.infer<typeof CateringEventModelSchema>;
