import { z } from 'zod';

export const CateringFormatModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any().optional().nullable(),
    images: z.array(z.object({
        url: z.string(),
    })).optional().nullable(),
    menus: z.array(z.object({
        name: z.string(),
        description: z.any().optional().nullable(),
        warning: z.string().optional().nullable(),
        image: z.object({
            url: z.string(),
        }).optional().nullable(),
        estimateFile: z.object({
            url: z.string(),
        }).optional().nullable(),
    })).optional().nullable(),
});

export type CateringFormatModel = z.infer<typeof CateringFormatModelSchema>;
