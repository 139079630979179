import React from 'react';

export type ButtonProps = {
    className?: string
    children: React.JSX.Element | React.JSX.Element[],
    onClick?: () => any
    disabled?: boolean
}

export const Button = ({
    className,
    children,
    onClick,
    disabled,
}: ButtonProps) => (
    <button
        className={className}
        type="button"
        onClick={onClick}
        disabled={disabled}
    >
        {children}
    </button>
);
