import {
    Grid,
    GridProps,
    Image,
    Text,
} from '@chakra-ui/react';
import {
    PopularServicesGroupCateringModel,
    PopularServicesGroupCulinaryStudioModel,
    PopularServicesGroupDeliveryModel,
    PopularServicesGroupOutdoorBarModel,
    PopularServicesGroupSweetShopModel,
} from 'entities/popular-services-group';
import React from 'react';
import { Link } from 'react-router-dom';

export type HomePageMainPopularServicesGroupSectionProps = {
    catering?: PopularServicesGroupCateringModel,
    delivery?: PopularServicesGroupDeliveryModel,
    culinaryStudio?: PopularServicesGroupCulinaryStudioModel,
    outdoorBar?: PopularServicesGroupOutdoorBarModel,
    sweetShop?: PopularServicesGroupSweetShopModel,
} & GridProps & React.RefAttributes<HTMLDivElement>

export const HomePageMainPopularServicesGroupSection
: React.FC<HomePageMainPopularServicesGroupSectionProps> = ({
    catering,
    delivery,
    culinaryStudio,
    outdoorBar,
    sweetShop,
    ...props
}) => (
    <Grid
        {...props}
        templateColumns="1fr 1fr 1fr"
        gap="2rem"
    >
        {catering && (
            <Grid
                gap="1rem"
                height="fit-content"
            >
                <Link to="/catering">
                    <Text
                        fontSize="2rem"
                        fontWeight="light"
                    >
                        КЕЙТЕРИНГ
                    </Text>
                </Link>
                <Grid
                    height="10px"
                    background="black"
                />
                <Image
                    src={catering?.image?.url}
                    aspectRatio="3 / 2"
                    objectFit="cover"
                />
                <Grid
                    templateColumns="auto 1fr"
                    gap="1.25rem"
                >
                    <Grid
                        width="2px"
                        background="black"
                    />
                    <Grid
                        gap="2rem"
                    >
                        {catering.formats?.map(({
                            uid,
                            name,
                            shortDescription,
                        }) => (
                            <Grid
                                gap="1rem"
                                height="fit-content"
                            >
                                <Link to={`/catering/formats${uid}`}>
                                    <Text
                                        fontSize="1.5rem"
                                        fontWeight="bold"
                                        textDecoration="underline"
                                    >
                                        {name}
                                    </Text>
                                </Link>
                                <Text
                                    fontSize="1rem"
                                    textAlign="justify"
                                >
                                    {shortDescription}
                                </Text>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        )}
        {delivery && (
            <Grid
                gap="1rem"
                height="fit-content"
            >
                <Link to="/delivery">
                    <Text
                        fontSize="2rem"
                        fontWeight="light"
                    >
                        ДОСТАВКА БЛЮД
                    </Text>
                </Link>
                <Grid
                    height="10px"
                    background="black"
                />
                <Image
                    src={delivery.image?.url}
                    aspectRatio="3 / 2"
                    objectFit="cover"
                />
                <Grid
                    templateColumns="auto 1fr"
                    gap="1.25rem"
                >
                    <Grid
                        width="2px"
                        background="black"
                    />
                    <Grid
                        gap="2rem"
                    >
                        {delivery.articleCategories?.map(({
                            uid,
                            name,
                            shortDescription,
                        }) => (
                            <Grid
                                gap="1rem"
                                height="fit-content"
                            >
                                <Link to={`/delivery?tab=${uid}`}>
                                    <Text
                                        fontSize="1.5rem"
                                        fontWeight="bold"
                                        textDecoration="underline"
                                    >
                                        {name}
                                    </Text>
                                </Link>
                                <Text
                                    fontSize="1rem"
                                    textAlign="justify"
                                >
                                    {shortDescription}
                                </Text>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        )}
        {culinaryStudio && (
            <Grid
                gap="1rem"
                height="fit-content"
            >
                <Link to="/culinary-studio">
                    <Text
                        fontSize="2rem"
                        fontWeight="light"
                    >
                        КУЛИНАРНАЯ СТУДИЯ
                    </Text>
                </Link>
                <Grid
                    height="10px"
                    background="black"
                />
                <Image
                    src={culinaryStudio.image?.url}
                    aspectRatio="3 / 2"
                    objectFit="cover"
                />
                <Grid
                    templateColumns="auto 1fr"
                    gap="1.25rem"
                >
                    <Grid
                        width="2px"
                        background="black"
                    />
                    <Grid
                        gap="2rem"
                    >
                        {culinaryStudio.articleCategories?.map(({
                            uid,
                            name,
                            shortDescription,
                        }) => (
                            <Grid
                                gap="1rem"
                                height="fit-content"
                            >
                                <Link to={`/culinary-studio?tab=${uid}`}>
                                    <Text
                                        fontSize="1.5rem"
                                        fontWeight="bold"
                                        textDecoration="underline"
                                    >
                                        {name}
                                    </Text>
                                </Link>
                                <Text
                                    fontSize="1rem"
                                    textAlign="justify"
                                >
                                    {shortDescription}
                                </Text>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        )}
        {outdoorBar && (
            <Grid
                gap="1rem"
                height="fit-content"
            >
                <Link to="/outdoor-bars">
                    <Text
                        fontSize="2rem"
                        fontWeight="light"
                    >
                        ВЫЕЗДНЫЕ БАРЫ
                    </Text>
                </Link>
                <Grid
                    height="10px"
                    background="black"
                />
                <Image
                    src={outdoorBar.image?.url}
                    aspectRatio="3 / 2"
                    objectFit="cover"
                />
                <Grid
                    templateColumns="auto 1fr"
                    gap="1.25rem"
                >
                    <Grid
                        width="2px"
                        background="black"
                    />
                    <Grid
                        gap="2rem"
                    >
                        {outdoorBar.articleCategories?.map(({
                            uid,
                            name,
                            shortDescription,
                        }) => (
                            <Grid
                                gap="1rem"
                                height="fit-content"
                            >
                                <Link to={`/outdoor-bars?tab=${uid}`}>
                                    <Text
                                        fontSize="1.5rem"
                                        fontWeight="bold"
                                        textDecoration="underline"
                                    >
                                        {name}
                                    </Text>
                                </Link>
                                <Text
                                    fontSize="1rem"
                                    textAlign="justify"
                                >
                                    {shortDescription}
                                </Text>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        )}
        {sweetShop && (
            <Grid
                gap="1rem"
                height="fit-content"
            >
                <Link to="/sweet-shop">
                    <Text
                        fontSize="2rem"
                        fontWeight="light"
                    >
                        КОНДИТЕРСКАЯ
                    </Text>
                </Link>
                <Grid
                    height="10px"
                    background="black"
                />
                <Image
                    src={sweetShop.image?.url}
                    aspectRatio="3 / 2"
                    objectFit="cover"
                />
                <Grid
                    templateColumns="auto 1fr"
                    gap="1.25rem"
                >
                    <Grid
                        width="2px"
                        background="black"
                    />
                    <Grid
                        gap="2rem"
                    >
                        {sweetShop.articleCategories?.map(({
                            uid,
                            name,
                            shortDescription,
                        }) => (
                            <Grid
                                gap="1rem"
                                height="fit-content"
                            >
                                <Link to={`/sweet-shop/${uid}`}>
                                    <Text
                                        fontSize="1.5rem"
                                        fontWeight="bold"
                                        textDecoration="underline"
                                    >
                                        {name}
                                    </Text>
                                </Link>
                                <Text
                                    fontSize="1rem"
                                    textAlign="justify"
                                >
                                    {shortDescription}
                                </Text>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        )}
    </Grid>
);
