import { z } from 'zod';

export const CrewMemberModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    jobTitle: z.string(),
    description: z.any().optional(),
    image: z.object({
        url: z.string(),
    }).optional(),
});

export type CrewMemberModel = z.infer<typeof CrewMemberModelSchema>;
