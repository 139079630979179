import React, {
    memo,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import {
    Flex,
    FlexProps,
    Grid,
    Text,
} from '@chakra-ui/react';
import { GridCard, useAppDispath } from 'shared';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Tabs } from 'shared/ui/Tabs';
import { OutdoorBarArticleCardModel, OutdoorBarArticleCategoryModel } from 'entities';
import { OutdoorBarsBlockActions } from '../model/outdoor-bars-block-actions';
import { OutdoorBarsBlockHeader } from './components';
import { useOutdoorBarsBlockState } from '../model';

/**
 * Мемоизированный хедер блока, тк как он не перерисовывается
 */
const MemoHeader = memo(OutdoorBarsBlockHeader);

/**
 * Функция, которая создаёт компонент лейбла для таба
 *
 * @param {string} label Текст
 * @returns {JSX.Element} Компонент
 */
const createOutdoorBarArticlesTabsItemLabel = (
    label: string,
) => (
    <Text fontSize="1.5rem">{label.toLocaleUpperCase()}</Text>
);

/**
 * Функция, которая создаёт компонент контента в табе
 *
 * @param {OutdoorBarArticleModel[]} outdoorBarArticles Список позиций кондитерской
 * @returns {JSX.Element} Компонент
 */
const createOutdoorBarArticlesTabsContentItem = (
    outdoorBarArticles: OutdoorBarArticleCardModel[],
) => (
    <Grid
        key="all"
        templateColumns="1fr 1fr 1fr 1fr"
        gap="2rem"
    >
        {outdoorBarArticles.map(({
            uid,
            name: name2,
            image,

        }) => (
            <GridCard
                label={name2}
                to={`/outdoor-bars/${uid}`}
                src={image?.url}
                aspectRatio="1 / 1"
            />
        ))}
    </Grid>
);

/**
 * Функция, которая создаёт список компонентов для табов
 *
 * @param {OutdoorBarArticleModel[]} outdoorBarArticles Список позиций кондитерской
 * @param {OutdoorBarArticleCategoryTagModel[]} sweetShopArticleCategoryTags
 * Список тегов для текущей категории
 * @returns {JSX.Element} Параметр items в TabsProps
 */
const createOutdoorBarArticlesTabsItems = (
    outdoorBarArticles: OutdoorBarArticleCardModel[],
    outdoorBarArticleCategory: OutdoorBarArticleCategoryModel[],
) => outdoorBarArticleCategory.map(({
    uid,
    name,
}) => ({
    key: uid,
    labelComponent: createOutdoorBarArticlesTabsItemLabel(name),
    contentComponent: createOutdoorBarArticlesTabsContentItem(outdoorBarArticles),
}));

export type OutdoorBarsBlockProps = FlexProps & React.RefAttributes<HTMLDivElement>

export const OutdoorBarsBlock = (props: OutdoorBarsBlockProps) => {
    const {
        outdoorBarArticleCategories,
        outdoorBarArticleCards,
    } = useOutdoorBarsBlockState();
    const dispatch = useAppDispath();
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTabKey, setSelectedTabKey] = useState(searchParams.get('tab') || undefined);

    useEffect(() => {
        const urlTab = searchParams.get('tab');
        if (urlTab && urlTab !== selectedTabKey) {
            setSelectedTabKey(urlTab);
        }
    }, [searchParams.get('tab')]);

    useLayoutEffect(() => {
        dispatch(OutdoorBarsBlockActions.getOutdoorBarArticleCategories());
    }, []);

    useEffect(() => {
        if (!selectedTabKey) {
            setSelectedTabKey(outdoorBarArticleCategories.at(0)?.uid);
        }
    }, [outdoorBarArticleCategories]);

    useEffect(() => {
        if (selectedTabKey) {
            dispatch(OutdoorBarsBlockActions.getOutdoorBarArticleCardsByCategoryUid({
                typeUid: selectedTabKey,
            }));
            if (selectedTabKey !== searchParams.get('tab')) {
                setSearchParams({
                    tab: selectedTabKey,
                });
            }
        }
    }, [selectedTabKey]);

    /**
     * Мемоизированные табы
     */
    const memoTabsItems = useMemo(() => (
        createOutdoorBarArticlesTabsItems(
            outdoorBarArticleCards,
            outdoorBarArticleCategories,
        )
    ), [outdoorBarArticleCards, outdoorBarArticleCategories]);

    console.log('KJH', outdoorBarArticleCards);
    return (
        <Flex
            {...props}
            flexDirection="column"
            gap="2rem"
            padding="2rem"
        >
            <MemoHeader />
            <Tabs
                value={selectedTabKey}
                onValueChange={(v) => setSelectedTabKey(v)}
                items={memoTabsItems}
            />
        </Flex>
    );
};
