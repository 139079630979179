import React, {
    memo,
    useLayoutEffect,
} from 'react';
import {
    Flex,
    FlexProps,
    Grid,
} from '@chakra-ui/react';
import { GridCard, useAppDispath } from 'shared';
import { SweetShopBlockActions } from '../model/sweet-shop-block-actions';
import { SweetShopBlockHeader } from './components';
import { useSweetShopBlockState } from '../model';

/**
 * Мемоизированный хедер блока, тк как он не перерисовывается
 */
const MemoHeader = memo(SweetShopBlockHeader);

export type SweetShopBlockProps = FlexProps & React.RefAttributes<HTMLDivElement>

export const SweetShopBlock = (props: SweetShopBlockProps) => {
    const {
        sweetShopArticleCategories,
    } = useSweetShopBlockState();
    const dispatch = useAppDispath();

    useLayoutEffect(() => {
        dispatch(SweetShopBlockActions.getSweetShopArticleCategories());
    }, []);
    console.log(sweetShopArticleCategories);
    return (
        <Flex
            {...props}
            flexDirection="column"
            gap="2rem"
            padding="2rem"
        >
            <MemoHeader />
            <Grid templateColumns="repeat(4, 1fr)" gap="2rem">
                {sweetShopArticleCategories.map(({ uid: uid2, name, image }) => (
                    <GridCard
                        flex="1 0 21%"
                        aspectRatio="1 / 1"
                        label={name}
                        to={`/sweet-shop/${uid2}`}
                        src={image?.url}
                    />
                ))}
            </Grid>
        </Flex>
    );
};
