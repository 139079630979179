import { z } from 'zod';

export const CulinaryStudioArticleSetModelSchema = z.object({
    name: z.string(),
    elements: z.array(
        z.object({
            name: z.string(),
            meassure: z.string(),
            value: z.number(),
        }),
    ).optional().nullable(),
});

export type CulinaryStudioArticleSetModel = z.infer<typeof CulinaryStudioArticleSetModelSchema>;
