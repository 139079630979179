import React from 'react';
import cn from 'classnames';
import styles from './Input.module.scss';

// eslint-disable-next-line no-shadow
export enum InputTheme {
    Light,
    Dark
}

export type InputProps = {
    className?: string,
    value?: string
    placeholder?: string
    theme?: InputTheme
    onChange?: (value: string) => void
}

export const Input = ({
    className,
    value,
    placeholder,
    theme,
    onChange,
}: InputProps) => {
    let inputClassName: string;

    switch (theme) {
    case InputTheme.Light: {
        inputClassName = styles.input_light;
        break;
    }
    default: {
        inputClassName = styles.input_dark;
    }
    }
    return (
        <input
            className={cn(className, styles.input, inputClassName)}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange && onChange(e.target.value)}
        />
    );
};
