import { z } from 'zod';

export const SweetShopArticleCategoryModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any().optional().nullable(),
    addictionalDescription: z.any().optional().nullable(),
    image: z.object({
        url: z.string(),
    }).optional().nullable(),
});

export type SweetShopArticleCategoryModel = z.infer<typeof SweetShopArticleCategoryModelSchema>;
