import { z } from 'zod';
import { CateringEventCategoryModelSchema } from 'entities/catering-event-category';
import { GetCateringEventCategoriesApiResponseSchema } from '../store';

export const createCateringEventCategoriesFromGetCateringEventCategoriesApiResponse = (
    (response: z.infer< typeof GetCateringEventCategoriesApiResponseSchema>) => (
        response.data.map(({
            documentId,
            name,
        }) => (
            CateringEventCategoryModelSchema.parse({
                uid: documentId,
                name,
            })
        ))
    )
);
