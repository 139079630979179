// eslint-disable-next-line no-restricted-imports
import { BaseEntityApi } from 'shared/api';

export class DeliveryArticleEntityApi
    extends BaseEntityApi {
    constructor() {
        super('culinary-studio-article-categories');
    }
}

export const deliveryArticleEntityApiInstance = new
DeliveryArticleEntityApi();
