import { Flex } from '@chakra-ui/react';
import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispath, BlockHeader } from 'shared';
import { LeadSection, LeadSectionType } from 'features';
import {
    CateringEventFormatPageActions,
    useCateringEventFormatPageState,
} from '../../store';
import { CateringEventFormatPageMainCateringEventsList } from './CateringEventFormatPageMainCateringEventsList';

export const CateringEventFormatPageMain = () => {
    const { id } = useParams();
    const dispatch = useAppDispath();
    const { cateringEventFormat, cateringEvents } = useCateringEventFormatPageState();

    useLayoutEffect(() => {
        if (id) dispatch(CateringEventFormatPageActions.getCateringEventFormatByUid({ uid: id }));
        if (id) {
            dispatch(CateringEventFormatPageActions.getCateringEventsByFormatUid({
                formatUid: id,
            }));
        }
    }, []);

    return (
        <Flex
            flexDirection="column"
        >
            <BlockHeader
                title={cateringEventFormat?.name || ''}
                description={cateringEventFormat?.description || []}
                imageSrc={cateringEventFormat?.image?.url || ''}
            />
            <LeadSection
                type={LeadSectionType.Small}
                title="ХОТИТЕ ОФОРМИТЬ ЗАКАЗ??"
                padding="2rem"
                buttonLabel="Задать вопрос"
                background="black"
            />
            <CateringEventFormatPageMainCateringEventsList
                padding="2rem"
                cateringEvents={cateringEvents}
            />
        </Flex>
    );
};
