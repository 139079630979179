import { z } from 'zod';

export const EventApiFindByEventTypeUIDResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
        description: z.any(),
        eventType: z.object({
            documentId: z.string(),
            name: z.string(),
        }),
        images: z.array(z.object({
            url: z.string(),
        })).nullable().optional(),
    })),
    meta: z.any().optional(),
});

export type EventApiFindByEventTypeUIDResponse
    = z.infer<typeof EventApiFindByEventTypeUIDResponseSchema>;
