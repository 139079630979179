import { z } from 'zod';

export const FindArticleTagsResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
    })),
    meta: z.any().optional(),
});

export type FindArticleTagsResponse = z.infer<typeof FindArticleTagsResponseSchema>;
