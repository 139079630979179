// eslint-disable-next-line no-restricted-imports
import { BaseEntityApi } from 'shared/api';

export type SweetShopArticleDto = {
    documentId: string,
    name?: string,
    description?: any,
    images?: {
        url: string
    }[]
    sweetShopArticleCategory?: {
        documentId: string
    },
    tags?: {
        documentId: string
    }[],
}

export class SweetShopArticleApi extends BaseEntityApi<SweetShopArticleDto> {
    constructor() {
        super('sweet-shop-articles');
    }
}

export const sweetShopArticleApiInstance = new SweetShopArticleApi();
