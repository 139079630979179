import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetPopularServicesGroupApiResponseSchema = z.object({
    data: z.object({
        catering: z.object({
            image: z.object({
                url: z.string(),
            }).optional().nullable(),
            formats: z.array(
                z.object({
                    documentId: z.string(),
                    name: z.string(),
                    shortDescription: z.string().optional().nullable(),
                }),
            ).optional().nullable(),
        }).optional().nullable(),
        delivery: z.object({
            image: z.object({
                url: z.string(),
            }).optional().nullable(),
            articleCategories: z.array(
                z.object({
                    documentId: z.string(),
                    name: z.string(),
                    shortDescription: z.string().optional().nullable(),
                }),
            ).optional().nullable(),
        }).optional().nullable(),
        culinaryStudio: z.object({
            image: z.object({
                url: z.string(),
            }).optional().nullable(),
            articleCategories: z.array(
                z.object({
                    documentId: z.string(),
                    name: z.string(),
                    shortDescription: z.string().optional().nullable(),
                }),
            ).optional().nullable(),
        }).optional().nullable(),
        outdoorBar: z.object({
            image: z.object({
                url: z.string(),
            }).optional().nullable(),
            articleCategories: z.array(
                z.object({
                    documentId: z.string(),
                    name: z.string(),
                    shortDescription: z.string().optional().nullable(),
                }),
            ).optional().nullable(),
        }).optional().nullable(),
        sweetShop: z.object({
            image: z.object({
                url: z.string(),
            }).optional().nullable(),
            articleCategories: z.array(
                z.object({
                    documentId: z.string(),
                    name: z.string(),
                    shortDescription: z.string().optional().nullable(),
                }),
            ).optional().nullable(),
        }).optional().nullable(),
    }),
});

type GetPopularServicesGroupApiResponse
    = ApiResponse<z.infer<typeof GetPopularServicesGroupApiResponseSchema>>
    | BaseError;

type GetPopularServicesGroupActionRequest = undefined

export const getPopularServicesGroup = createAsyncThunk<
    GetPopularServicesGroupApiResponse,
    GetPopularServicesGroupActionRequest
>(
    'HomePageActionsGetPopularServicesGroup',
    async () => apiInstance.get(
        '/popular-services-group',
        {
            'populate[catering][populate][image][fields]': 'url',
            'populate[catering][populate][formats][fields][0]': 'name',
            'populate[catering][populate][formats][fields][1]': 'shortDescription',
            'populate[delivery][populate][image][fields]': 'url',
            'populate[delivery][populate][articleCategories][fields][0]': 'name',
            'populate[delivery][populate][articleCategories][fields][1]': 'shortDescription',
            'populate[culinaryStudio][populate][image][fields]': 'url',
            'populate[culinaryStudio][populate][articleCategories][fields][0]': 'name',
            'populate[culinaryStudio][populate][articleCategories][fields][1]': 'shortDescription',
            'populate[outdoorBar][populate][image][fields]': 'url',
            'populate[outdoorBar][populate][articleCategories][fields][0]': 'name',
            'populate[outdoorBar][populate][articleCategories][fields][1]': 'shortDescription',
            'populate[sweetShop][populate][image][fields]': 'url',
            'populate[sweetShop][populate][articleCategories][fields][0]': 'name',
            'populate[sweetShop][populate][articleCategories][fields][1]': 'shortDescription',
        },
        GetPopularServicesGroupApiResponseSchema,
    ),
);
