import {
    Grid,
    GridProps,
} from '@chakra-ui/react';
import { DeliveryArticleTagModel } from 'entities';
import { DeliveryArticleCard, DeliveryArticleCardModel } from 'entities/delivery-article';
import React, { useMemo } from 'react';
import { FilterSection, FilterSectionItemModel } from 'shared';

const createDeliveryPageMainTabsContentFilterSectionItems = (
    value: any,
    tags: DeliveryArticleTagModel[],
): FilterSectionItemModel[] => (
    [
        {
            label: 'Все',
            key: 'all',
        },
    ].concat(tags.map(({
        uid,
        name,
    }) => ({
        label: name,
        key: uid,
    })))
);

export type DeliveryPageMainTabsContentProps = {
    selectedTagUid: string
    tags: DeliveryArticleTagModel[],
    cards: DeliveryArticleCardModel[],
    onChangeTag: (v: any) => void
} & GridProps & React.RefAttributes<HTMLDivElement>

export const DeliveryPageMainTabsContent: React.FC<DeliveryPageMainTabsContentProps> = ({
    selectedTagUid,
    tags,
    cards,
    onChangeTag,
}) => {
    const memoFilterItems = useMemo(() => (
        createDeliveryPageMainTabsContentFilterSectionItems(selectedTagUid, tags)
    ), [selectedTagUid, tags]);

    return (
        <Grid
            templateColumns="1fr 3fr"
            gap="2rem"
        >
            <FilterSection
                title="ПОВОДЫ"
                value={selectedTagUid}
                onValueChange={(v) => onChangeTag(v)}
                items={memoFilterItems}
                background="#000000"
                padding="2rem"
            />
            <Grid
                templateColumns="1fr 1fr 1fr"
                gap="2rem"
            >
                {cards.map(({
                    uid,
                    name,
                    weight,
                    price,
                    currency,
                    image,
                }) => (
                    <DeliveryArticleCard
                        name={name}
                        weight={weight || undefined}
                        price={price || undefined}
                        currencyAbbreviation={currency?.abbreviation}
                        image={(image && {
                            src: image.url,
                        }) || undefined}
                        link={{
                            to: `/delivery/${uid}`,
                        }}
                    />
                ))}
            </Grid>
        </Grid>
    );
};
