import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetBestImagesGroupApiResponseSchema = z.object({
    data: z.object({
        images: z.array(
            z.object({
                url: z.string(),
            }),
        ).optional().nullable(),
    }),
});

type GetBestImagesGroupApiResponse
    = ApiResponse<z.infer<typeof GetBestImagesGroupApiResponseSchema>>
    | BaseError;

type GetBestImagesGroupActionRequest = undefined

export const getBestImagesGroup = createAsyncThunk<
    GetBestImagesGroupApiResponse,
    GetBestImagesGroupActionRequest
>(
    'ImagesBlockActionsGetBestImagesGroup',
    async () => apiInstance.get(
        '/best-images-group',
        {
            'populate[images][fields]': 'url',
        },
        GetBestImagesGroupApiResponseSchema,
    ),
);
