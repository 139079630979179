import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './LeadForm.module.scss';
import {
    Input,
    InputTheme,
    TextArea,
    TextAreaTheme,
} from '../../shared';
import { Button } from '../button/Button';

export type LeadFormProps = {
    className?: string;
    title: string
    subtitle: string
    pdf?: {
        url: string
    },
    hasTextArea?: boolean
}

export const LeadForm = ({
    className,
    title,
    subtitle,
    hasTextArea = false,
    pdf,
}: LeadFormProps) => {
    const [name, setName] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [textAreaValue, setTextAreaValue] = useState<string>();

    return (
        <div className={cn(className, styles.leadForm)}>
            <span className={styles.leadForm__title}>
                {title}
            </span>
            <span className={styles.leadForm__subtitle}>
                {subtitle}
            </span>
            <div className={styles.leadForm__controls}>
                <Input
                    value={name}
                    onChange={setName}
                    placeholder="Ваше имя"
                    theme={InputTheme.Light}
                />
                <Input
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    placeholder="Номер телефона"
                    theme={InputTheme.Light}
                />
                {hasTextArea && (
                    <TextArea
                        className={styles.leadForm__textArea}
                        value={textAreaValue}
                        onChange={setTextAreaValue}
                        placeholder="Что вы хотите узнать?"
                        theme={TextAreaTheme.Light}
                    />
                )}
                <Button
                    className={styles.leadForm__submitButton}
                    onClick={() => console.log('')}
                >
                    <span>Записаться</span>
                </Button>
                <div className={styles.leadForm__divider}>
                    <div />
                    <span>или</span>
                    <div />
                </div>
                <Link
                    className={styles.leadForm__pdfLink}
                    to={pdf?.url || ''}
                >
                    <span>Получить коммерческое предложение PDF</span>
                </Link>
            </div>
            {pdf?.url}
        </div>
    );
};
