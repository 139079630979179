import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCompanyInformationApiResponseSchema = z.object({
    data: z.object({
        name: z.string(),
        title: z.string(),
        description: z.any().optional().nullable(),
        phone: z.string(),
        address: z.string(),
        email: z.string(),
        logoImage: z.object({
            url: z.string(),
        }),
        mainImage: z.object({
            url: z.string(),
        }),
    }),
});

type GetCompanyInformationApiResponse
    = ApiResponse<z.infer<typeof GetCompanyInformationApiResponseSchema>>
    | BaseError;

type GetCompanyInformationActionRequest = undefined

export const getCompanyInformation = createAsyncThunk<
    GetCompanyInformationApiResponse,
    GetCompanyInformationActionRequest
>(
    'HomePageActionsGetCompanyInformation',
    async () => apiInstance.get(
        '/company-information',
        {
            'populate[logoImage][fields]': 'url',
            'populate[mainImage][fields]': 'url',
        },
        GetCompanyInformationApiResponseSchema,
    ),
);
