import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCateringEventCategoriesApiResponseSchema = z.object({
    data: z.array(
        z.object({
            documentId: z.string(),
            name: z.string(),
        }),
    ),
});

type GetCateringEventCategoriesApiResponse
    = ApiResponse<z.infer<typeof GetCateringEventCategoriesApiResponseSchema>>
    | BaseError;

type GetCateringEventCategoriesActionRequest = undefined

export const getCateringEventCategories = createAsyncThunk<
    GetCateringEventCategoriesApiResponse,
    GetCateringEventCategoriesActionRequest
>(
    'CateringPageActionsGetCateringEventCategories',
    async () => apiInstance.get(
        '/catering-event-categories',
        {},
        GetCateringEventCategoriesApiResponseSchema,
    ),
);
