import cn from 'classnames';
import React from 'react';
import styles from './DegustationBlock.module.scss';
import { LeadSection } from '../../../components';

export type DegustationBlockProps = {
    className?: string
}

export const DegustationBlock = ({
    className,
}: DegustationBlockProps) => (
    <div className={cn(className, styles.degustationBlock)}>
        <LeadSection
            className={styles.degustationBlock__leadSection}
            title="ПРИХОДИТЕ НА ДЕГУСТАЦИЮ"
            subtitle="Запишитесь прямо сейчас"
            sidebar={{
                title: 'ШОУ-РУМ',
                subtitle: 'Москва',
            }}
        />
    </div>
);
