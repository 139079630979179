import { z } from 'zod';

export const FindCateringFormatsResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
        description: z.any().optional(),
        images: z.array(z.object({
            url: z.string(),
        })).optional().nullable(),
    })),
    meta: z.any().optional(),
});

export type FindCateringFormatsResponse = z.infer<typeof FindCateringFormatsResponseSchema>;
