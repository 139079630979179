import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCulinaryStudioArticleCategoriesApiResponseSchema = z.object({
    data: z.array(
        z.object({
            documentId: z.string(),
            name: z.string(),
        }),
    ),
});

type GetCulinaryStudioArticleCategoriesActionResponse
    = ApiResponse<z.infer<typeof GetCulinaryStudioArticleCategoriesApiResponseSchema>>
    | BaseError;

type GetCulinaryStudioArticleCategoriesActionRequest = undefined;

export const getCulinaryStudioArticleCategories = createAsyncThunk<
    GetCulinaryStudioArticleCategoriesActionResponse,
    GetCulinaryStudioArticleCategoriesActionRequest
>(
    'CulinaryStudioPageActionsGetCulinaryStudioArticleCategories',
    async () => apiInstance.get(
        '/culinary-studio-article-categories',
        {},
        GetCulinaryStudioArticleCategoriesApiResponseSchema,
    ),
);
