import {
    Button,
    Grid,
    GridProps,
    Image,
    Text,
} from '@chakra-ui/react';
import { Icon, IconsNames } from 'components';
import React from 'react';
import { Link } from 'react-router-dom';

export type DeliveryArticleCardProps = {
    name: string
    weight?: number
    price?: number
    currencyAbbreviation?: string
    link: {
        to: string,
    },
    image?: {
        src: string
    }
} & GridProps

export const DeliveryArticleCard: React.FC<DeliveryArticleCardProps> = ({
    image,
    weight,
    price,
    currencyAbbreviation,
    name,
    link,
    ...props
}) => (
    <Grid
        gap="2rem"
        {...props}
    >
        <Image
            src={image?.src}
            aspectRatio="1 / 1"
            objectFit="cover"
        />
        <Grid>
            <Grid
                gap="2rem"
                templateColumns="1fr auto"
            >
                <Text
                    fontSize="1rem"
                    margin="auto 0"
                >
                    Вес:
                    {' '}
                    {weight}
                    г
                </Text>
                <Text
                    fontSize="2rem"
                >
                    {price}
                    {currencyAbbreviation}
                </Text>
            </Grid>
            <Link to={link.to}>
                <Text
                    fontSize="1.5rem"
                    fontWeight="bold"
                    textDecoration="underline"
                >
                    {name}
                </Text>
            </Link>
        </Grid>
        <Button
            gap="1rem"
        >
            <Icon
                iconName={IconsNames.Cart}
            />
            <Text
                fontSize="1rem"
            >
                Заказать
            </Text>
        </Button>
    </Grid>
);
