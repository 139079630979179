import { defineSlotRecipe } from '@chakra-ui/react';

export const tabsRecipe = defineSlotRecipe({
    base: {

    },
    slots: ['root', 'list', 'trigger'],
    variants: {
        size: {
            lg: {},
        },
        visual: {
            plain: {},
        },
    },
    compoundVariants: [
        {
            size: 'lg',
            visual: 'plain',
            css: {
                trigger: {
                    fontWeight: 900,
                    _selected: {
                        border: '3px solid #E74C3C',
                    },
                    border: '3px solid transparent',
                    borderRadius: 0,
                },
                list: {
                    gap: '1rem',
                },
            },
        },
    ],
});
