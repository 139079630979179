import { z } from 'zod';

export const BestImagesGroupModelSchema = z.object({
    images: z.array(
        z.object({
            url: z.string(),
        }),
    ).optional().nullable(),
});

export type BestImagesGroupModel
= z.infer<typeof BestImagesGroupModelSchema>;
