import { z } from 'zod';
import { ArticleCategoryModelSchema } from '../../article-category';
import { ArticleTagModelSchema } from '../../article-tag';

export const ArticleModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any().optional(),
    weight: z.number().optional().nullable(),
    price: z.number().optional().nullable(),
    images: z.array(z.object({
        url: z.string(),
    })).nullable().optional(),
    category: ArticleCategoryModelSchema,
    tags: z.array(ArticleTagModelSchema).nullable().optional(),
});

export type ArticleModel = z.infer<typeof ArticleModelSchema>;
