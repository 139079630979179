import cn from 'classnames';
import React from 'react';
import styles from './PageLayout.module.scss';

type PageLayoutProps = {
    className?: string,
    children: React.JSX.Element | React.JSX.Element[],
}

const PageLayout = ({
    className,
    children,
}: PageLayoutProps) => (
    <div className={cn(className, styles.pageLayout)}>
        {children}
    </div>
);

export default PageLayout;
