import {
    getLeadForm,
    GetLeadFormApiResponseSchema,
} from './get-lead-form';

export {
    GetLeadFormApiResponseSchema,
};

export const LeadSectionActions = {
    getLeadForm,
};
