import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCateringEventByUidApiResponseSchema = z.object({
    data: z.object({
        documentId: z.string(),
        name: z.string(),
        description: z.any(),
        images: z.array(
            z.object({
                url: z.string(),
            }),
        ).optional().nullable(),
        format: z.object({
            name: z.string(),
        }).optional().nullable(),
    }),
});

type GetCateringEventByUidActionResponse
    = ApiResponse<z.infer<typeof GetCateringEventByUidApiResponseSchema>>
    | BaseError;

type GetCateringEventByUidActionRequest = {
    uid: string,
}

export const getCateringEventByUid = createAsyncThunk<
    GetCateringEventByUidActionResponse,
    GetCateringEventByUidActionRequest
>(
    'CateringEventPageActionsGetCateringEventByUid',
    async ({
        uid,
    }) => apiInstance.get(
        `/catering-events/${uid}`,
        {
            'populate[images][fields]': 'url',
            'populate[format][fields]': '*',
        },
        GetCateringEventByUidApiResponseSchema,
    ),
);
