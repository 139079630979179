import { z } from 'zod';

export const DeliveryArticleCategoryPopularCardModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    shortDescription: z.string().optional().nullable(),
    image: z.object({
        url: z.string(),
    }).optional(),
});

export type DeliveryArticleCategoryPopularCardModel
= z.infer<typeof DeliveryArticleCategoryPopularCardModelSchema>;
