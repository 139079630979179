import React from 'react';
import cn from 'classnames';
import styles from './LeadSection.module.scss';
import { Image } from '../image/Image';
import { LeadForm } from '../lead-form/LeadForm';

export type LeadSectionProps = {
    className?: string;
    sidebar: {
        title: string
        subtitle?: string
    }
    image?: {
        url: string
    }
    title: string
    subtitle: string
    pdf?: {
        url: string
    }
}

export const LeadSection = ({
    className,
    sidebar,
    image,
    title,
    subtitle,
    pdf,
}: LeadSectionProps) => (
    <div className={cn(className, styles.leadSection)}>
        <div className={styles.leadSection__sidebar}>
            <span className={styles.leadSection__sidebarSubtitle}>
                {sidebar.subtitle}
            </span>
            <div className={styles.leadSection__divider} />
            <span className={styles.leadSection__sidebarTitle}>
                {sidebar.title}
            </span>
        </div>
        <div className={styles.leadSection__imageWrapper}>
            <Image
                className={styles.leadSection__image}
                url={image?.url || ''}
            />
        </div>
        <LeadForm
            className={styles.leadSection__leadForm}
            title={title}
            subtitle={subtitle}
            pdf={pdf}
        />
    </div>
);
