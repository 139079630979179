import cn from 'classnames';
import React from 'react';
import styles from './Footer.module.scss';

export type FooterProps = {
    className?: string
}

export const Footer = ({
    className,
}: FooterProps) => (
    <div className={cn(className, styles.footer)}>
        <span>
            Кейтеринг CORSO ©2022
        </span>
    </div>
);
