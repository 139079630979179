import { z } from 'zod';

export const FindArticlesResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
        description: z.any().optional(),
        weight: z.number().optional().nullable(),
        price: z.number().optional().nullable(),
        images: z.array(z.object({
            url: z.string(),
        })).nullable().optional(),
        category: z.object({
            documentId: z.string(),
            name: z.string(),
        }).nullable().optional(),
        tags: z.array(z.object({
            documentId: z.string(),
            name: z.string(),
        })).nullable().optional(),
    })),
    meta: z.any().optional(),
});

export type FindArticlesResponse = z.infer<typeof FindArticlesResponseSchema>;
