import {
    getDeliveryArticleByUid,
    GetDeliveryArticleByUidApiResponseSchema,
} from './get-delivery-article-by-uid';

export {
    GetDeliveryArticleByUidApiResponseSchema,
};

export const DeliveryArticlePageActions = {
    getDeliveryArticleByUid,
};
