import React, { memo } from 'react';
import {
    AwardsBlock,
    Footer,
    Header,
    CrewMembersBlock,
    PortfolioBlock,
    FAQsBlock,
    DegustationBlock,
    ImagesBlock,
    ContactsBlock,
    NavigationBlock,
    TopHeader,
} from 'widgets';
import { LeadSection, PageLayout } from 'components';
import { CulinaryStudioPageMain } from './components';

const MemoTopHeader = memo(TopHeader);
const MemoHeader = memo(Header);
const MemoFooter = memo(Footer);
const MemoAwardsBlock = memo(AwardsBlock);
const MemoCrewMembersBlock = memo(CrewMembersBlock);
const MemoPortfolioBlock = memo(PortfolioBlock);
const MemoFAQsBlock = memo(FAQsBlock);
const MemoDegustationBlock = memo(DegustationBlock);
const MemoImagesBlock = memo(ImagesBlock);
const MemoContactsBlock = memo(ContactsBlock);
const MemoNavigationBlock = memo(NavigationBlock);
const MemoLeadSection = memo(LeadSection);
const MemoMain = memo(CulinaryStudioPageMain);

export const CulinaryStudioPage = () => (
    <PageLayout>
        <MemoTopHeader />
        <MemoHeader />
        <MemoLeadSection
            title="ЕСТЬ ВОПРОСЫ ПО ДОСТАВКЕ?"
            subtitle="Наши менеджеры Вам помогут"
            sidebar={{
                title: 'СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ',
            }}
        />
        <MemoMain />
        <MemoAwardsBlock />
        <MemoImagesBlock />
        <MemoDegustationBlock />
        <MemoCrewMembersBlock />
        <MemoPortfolioBlock />
        <MemoContactsBlock />
        <MemoFAQsBlock />
        <MemoNavigationBlock />
        <MemoFooter />
    </PageLayout>
);
