import { z } from 'zod';

export const PortfolioEventModelSchema = z.object({
    uid: z.string(),
    description: z.any().optional(),
    name: z.string(),
    images: z.array(z.object({
        url: z.string(),
    })).optional(),
});

export type PortfolioEventModel = z.infer<typeof PortfolioEventModelSchema>;
