import { createSlice } from '@reduxjs/toolkit';
import { SweetShopBlockState } from './sweet-shop-block-state';
import { SweetShopBlockActions } from './sweet-shop-block-actions';
import BaseError from '../../../utils/base-error';
import { parseSweetShopArticleCategoryDtoToDomain } from '../../../entities';

const initialState: SweetShopBlockState = {
    loaded: false,
    sweetShopArticleCategories: [],
};

export const sweetShopBlockSlice = createSlice({
    name: 'sweetShopBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /**
         * Обработчик получения списка типов баров (коллекция OutdoorBarType)
         */
        builder.addCase(
            SweetShopBlockActions.getSweetShopArticleCategories.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log(response);
                        const result = response.data.map(
                            (dto) => parseSweetShopArticleCategoryDtoToDomain(dto),
                        );

                        console.log(result);

                        state.sweetShopArticleCategories = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
