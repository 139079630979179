import { defineRecipe } from '@chakra-ui/react';

export const headingRecipe = defineRecipe({
    base: {
        fontWeight: '900',
        letterSpacing: '0',
        textDecoration: 'None',
        textTransform: 'None',
        padding: 0,
        margin: 0,
    },
    variants: {
        size: {
            lg: {
                lineHeight: '1',
                fontSize: '3.25rem',
            },
            md: {
                lineHeight: '1',
                fontSize: '2.25rem',
            },
            sm: {
                lineHeight: '1',
                fontSize: '1.75rem',
            },
            xs: {
                lineHeight: '1',
                fontSize: '1.5rem',
            },
        },
        colorScheme: {
            primary: {
                color: 'black',

                _hover: {

                },
            },
            variant: {
                color: 'white',

                _hover: {

                },
            },
        },
    },
    defaultVariants: {
        size: 'lg',
    },
});
