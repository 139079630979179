// eslint-disable-next-line no-restricted-imports
import { BaseEntityApi } from 'shared/api';

export type CulinaryStudioArticleCategoryDto = {
    documentId?: string,
    name?: string,
}

export class CulinaryStudioArticleCategoryEntityApi
    extends BaseEntityApi<CulinaryStudioArticleCategoryDto> {
    constructor() {
        super('culinary-studio-article-categories');
    }
}

export const culinaryStudioArticleCategoryEntityApiInstance = new
CulinaryStudioArticleCategoryEntityApi();
