import { z } from 'zod';
import { CateringFormatModelSchema } from 'entities/catering-format';
import { GetCateringFormatByUidApiResponseSchema } from '../store';

export const createCateringFormatFromGetCateringFormatByUidApiResponse = (
    (response: z.infer< typeof GetCateringFormatByUidApiResponseSchema>) => (
        CateringFormatModelSchema.parse({
            uid: response.data.documentId,
            name: response.data.name,
            description: response.data.description,
            images: response.data.images?.map(({ url }) => ({
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + url,
            })),
            menus: response.data.menus?.map(({
                name,
                description,
                warning,
                image,
                estimateFile,
            }) => ({
                name,
                description,
                warning,
                image: image && {
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + image.url,
                },
                estimateFile: estimateFile && {
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + estimateFile.url,
                },
            })),
        })
    )
);
