import { z } from 'zod';

export const CateringFormatApiFindOneResponseSchema = z.object({
    data: z.object({
        documentId: z.string(),
        name: z.string(),
        description: z.any().optional(),
        images: z.array(z.object({
            url: z.string(),
        })).optional().nullable(),
    }),
    meta: z.any().optional(),
});

export type CateringFormatApiFindOneResponse
    = z.infer<typeof CateringFormatApiFindOneResponseSchema>;
