import {
    Button,
    Flex,
    Grid,
    GridProps,
    Heading,
    Image,
    Spacer,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from './RichText';

/**
 * Пропсы для компонента карточки позиции магазина
 */
export type ArticleCardProps = {
    uid: string,
    image?: {
        url: string
    },
    name: string,
    link?: {
        url: string
    },
    description?: {
        content: any
    }
} & GridProps & React.RefAttributes<HTMLDivElement>

/**
 * Компонент карточки для позиции магазина
 *
 * @param {ArticleCardProps} props Пропсы
 * @returns Компонент
 */
export const ArticleCard: React.FC<ArticleCardProps> = ({
    uid,
    image,
    name,
    link,
    description,
    ...props
}) => (
    <Grid
        templateRows="auto auto auto 1fr auto"
        key={uid}
        gap="1.25rem"
        {...props}
    >
        <Image
            src={image?.url}
            aspectRatio="3 / 2"
            objectFit="cover"
            widows="100%"
        />
        {link ? (
            <Link to={link.url}>
                <Heading
                    size="md"
                    textDecoration="underline"
                    colorScheme="primary"
                >
                    {name}
                </Heading>
            </Link>
        ) : (
            <Heading
                size="md"
                textDecoration="underline"
                colorScheme="primary"
            >
                {name}
            </Heading>
        )}
        <Flex
            flexDirection="row"
            gap="1rem"
            width="100%"
        >
            <Flex width="3px" background="black" height="100%" />
            <RichText
                fontSize="1.5rem"
                content={description?.content}
            />
        </Flex>
        <Spacer />
        <Button>
            Заказать
        </Button>
    </Grid>
);
