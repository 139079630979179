import {
    Button,
    Flex,
    FlexProps,
    Heading,
} from '@chakra-ui/react';
import React from 'react';

export type OutdoorBarDetailBlockHeaderProps = {
    title: string
} & FlexProps & React.RefAttributes<HTMLDivElement>

export const OutdoorBarDetailBlockHeader: React.FC<OutdoorBarDetailBlockHeaderProps> = ({
    title,
    ...props
}) => (
    <Flex
        flexDirection="row"
        gap="2rem"
        {...props}
    >
        <Heading
            flex={1}
            size="md"
        >
            {title}
        </Heading>
        <Button
            size="md"
            variant="solid"
            colorScheme="primary"
        >
            Доставка и оплата
        </Button>
    </Flex>
);
