import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCulinaryStudioArticleByUidApiResponseSchema = z.object({
    data: z.object({
        documentId: z.string(),
        name: z.string(),
        description: z.any().optional().nullable(),
        category: z.object({
            documentId: z.string(),
            name: z.string(),
        }).optional().nullable(),
        images: z.array(
            z.object({
                url: z.string(),
            }),
        ).optional().nullable(),
        portions: z.array(
            z.object({
                value: z.number(),
                meassure: z.string(),
                description: z.any().optional().nullable(),
                weight: z.number(),
                price: z.number(),
                currency: z.object({
                    documentId: z.string(),
                    name: z.string(),
                    abbreviation: z.string(),
                }).optional().nullable(),
            }),
        ).optional().nullable(),
        sets: z.array(
            z.object({
                name: z.string(),
                elements: z.array(
                    z.object({
                        name: z.string(),
                        meassure: z.string(),
                        value: z.number(),
                    }),
                ).optional().nullable(),
            }),
        ).optional().nullable(),
        includedServices: z.array(
            z.object({
                name: z.string(),
                description: z.any().optional().nullable(),
            }),
        ).optional().nullable(),
        nonIncludedServices: z.array(
            z.object({
                name: z.string(),
            }),
        ).optional().nullable(),
        recommendations: z.array(
            z.object({
                documentId: z.string(),
                name: z.string(),
                weight: z.number(),
                price: z.number(),
                currency: z.object({
                    documentId: z.string(),
                    name: z.string(),
                    abbreviation: z.string(),
                }).optional().nullable(),
                images: z.array(
                    z.object({
                        url: z.string(),
                    }),
                ).optional().nullable(),
            }),
        ).optional().nullable(),
    }),
});

type GetCulinaryStudioArticleByUidActionResponse
    = ApiResponse<z.infer<typeof GetCulinaryStudioArticleByUidApiResponseSchema>>
    | BaseError;

type GetCulinaryStudioArticleByUidActionRequest = {
    uid: string
};

export const getCulinaryStudioArticleByUid = createAsyncThunk<
    GetCulinaryStudioArticleByUidActionResponse,
    GetCulinaryStudioArticleByUidActionRequest
>(
    'CulinaryStudioArticlePageActionsGetCulinaryStudioArticleByUid',
    async ({
        uid,
    }) => apiInstance.get(
        `/culinary-studio-articles/${uid}`,
        {
            'populate[category][fields]': '*',
            'populate[images][fields]': 'url',
            'populate[portions][fields]': '*',
            'populate[portions][populate]': 'currency',
            'populate[sets][fields]': '*',
            'populate[sets][populate]': 'elements',
            'populate[includedServices][fields]': '*',
            'populate[nonIncludedServices][fields]': '*',
            'populate[recommendations][fields]': '*',
            'populate[recommendations][populate][0]': 'images',
            'populate[recommendations][populate][1]': 'currency',
            'populate[recommendations][populate][images][fields]': 'url',
            'populate[recommendations][populate][currency][fields]': '*',
        },
        GetCulinaryStudioArticleByUidApiResponseSchema,
    ),
);
