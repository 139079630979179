import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiInstance } from '../../api/api';
import { PageId } from '../../shared/types/page-info';

type SetPageInfoRequest = {
    id: PageId
}

const getPageInfo = createAsyncThunk(
    'setPageInfo',
    async ({ id }: SetPageInfoRequest) => {
        const result = await ApiInstance.getPageInfoById(id);
        return result;
    },
);

type GetWidgetMarkupByNameRequest = {
    widgetName: string;
}

const getWidgetInfo = createAsyncThunk(
    'getWidgetInfo',
    async ({ widgetName }: GetWidgetMarkupByNameRequest) => {
        const widgetInfo = await ApiInstance.getWidgetInfoByName(widgetName);
        return widgetInfo;
    },
);

type GetDatasourceRequest = {
    datasourceName: string,
    values?: Record<string, string | number | null | boolean>
}

const getDatasource = createAsyncThunk(
    'getDatasource',
    async (request : GetDatasourceRequest) => {
        const { datasourceName, values } = request;
        const response = await ApiInstance.executeDatasource(datasourceName, values);
        return {
            request,
            response,
        };
    },
);

const PAGE_ACTIONS = {
    GET_PAGE_INFO: getPageInfo,
    GET_WIDGET_INFO: getWidgetInfo,
    GET_DATASOURCE: getDatasource,
};

export default PAGE_ACTIONS;
