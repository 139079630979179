import { createSlice } from '@reduxjs/toolkit';
import { AwardsBlockState } from './awards-block-state';
import AwardsBlockActions from './awards-block-actions';
import BaseError from '../../../utils/base-error';
import { AwardModelSchema } from '../../../models/award';

const initialState: AwardsBlockState = {
    loaded: false,
    awards: [],
};

// eslint-disable-next-line import/prefer-default-export
export const awardsBlockSlice = createSlice({
    name: 'awardsBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(AwardsBlockActions.getAwards.fulfilled, (state, action) => {
            const response = action.payload;

            if (response instanceof BaseError) {
                // TODO
            } else {
                // Валидация
                try {
                    const result = response.data.map(({
                        documentId,
                        description,
                        image,
                        years,
                    }) => AwardModelSchema.parse({
                        uid: documentId,
                        description,
                        image: image && {
                            url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + image.url,
                        },
                        years: years.map(({ year }) => +year),
                    }));

                    state.awards = result;
                    state.loaded = true;
                } catch (e) {
                    // return new BaseError(400, String(e));
                    // TODO
                }
            }
        });
    },
});
