import React from 'react';
import {
    createSystem,
    defaultConfig,
    defineConfig,
    ChakraProvider as Provider,
} from '@chakra-ui/react';
import {
    buttonRecipe,
    containerRecipe,
    flexRecipe,
    headingRecipe,
    inputRecipe,
    tabsRecipe,
    textRecipe,
} from 'shared';

const config = defineConfig({
    theme: {
        recipes: {
            button: buttonRecipe,
            heading: headingRecipe,
            text: textRecipe,
            container: containerRecipe,
            input: inputRecipe,
            flex: flexRecipe,
        },
        slotRecipes: {
            tabs: tabsRecipe,
        },
        tokens: {
            colors: {},
        },
    },
});

const system = createSystem(defaultConfig, config);

type ChakraProviderProps = {
    children: React.ReactNode
}

export const ChakraProvider = ({
    children,
}: ChakraProviderProps) => (
    <Provider value={system}>
        {children}
    </Provider>
);
