import { createAsyncThunk } from '@reduxjs/toolkit';
import { fAQsApiInstance } from '../../../api';

const getFAQs = createAsyncThunk(
    'getFAQs',
    async () => {
        const response = await fAQsApiInstance.findFAQs();
        return response;
    },
);

const FAQsBlockActions = {
    getFAQs,
};

export default FAQsBlockActions;
