import React from 'react';
import { RouteObject } from 'react-router-dom';
import {
    DeliveryPage,
    CateringFormatPage,
    CateringEventFormatPage,
    CateringEventPage,
    CulinaryStudioPage,
    PortfolioEventsPage,
    PortfolioEventDetailPage,
    OutdoorBarsPage,
    OutdoorBarDetailPage,
    SweetShopPage,
    SweetShopArticlesPage,
    CulinaryStudioArticlePage,
    DeliveryArticlePage,
    HomePage,
    CateringPage,
} from 'pages';

const routes: RouteObject[] = [
    {
        path: '/delivery',
        element: <DeliveryPage />,
    },
    {
        path: '/catering',
        element: <CateringPage />,
    },
    {
        path: '/catering/formats/:id',
        element: <CateringFormatPage />,
    },
    {
        path: '/catering/event-format/:id',
        element: <CateringEventFormatPage />,
    },
    {
        path: '/catering/events/:id',
        element: <CateringEventPage />,
    },
    {
        path: '/culinary-studio',
        element: <CulinaryStudioPage />,
    },
    {
        path: '/portfolio-events',
        element: <PortfolioEventsPage />,
    },
    {
        path: '/portfolio-events/:id',
        element: <PortfolioEventDetailPage />,
    },
    {
        path: '/outdoor-bars',
        element: <OutdoorBarsPage />,
    },
    {
        path: '/outdoor-bars/:id',
        element: <OutdoorBarDetailPage />,
    },
    {
        path: '/sweet-shop',
        element: <SweetShopPage />,
    },
    {
        path: '/sweet-shop/:id',
        element: <SweetShopArticlesPage />,
    },
    {
        path: '/culinary-studio/:id',
        element: <CulinaryStudioArticlePage />,
    },
    {
        path: '/delivery/:id',
        element: <DeliveryArticlePage />,
    },
    {
        path: '/home',
        element: <HomePage />,
    },
];

export default routes;
