import { Flex, Tabs, Text } from '@chakra-ui/react';
import React, { memo, useLayoutEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispath } from 'shared';
import { PortfolioEventsBlockActions, usePortfolioEventBlockState } from '../slice';
import { PortfolioEventsBlockEventsList, PortfolioEventsBlockHeader } from './components';

const MemoHeader = memo(PortfolioEventsBlockHeader);

export const PortfolioEventsBlock = () => {
    const dispatch = useAppDispath();
    const { portfolioEventsCards, eventCategories } = usePortfolioEventBlockState();

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTab, setSelectedTab] = useState(searchParams.get('tab'));

    const handleTabChange = (key: string) => {
        setSearchParams({
            tab: key,
        });
        setSelectedTab(key);
        dispatch(
            PortfolioEventsBlockActions.getPortfolioEventsCardsByCategoryUid({
                uid: key,
            }),
        );
    };

    // useEffect(() => {
    //     if (searchParams.get('tab') !== selectedTab) {
    //         setSelectedTab(searchParams.get('tab'));
    //         if (selectedTab) {
    //             dispatch(
    //                 PortfolioEventsBlockActions.getPortfolioEventsCardsByCategoryUid({
    //                     uid: selectedTab,
    //                 }),
    //             );
    //         }
    //     }
    // }, [searchParams.get('tab')]);

    useLayoutEffect(() => {
        if (selectedTab) dispatch(PortfolioEventsBlockActions.getEventCategories());
        if (selectedTab) {
            dispatch(
                PortfolioEventsBlockActions.getPortfolioEventsCardsByCategoryUid({
                    uid: selectedTab,
                }),
            );
        }
    }, []);

    console.log('EventTypeBlock', selectedTab, portfolioEventsCards);

    return (
        <Flex
            flexDirection="column"
            gap="2rem"
            padding="2rem"
        >
            <MemoHeader />
            <Tabs.Root
                visual="plain"
                size="lg"
                variant="plain"
                value={selectedTab}
                onValueChange={(e: any) => handleTabChange(e.value)}
            >
                <Flex overflow="scroll" scrollbar="hidden">
                    <Tabs.List>
                        {eventCategories.map(({ uid, name }) => (
                            <Tabs.Trigger key={uid} value={uid}>
                                <Text
                                    fontSize="1.5rem"
                                >
                                    {name.toLocaleUpperCase()}
                                </Text>
                            </Tabs.Trigger>
                        ))}
                    </Tabs.List>
                </Flex>
                {eventCategories.map(({ uid }) => (
                    <Tabs.Content value={uid} key={uid}>
                        <PortfolioEventsBlockEventsList
                            padding="2rem"
                            events={portfolioEventsCards || []}
                        />
                    </Tabs.Content>
                ))}
            </Tabs.Root>
        </Flex>
    );
};
