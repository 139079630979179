import {
    GridProps,
} from '@chakra-ui/react';
import { CulinaryStudioArticlePortionModel } from 'entities';
import React, { useState } from 'react';
import { Carousel } from 'shared';
import { CulinaryStudioArticlePageMainPortionCard } from './CulinaryStudioArticlePageMainPortionCard';

export type CulinaryStudioArticlePageMainPortionPickerProps = {
    culinaryStudioArticlePortions: CulinaryStudioArticlePortionModel[]
} & GridProps & React.RefAttributes<HTMLDivElement>;

export const CulinaryStudioArticlePageMainPortionPicker
: React.FC<CulinaryStudioArticlePageMainPortionPickerProps> = ({
    culinaryStudioArticlePortions,
}) => {
    const [currentPortionUid, setCurrentPortionUid] = useState(0);

    return (
        <Carousel
            items={culinaryStudioArticlePortions?.map(({
                value,
                meassure,
                description,
                weight,
                price,
                currency,
            }, i) => ({
                key: i,
                element: (
                    <CulinaryStudioArticlePageMainPortionCard
                        value={value}
                        measure={meassure.toLocaleUpperCase()}
                        description={description}
                        weight={weight}
                        price={price}
                        currency={currency?.abbreviation}
                        isActive={i === currentPortionUid}
                    />
                ),
            })) || []}
            value={currentPortionUid}
            onValueChange={(uid) => setCurrentPortionUid(uid)}
        />
    );
};
