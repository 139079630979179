import { createAsyncThunk } from '@reduxjs/toolkit';
import { crewMembersApiInstance } from '../../../api';

const getCrewMembers = createAsyncThunk(
    'getAwards',
    async () => {
        const response = await crewMembersApiInstance.findCrewMembers();
        return response;
    },
);

const CrewMembersBlockActions = {
    getCrewMembers,
};

export default CrewMembersBlockActions;
