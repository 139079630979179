import { AxiosRequestConfig } from 'axios';
import { FindArticleCategoriesResponse, FindArticleCategoriesResponseSchema } from './responses/find-article-categories-response';
import { Api } from '../../../api/api';
import BaseError from '../../../utils/base-error';
import { ErrorResponseSchema } from '../../../api/base/responses/error-response';

export class ArticleCategoryApi extends Api {
    findArticleCategories = async (): Promise<BaseError | FindArticleCategoriesResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/article-categories`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<FindArticleCategoriesResponse>(
            config,
            FindArticleCategoriesResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };
}

export const articleCategoryApiInstance = new ArticleCategoryApi();
