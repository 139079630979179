// eslint-disable-next-line no-restricted-imports
import { BaseEntityApi } from 'shared/api';

export type SweetShopArticleCategoryDto = {
    documentId: string,
    name: string,
    description?: any,
    addictionalDescription?: any,
    image?: {
        url: string,
    },
}

export class SweetShopArticleCategoryApi extends BaseEntityApi<SweetShopArticleCategoryDto> {
    constructor() {
        super('sweet-shop-article-categories');
    }
}

export const sweetShopArticleCategoryApiInstance = new SweetShopArticleCategoryApi();
