import { z } from 'zod';

export const FindEventCategoriesResponseSchema = z.object({
    data: z.array(z.object({
        documentId: z.string(),
        name: z.string(),
    })),
    meta: z.any().optional(),
});

export type FindEventCategoriesResponse = z.infer<typeof FindEventCategoriesResponseSchema>;
