import {
    getCateringFormats,
    GetCateringFormatsApiResponseSchema,
} from './get-catering-formats';
import {
    getCateringEventFormatsByCategoryUid,
    GetCateringEventFormatsByCategoryUidApiResponseSchema,
} from './get-catering-event-formats-by-category-uid';
import {
    getCateringEventCategories,
    GetCateringEventCategoriesApiResponseSchema,
} from './get-catering-event-categories';

export {
    GetCateringFormatsApiResponseSchema,
    GetCateringEventFormatsByCategoryUidApiResponseSchema,
    GetCateringEventCategoriesApiResponseSchema,
};

export const CateringPageActions = {
    getCateringFormats,
    getCateringEventFormatsByCategoryUid,
    getCateringEventCategories,
};
