import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';

export const SweetShopBlockHeader = () => (
    <Flex
        flexDirection="row"
    >
        <Heading
            flex={1}
            textDecoration="underline"
        >
            КОНДИТЕРСКАЯ
        </Heading>
    </Flex>
);
