import {
    Button,
    Flex,
    FlexProps,
    Heading,
} from '@chakra-ui/react';
import React from 'react';

type CateringEventPageMainHeaderProps = {
    name: string;
} & FlexProps & React.RefAttributes<HTMLDivElement>

export const CateringEventPageMainHeader: React.FC<CateringEventPageMainHeaderProps> = ({
    name,
    ...props
}) => {
    console.log('');
    return (
        <Flex
            flexDirection="row"
            gap="2rem"
            {...props}
        >
            <Heading
                flex={1}
                textDecoration="underline"
            >
                {name}
            </Heading>
            <Button
                size="md"
                variant="solid"
                colorScheme="primary"
            >
                УНИВЕРСАЛЬНЫЕ ПРЕДЛОЖЕНИЯ
            </Button>
        </Flex>
    );
};
