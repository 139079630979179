import React, {
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import {
    Grid,
    GridProps,
    Text,
} from '@chakra-ui/react';
import { useAppDispath } from 'shared';
// eslint-disable-next-line no-restricted-imports
import { Tabs, TabsItemProps } from 'shared/ui/Tabs';
import {
    DeliveryArticleCardModel,
    DeliveryArticleCategoryModel,
    DeliveryArticleTagModel,
} from 'entities';
import { useSearchParams } from 'react-router-dom';
import { DeliveryPageMainHeader } from './DeliveryPageMainHeader';
import { DeliveryPageMainTabsContent } from './DeliveryPageMainTabsContent';
import { DeliveryPageActions, useDeliveryPageState } from '../../model';

const createDeliveryPageMainTabsItemLabel = (
    label: string,
) => (
    <Text fontSize="1.5rem" transition="0.5s">{label.toLocaleUpperCase()}</Text>
);

const createDeliveryPageMainTabsContentItem = (
    key: string,
    deliveryArticleTags: DeliveryArticleTagModel[],
    deliveryArticlesCards: DeliveryArticleCardModel[],
    selectedTagUid: string,
    onChangeSelectedTag: (v: string) => void,
) => (
    <DeliveryPageMainTabsContent
        selectedTagUid={selectedTagUid}
        tags={deliveryArticleTags}
        cards={deliveryArticlesCards}
        onChangeTag={(v) => onChangeSelectedTag(v)}
        transition="0.5s"
    />
);

const createDeliveryPageMainTabsItems = (
    deliveryArticleCategories: DeliveryArticleCategoryModel[],
    deliveryArticleTags: DeliveryArticleTagModel[],
    deliveryArticlesCards: DeliveryArticleCardModel[],
    selectedTabUid: string,
    selectedTagUid: string,
    onChangeSelectedTag: (v: string) => void,
): TabsItemProps[] => deliveryArticleCategories.map(({
    uid,
    name,
}) => ({
    key: uid,
    labelComponent: createDeliveryPageMainTabsItemLabel(name),
    contentComponent: createDeliveryPageMainTabsContentItem(
        uid,
        deliveryArticleTags,
        deliveryArticlesCards,
        selectedTagUid,
        onChangeSelectedTag,
    ),
}));

export type DeliveryPageMainProps = GridProps & React.RefAttributes<HTMLDivElement>

export const DeliveryPageMain: React.FC<DeliveryPageMainProps> = ({
    ...props
}) => {
    const {
        deliveryArticleCategories,
        deliveryArticleTags,
        deliveryArticlesCards,
    } = useDeliveryPageState();
    const dispatch = useAppDispath();

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedCategoryUid, setSelectedCategoryUid] = useState<string>();
    const [selectedTagUid, setSelectedTagUid] = useState('all');

    useLayoutEffect(() => {
        dispatch(DeliveryPageActions.getDeliveryArticleTags());
        dispatch(DeliveryPageActions.getDeliveryArticleCategories());
    }, []);

    useEffect(() => {
        if (!selectedCategoryUid && deliveryArticleCategories.length > 0) {
            setSelectedCategoryUid(deliveryArticleCategories[0].uid);
        }
    }, [deliveryArticleCategories]);

    const memoTabsItems = useMemo(() => (
        createDeliveryPageMainTabsItems(
            deliveryArticleCategories,
            deliveryArticleTags,
            deliveryArticlesCards,
            selectedCategoryUid || '',
            selectedTagUid,
            setSelectedTagUid,
        )
    ), [
        deliveryArticleCategories,
        deliveryArticleTags,
        deliveryArticlesCards,
        selectedTagUid,
    ]);

    useEffect(() => {
        const urlTab = searchParams.get('tab');
        if (urlTab && urlTab !== selectedCategoryUid) {
            setSelectedCategoryUid(urlTab);
        }
    }, [searchParams.get('tab')]);

    useEffect(() => {
        const urlTag = searchParams.get('tag');
        if (urlTag && urlTag !== selectedTagUid) {
            setSelectedTagUid(urlTag);
        }
    }, [searchParams.get('tag')]);

    useEffect(() => {
        if (selectedCategoryUid && selectedTagUid) {
            const isTabChanged = selectedCategoryUid !== searchParams.get('tab');
            const isTagChanged = selectedTagUid !== searchParams.get('tag');
            if (isTabChanged && isTagChanged) {
                setSearchParams({
                    tab: selectedCategoryUid,
                    tag: selectedTagUid,
                });
            } else if (isTabChanged) {
                setSearchParams({
                    tab: selectedCategoryUid,
                    tag: searchParams.get('tag') || '',
                });
            } else {
                setSearchParams({
                    tab: searchParams.get('tab') || '',
                    tag: selectedTagUid,
                });
            }
            dispatch(DeliveryPageActions.getDeliveryArticlesByCategoryAndTag({
                categoryUid: selectedCategoryUid,
                tagUid: selectedTagUid !== 'all' ? selectedTagUid : undefined,
            }));
        }
    }, [selectedCategoryUid, selectedTagUid]);

    console.log(deliveryArticlesCards);

    return (
        <Grid
            gap="2rem"
            {...props}
        >
            <DeliveryPageMainHeader padding="2rem 2rem 0 2rem" />
            <Tabs
                padding="0 2rem 2rem 2rem"
                value={selectedCategoryUid}
                onValueChange={(v) => setSelectedCategoryUid(v)}
                items={memoTabsItems}
            />
        </Grid>
    );
};
