import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetCulinaryStudioArticleCardsByCategoryUidApiResponseSchema = z.object({
    data: z.array(
        z.object({
            documentId: z.string(),
            name: z.string(),
            category: z.object({
                documentId: z.string(),
            }).optional().nullable(),
            images: z.array(
                z.object({
                    url: z.string(),
                }),
            ).optional().nullable(),
        }),
    ),
});

type GetCulinaryStudioArticleCardsByCategoryUidActionResponse
    = ApiResponse<z.infer<typeof GetCulinaryStudioArticleCardsByCategoryUidApiResponseSchema>>
    | BaseError;

type GetCulinaryStudioArticleCardsByCategoryUidActionRequest = {
    categoryUid: string
};

export const getCulinaryStudioArticleCardsByCategoryUid = createAsyncThunk<
    GetCulinaryStudioArticleCardsByCategoryUidActionResponse,
    GetCulinaryStudioArticleCardsByCategoryUidActionRequest
>(
    'CulinaryStudioPageActionsGetCulinaryStudioArticleCardsByCategoryUid',
    async ({
        categoryUid,
    }) => apiInstance.get(
        '/culinary-studio-articles',
        {
            'filters[category][documentId][$eq]': categoryUid,
            'fields[0]': 'name',
            'populate[images][fields][0]': 'url',
            'populate[category][fields][0]': 'documentId',
        },
        GetCulinaryStudioArticleCardsByCategoryUidApiResponseSchema,
    ),
);
