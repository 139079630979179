import { createAsyncThunk } from '@reduxjs/toolkit';
import { eventCategoryApiInstance, portfolioEventApiInstance } from '../../../entities';

const getEventCategories = createAsyncThunk(
    'getEventCategories',
    async () => {
        const response = await eventCategoryApiInstance.findEventCategories();
        return response;
    },
);

type GetPortfolioEventsByCategoryUidRequest = {
    uid: string
}

const getPortfolioEventsCardsByCategoryUid = createAsyncThunk(
    'getPortfolioEventsCardsByCategoryUid',
    async ({
        uid,
    }: GetPortfolioEventsByCategoryUidRequest) => {
        console.log('ВЫЗОВ');
        const response = await portfolioEventApiInstance.findCardsByCategoryUid(uid);
        console.log('123', response);
        return response;
    },
);

export const PortfolioEventsBlockActions = {
    getEventCategories,
    getPortfolioEventsCardsByCategoryUid,
};
