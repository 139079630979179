import { createSlice } from '@reduxjs/toolkit';
import { PageState } from './page-state';
import PAGE_ACTIONS from './page-actions';
import BaseError from '../../utils/base-error';
import { MarkupSchema } from '../../shared/types/markup';

const initialState: PageState = {
    datasources: [],
    widgets: [],
};

// eslint-disable-next-line import/prefer-default-export
export const pageSlice = createSlice({
    name: 'pageSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(PAGE_ACTIONS.GET_PAGE_INFO.fulfilled, (state, action) => {
            const response = action.payload;
            if (response instanceof BaseError) {
                console.log(response);
            } else {
                const { data } = response;

                try {
                    const markup = MarkupSchema.parse(data.markup);

                    state.pageInfo = {
                        id: data.documentId,
                        path: data.url,
                        markup,
                    };
                } catch (e) {
                    console.log(String(e));
                }
            }
        });
        builder.addCase(PAGE_ACTIONS.GET_WIDGET_INFO.fulfilled, (state, action) => {
            const response = action.payload;
            if (response instanceof BaseError) {
                // TODO
            } else {
                const data = response.data.at(0);

                if (data) {
                    try {
                        const markup = MarkupSchema.parse(data.markup);

                        const existWidgetIndex = state.widgets.findIndex(
                            ({ id }) => data.documentId === id,
                        );

                        if (existWidgetIndex > -1) {
                            state.widgets[existWidgetIndex] = {
                                id: data.documentId,
                                name: data.name,
                                markup,
                            };
                        } else {
                            state.widgets.push({
                                id: data.documentId,
                                name: data.name,
                                markup,
                            });
                        }
                    } catch (e) {
                        console.log(String(e));
                    }
                } else {
                    // TODO
                }
            }
        });
        builder.addCase(PAGE_ACTIONS.GET_DATASOURCE.fulfilled, (state, action) => {
            const { response, request } = action.payload;
            if (response instanceof BaseError) {
                // TODO
            } else {
                const { data } = response;

                if (data) {
                    try {
                        state.datasources.push({
                            data,
                            name: request.datasourceName,
                        });
                    } catch (e) {
                        console.log(String(e));
                    }
                } else {
                    // TODO
                }
            }
        });
    },
});
