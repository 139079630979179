import { z } from 'zod';
import { CateringEventFormatModelSchema } from 'entities/catering-event-format';
import { GetCateringEventFormatsByCategoryUidApiResponseSchema } from '../store';

export const createCateringEventFormatsFromGetCateringEventFormatsApiResponse = (
    (response: z.infer< typeof GetCateringEventFormatsByCategoryUidApiResponseSchema>) => (
        response.data?.map(({
            documentId,
            name,
            description,
            image,
        }) => (
            CateringEventFormatModelSchema.parse({
                uid: documentId,
                name,
                description,
                image: image && {
                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + image.url,
                },
            })
        ))
    )
);
