import { z } from 'zod';

export const GetPageInfoByIdResponseSchema = z.object({
    data: z.object({
        documentId: z.string(),
        url: z.string(),
        title: z.string().nullable().optional(),
        markup: z.any(),
    }),
    meta: z.any().optional(),
});

export type GetPageInfoByIdResponse = z.infer<typeof GetPageInfoByIdResponseSchema>;
