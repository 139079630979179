import { Container, ContainerProps } from '@chakra-ui/react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import React from 'react';

export type RichTextProps = {
    content?: any
} & ContainerProps & React.RefAttributes<HTMLDivElement>;

export const RichText: React.FC<RichTextProps> = ({
    content,
    ...props
}) => (
    <Container
        margin={0}
        padding={0}
        {...props}
    >
        {content && (
            <BlocksRenderer
                content={content}
            />
        )}
    </Container>
);
