import { createSlice } from '@reduxjs/toolkit';
import { EventCategoryModelSchema } from 'entities/event-category';
import { PortfolioEventCardModelSchema } from 'entities/portfolio-event';
import BaseError from '../../../utils/base-error';
import { PortfolioEventsBlockState } from './portfolio-events-block-state';
import { PortfolioEventsBlockActions } from './portfolio-events-block-actions';

const initialState: PortfolioEventsBlockState = {
    loaded: false,
    portfolioEventsCards: [],
    eventCategories: [],
};

export const portfolioEventsBlockSlice = createSlice({
    name: 'portfolioEventsBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            PortfolioEventsBlockActions.getEventCategories.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log('LOL2', response);
                        const result = response.data.map(({ documentId, name }) => (
                            EventCategoryModelSchema.parse({
                                uid: documentId,
                                name,
                            })
                        ));

                        state.eventCategories = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
        builder.addCase(
            PortfolioEventsBlockActions.getPortfolioEventsCardsByCategoryUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        console.log('LOL2', response);
                        const result = response.data.map(({
                            documentId,
                            name,
                            description,
                            images,
                            category,
                        }) => (
                            PortfolioEventCardModelSchema.parse({
                                uid: documentId,
                                name,
                                description,
                                images: images?.map(({ url }) => ({
                                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + url,
                                })),
                                category: EventCategoryModelSchema.parse({
                                    uid: category.documentId,
                                    name: category.name,
                                }),
                            })
                        ));

                        state.portfolioEventsCards = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
