import {
    Button,
    Grid,
    GridProps,
    Heading,
    Text,
} from '@chakra-ui/react';
import { Icon, IconsNames } from 'components';
import React from 'react';

export type DeliveryPageMainHeaderProps = GridProps & React.RefAttributes<HTMLDivElement>;

export const DeliveryPageMainHeader: React.FC<DeliveryPageMainHeaderProps> = ({
    ...props
}) => (
    <Grid
        templateColumns="1fr auto"
        {...props}
    >
        <Heading
            flex={1}
            textDecoration="underline"
        >
            ДОСТАВКА БЛЮД
        </Heading>
        <Button
            gap="1rem"
        >
            <Icon
                iconName={IconsNames.Track}
            />
            <Text
                fontSize="1.25rem"
            >
                ДОСТАВКА И ОПЛАТА
            </Text>
        </Button>
    </Grid>
);
