import { z } from 'zod';
import {
    CompanyInformationModelSchema,
} from 'entities/company-information';
import { GetCompanyInformationApiResponseSchema } from '../store';

export const createCompanyInformationFromGetCompanyInformationApiResponse = (
    (response: z.infer< typeof GetCompanyInformationApiResponseSchema>) => (
        CompanyInformationModelSchema.parse({
            name: response.data.name,
            title: response.data.title,
            description: response.data.description,
            phone: response.data.phone,
            address: response.data.address,
            email: response.data.email,
            logoImage: response.data.logoImage && {
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + response.data.logoImage.url,
            },
            mainImage: response.data.mainImage && {
                url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + response.data.mainImage.url,
            },
        })
    )
);
