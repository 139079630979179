import { AxiosRequestConfig } from 'axios';
import { Api } from '../api';
import { ErrorResponseSchema } from '../base/responses/error-response';
import BaseError from '../../utils/base-error';
import { FindImagesBlockInfoResponse, FindImagesBlockInfoResponseSchema } from './responses/find-images-block-info-response';

export class ImagesBlockInfoApi extends Api {
    findImagesBlockInfo = async (): Promise<BaseError | FindImagesBlockInfoResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/images-block-info`,
            params: {
                populate: 'images',
            },
        };
        const response = await this.makeCrud<FindImagesBlockInfoResponse>(
            config,
            FindImagesBlockInfoResponseSchema,
            ErrorResponseSchema,
        );

        return response;
    };
}

export const imagesBlockInfoApiInstance = new ImagesBlockInfoApi();
