import { z } from 'zod';

export const SweetShopArticleModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any().optional().nullable(),
    category: z.object({
        uid: z.string(),
    }).optional().nullable(),
    tags: z.array(z.object({
        uid: z.string(),
    })).optional().nullable(),
    images: z.array(z.object({
        url: z.string(),
    })).optional().nullable(),
});

export type SweetShopArticleModel
    = z.infer<typeof SweetShopArticleModelSchema>;
