import { z } from 'zod';

export const OutdoorBarArticleCardModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    category: z.object({
        uid: z.string(),
    }).optional().nullable(),
    image: z.object({
        url: z.string(),
    }).optional().nullable(),
});

export type OutdoorBarArticleCardModel = z.infer<typeof OutdoorBarArticleCardModelSchema>;
