import { z } from 'zod';
// eslint-disable-next-line no-restricted-imports
import { ImageModelSchema } from 'shared/schemas';
import { EventCategoryModelSchema } from '../../event-category';

export const EventTypeModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any(),
    category: EventCategoryModelSchema,
    images: z.array(ImageModelSchema).nullable().optional(),
});

export type EventTypeModel = z.infer<typeof EventTypeModelSchema>;
