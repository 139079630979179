import { AxiosRequestConfig } from 'axios';
import { Api } from '../../../api/api';
import BaseError from '../../../utils/base-error';
import { ErrorResponseSchema } from '../../../api/base/responses/error-response';
import {
    PortfolioEventApiFindCardByCategoryUidResponse,
    PortfolioEventApiFindCardByCategoryUidResponseSchema,
    PortfolioEventApiFindOneResponse,
    PortfolioEventApiFindOneResponseSchema,
} from './responses';

export class PortfolioEventApi extends Api {
    override findOne = async (
        uid: string,
    ): Promise<BaseError | PortfolioEventApiFindOneResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/portfolio-events/${uid}`,
            params: {
                populate: '*',
            },
        };
        const response = await this.makeCrud<PortfolioEventApiFindOneResponse>(
            config,
            PortfolioEventApiFindOneResponseSchema,
            ErrorResponseSchema,
        );

        console.log('LOL3', response);

        return response;
    };

    findCardsByCategoryUid = async (
        eventCategoryUid: string,
    ): Promise<BaseError | PortfolioEventApiFindCardByCategoryUidResponse> => {
        const config: AxiosRequestConfig = {
            method: 'GET',
            url: `${this.host}/portfolio-events/`,
            params: {
                populate: '*',
                'filters[category][documentId][$eq]': eventCategoryUid,
            },
        };

        const response = await this.makeCrud<PortfolioEventApiFindCardByCategoryUidResponse>(
            config,
            PortfolioEventApiFindCardByCategoryUidResponseSchema,
            ErrorResponseSchema,
        );

        console.log('LOL3', response);

        return response;
    };
}

export const portfolioEventApiInstance = new PortfolioEventApi();
