import { createSlice } from '@reduxjs/toolkit';
import { OutdoorBarArticlesBlockState } from './outdoor-bars-block-state';
import { OutdoorBarsBlockActions } from './outdoor-bars-block-actions';
import BaseError from '../../../utils/base-error';
import { OutdoorBarArticleCardModelSchema, parseOutdoorBarArticleCategoryDtoToDomain } from '../../../entities';

const initialState: OutdoorBarArticlesBlockState = {
    loaded: false,
    outdoorBarArticleCategories: [],
    outdoorBarArticleCards: [],
};

export const outdoorBarsBlockSlice = createSlice({
    name: 'outdoorBarsBlockSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /**
         * Обработчик получения списка типов баров (коллекция outdoorBarArticleCategory)
         */
        builder.addCase(
            OutdoorBarsBlockActions.getOutdoorBarArticleCategories.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        const result = response.data.map(
                            (dto) => parseOutdoorBarArticleCategoryDtoToDomain(dto),
                        );

                        state.outdoorBarArticleCategories = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
        /**
         * Обработчик получения списка карточек выездных баров по типу (коллекция OutdoorBarArticle)
         */
        builder.addCase(
            OutdoorBarsBlockActions.getOutdoorBarArticleCardsByCategoryUid.fulfilled,
            (state, action) => {
                const response = action.payload;

                if (response instanceof BaseError) {
                    // TODO
                } else {
                    // Валидация
                    try {
                        const result = response.data.map(
                            (dto) => OutdoorBarArticleCardModelSchema.parse({
                                uid: dto.documentId,
                                name: dto.name,
                                image: dto.images && {
                                    // eslint-disable-next-line max-len
                                    url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + String(dto.images?.[0].url),
                                },
                                category: dto.category && {
                                    uid: dto.category.documentId,
                                },
                            }),
                        );
                        state.outdoorBarArticleCards = result;
                        state.loaded = true;
                    } catch (e) {
                        // return new BaseError(400, String(e));
                        // TODO
                    }
                }
            },
        );
    },
});
