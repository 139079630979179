import { z } from 'zod';
import {
    OutdoorBarArticleIncludedServiceModelSchema,
    OutdoorBarArticleNonIncludedServiceModelSchema,
    OutdoorBarArticlePortionModelSchema,
    OutdoorBarArticleRecommendationModelSchema,
    OutdoorBarArticleSetModelSchema,
} from './components';

export const OutdoorBarArticleModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    description: z.any().optional().nullable(),
    category: z.object({
        uid: z.string(),
        name: z.string(),
    }).optional().nullable(),
    images: z.array(z.object({
        url: z.string(),
    })).optional().nullable(),
    portions: z.array(
        OutdoorBarArticlePortionModelSchema,
    ).optional().nullable(),
    sets: z.array(
        OutdoorBarArticleSetModelSchema,
    ).optional().nullable(),
    includedServices: z.array(
        OutdoorBarArticleIncludedServiceModelSchema,
    ).optional().nullable(),
    nonIncludedServices: z.array(
        OutdoorBarArticleNonIncludedServiceModelSchema,
    ).optional().nullable(),
    recommendations: z.array(
        OutdoorBarArticleRecommendationModelSchema,
    ).optional().nullable(),
});

export type OutdoorBarArticleModel = z.infer<typeof OutdoorBarArticleModelSchema>;
