import {
    AwardsBlock,
    ContactsBlock,
    CrewMembersBlock,
    DegustationBlock,
    FAQsBlock,
    Footer,
    Header,
    ImagesBlock,
    NavigationBlock,
    PortfolioBlock,
    TopHeader,
} from 'widgets';
import { PageLayout } from 'components';
import React, { memo, useLayoutEffect } from 'react';
import { useAppDispath } from 'shared';
import { HomePageActions, useHomePageState } from '../store';
import { HomePageMain } from './components';

const MemoTopHeader = memo(TopHeader);
const MemoHeader = memo(Header);
const MemoFooter = memo(Footer);
const MemoAwardsBlock = memo(AwardsBlock);
const MemoCrewMembersBlock = memo(CrewMembersBlock);
const MemoPortfolioBlock = memo(PortfolioBlock);
const MemoFAQsBlock = memo(FAQsBlock);
const MemoDegustationBlock = memo(DegustationBlock);
const MemoImagesBlock = memo(ImagesBlock);
const MemoContactsBlock = memo(ContactsBlock);
const MemoNavigationBlock = memo(NavigationBlock);

const MemoMain = memo(HomePageMain);

export const HomePage = () => {
    const dispatch = useAppDispath();
    const {
        popularServicesGroup,
        companyInformation,
    } = useHomePageState();

    useLayoutEffect(() => {
        dispatch(HomePageActions.getPopularServicesGroup());
        dispatch(HomePageActions.getCompanyInformation());
    }, []);

    return (
        <PageLayout>
            <MemoTopHeader />
            <MemoHeader />
            <MemoMain
                popularServicesGroup={popularServicesGroup}
                companyInformation={companyInformation}
            />
            <MemoAwardsBlock />
            <MemoImagesBlock />
            <MemoDegustationBlock />
            <MemoCrewMembersBlock />
            <MemoPortfolioBlock />
            <MemoContactsBlock />
            <MemoFAQsBlock />
            <MemoNavigationBlock />
            <MemoFooter />
        </PageLayout>
    );
};
