import { z } from 'zod';

export const PopularServicesGroupCateringModelSchema = z.object({
    image: z.object({
        url: z.string(),
    }).optional().nullable(),
    formats: z.array(
        z.object({
            uid: z.string(),
            name: z.string(),
            shortDescription: z.string().optional().nullable(),
        }),
    ).optional().nullable(),
});

export type PopularServicesGroupCateringModel
= z.infer<typeof PopularServicesGroupCateringModelSchema>;
