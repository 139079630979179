import { configureStore } from '@reduxjs/toolkit';
import {
    awardsBlockSlice,
    contactsBlockSlice,
    crewMembersBlockSlice,
    degustationBlockSlice,
    fAQsBlockSlice,
    imagesBlockSlice,
    portfolioBlockSlice,
    navigationBlockSlice,
    portfolioEventsBlockSlice,
    portfolioEventDetailBlockSlice,
    outdoorBarsBlockSlice,
    outdoorBarDetailBlockSlice,
    sweetShopBlockSlice,
    sweetShopArticlesBlockSlice,
} from 'widgets';
import { leadSectionSlice } from 'features';
import {
    cateringEventFormatSlice,
    cateringEventPageSlice,
    cateringFormatPageSlice,
    cateringPageSlice,
    culinaryStudioArticlePageSlice,
    culinaryStudioPageSlice,
    deliveryArticlePageSlice,
    deliveryPageSlice,
    homePageSlice,
} from 'pages';
import { pageSlice } from '../../controllers/page/page-slice';
import { appSlice } from '../../controllers/app/app-slice';

export const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        basePage: pageSlice.reducer,
        awardsBlock: awardsBlockSlice.reducer,
        crewMembersBlock: crewMembersBlockSlice.reducer,
        portfolioBlock: portfolioBlockSlice.reducer,
        fAQsBlock: fAQsBlockSlice.reducer,
        degustationBlock: degustationBlockSlice.reducer,
        imagesBlock: imagesBlockSlice.reducer,
        contactsBlock: contactsBlockSlice.reducer,
        navigationBlock: navigationBlockSlice.reducer,
        portfolioEventsBlock: portfolioEventsBlockSlice.reducer,
        portfolioEventDetailBlock: portfolioEventDetailBlockSlice.reducer,
        outdoorBarsBlock: outdoorBarsBlockSlice.reducer,
        outdoorBarDetailBlock: outdoorBarDetailBlockSlice.reducer,
        sweetShopBlock: sweetShopBlockSlice.reducer,
        sweetShopArticlesBlock: sweetShopArticlesBlockSlice.reducer,
        homePage: homePageSlice.reducer,
        leadSection: leadSectionSlice.reducer,
        cateringPage: cateringPageSlice.reducer,
        cateringFormatPage: cateringFormatPageSlice.reducer,
        deliveryPage: deliveryPageSlice.reducer,
        cateringEventFormatPage: cateringEventFormatSlice.reducer,
        cateringEventPage: cateringEventPageSlice.reducer,
        deliveryArticlePage: deliveryArticlePageSlice.reducer,
        culinaryStudioPage: culinaryStudioPageSlice.reducer,
        culinaryStudioArticlePage: culinaryStudioArticlePageSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
