import React from 'react';
import { FlexProps } from '@chakra-ui/react';
import { LeadSectionSmallContent } from './components/LeadSectionSmallContent';

export enum LeadSectionType {
    Base = 'base',
    Small = 'small'
}

export type LeadSectionProps = {
    type: LeadSectionType
    title: string
    buttonLabel: string
} & FlexProps & React.RefAttributes<HTMLDivElement>

export const LeadSection: React.FC<LeadSectionProps> = ({
    type,
    title,
    buttonLabel,
    ...props
}) => {
    switch (type) {
    case LeadSectionType.Small: return (
        <LeadSectionSmallContent
            title={title}
            buttonLabel={buttonLabel}
            {...props}
            background="#000000"
        />
    );
    default: return <LeadSectionSmallContent title={title} buttonLabel={buttonLabel} {...props} background="#000000" />;
    }
};
