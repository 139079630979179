import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    sweetShopArticleCategoryApiInstance,
    sweetShopArticleCategoryTagApiInstance,
    sweetShopArticleApiInstance,
} from '../../../entities';

type GetSweetShopArticleCategoryByUidRequestParams = {
    uid: string
}

const getSweetShopArticleCategoryByUid = createAsyncThunk(
    'sweetShopArticlesBlockActionsGetSweetShopArticleCategoriesByUid',
    async (
        {
            uid,
        }: GetSweetShopArticleCategoryByUidRequestParams,
    ) => sweetShopArticleCategoryApiInstance.findOne(
        uid,
        {
            'fields[0]': 'name',
            'fields[1]': 'description',
            'fields[2]': 'addictionalDescription',
            'populate[image][fields][0]': 'url',
        },
    ),
);

type GetSweetShopArticleCategoryTagsByCategoryUidRequestParams = {
    uid: string
}

const getSweetShopArticleCategoryTagsByCategoryUid = createAsyncThunk(
    'sweetShopArticlesBlockActionsGetSweetShopArticleCategoryTagsByCategoryUid',
    async (
        {
            uid,
        }: GetSweetShopArticleCategoryTagsByCategoryUidRequestParams,
    ) => sweetShopArticleCategoryTagApiInstance.find(
        {
            'filters[category][documentId][$eq]': uid,
            'fields[0]': 'name',
            'populate[category][fields][0]': 'documentId',
        },
    ),
);

type GetSweetShopArticlesByCategoryUidAndTagUidRequestParams = {
    categoryUid: string,
    tagUid?: string,
}

const getSweetShopArticlesByCategoryUidAndTagUid = createAsyncThunk(
    'sweetShopArticlesBlockActionsGetSweetShopArticlesByCategoryUidAndTagUid',
    async (
        {
            categoryUid,
            tagUid,
        }: GetSweetShopArticlesByCategoryUidAndTagUidRequestParams,
    ) => sweetShopArticleApiInstance.find(
        {
            'filters[sweetShopArticleCategory][documentId][$eq]': categoryUid,
            'filters[tags][documentId][$eq]': tagUid,
            'fields[0]': 'name',
            'fields[1]': 'description',
            'populate[sweetShopArticleCategory][fields][0]': 'documentId',
            'populate[tags][fields][1]': 'documentId',
            'populate[images][fields][2]': 'url',
        },
    ),
);

export const SweetShopArticlesBlockActions = {
    getSweetShopArticleCategoryByUid,
    getSweetShopArticleCategoryTagsByCategoryUid,
    getSweetShopArticlesByCategoryUidAndTagUid,
};
