import { SweetShopArticleModelSchema, SweetShopArticleModel } from '../model';
import { SweetShopArticleDto } from '../api';

export const parseSweetShopArticleDtoToDomain = (
    dto: SweetShopArticleDto,
): SweetShopArticleModel => SweetShopArticleModelSchema.parse({
    uid: dto.documentId,
    name: dto.name,
    description: dto.description,
    category: {
        uid: dto.sweetShopArticleCategory?.documentId,
    },
    tags: dto.tags?.map(({ documentId }) => ({
        uid: documentId,
    })),
    images: dto.images?.map(({ url }) => ({
        url: process.env.REACT_APP_STRAPI_MEDIA_HOST! + url,
    })),
});
