import { z } from 'zod';

export const FindPortfolioBlockResponseSchema = z.object({
    data: z.object({
        documentId: z.string(),
        image: z.object({
            url: z.string(),
        }).optional().nullable(),
        portfolioEvents: z.array(z.object({
            documentId: z.string(),
            name: z.string(),
            description: z.any().optional(),
            images: z.array(z.object({
                url: z.string(),
            })).optional().nullable(),
        })),
    }),
    meta: z.any().optional(),
});

export type FindPortfolioBlockResponse = z.infer<typeof FindPortfolioBlockResponseSchema>;
