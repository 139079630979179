import cn from 'classnames';
import React, { useLayoutEffect } from 'react';
import { useAppDispath } from 'shared';
import styles from './AwardsBlock.module.scss';
import { useAwardsBlockState } from '../model/awards-block-hooks';
import AwardsBlockActions from '../model/awards-block-actions';
import { AwardCard } from '../../../components';

export type AwardsBlockProps = {
    className?: string
}

export const AwardsBlock = ({
    className,
}: AwardsBlockProps) => {
    const { awards } = useAwardsBlockState();
    const dispatch = useAppDispath();

    useLayoutEffect(() => {
        dispatch(AwardsBlockActions.getAwards());
    }, []);

    return (
        <div className={cn(className, styles.awardsBlock)}>
            <div className={styles.awardsBlock__header}>
                <span className={styles.awardsBlock__title}>
                    Наши награды
                </span>
                <div className={styles.awardsBlock__divider} />
            </div>
            <div className={styles.awardsBlock__grid}>
                {awards.map(({ years, description, image }) => (
                    <AwardCard
                        className={styles.awardsBlock__card}
                        years={years}
                        description={description}
                        image={image}
                    />
                ))}
            </div>
        </div>
    );
};
