import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Image.module.scss';

export type ImageProps = {
    className?: string,
    url: string
}

export const Image = ({
    className,
    url,
}: ImageProps) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    console.log(url);
    const handleLoad = () => {
        setTimeout(() => {
            setLoaded(true);
        }, 5000);
    };

    const handleError = () => {
        setTimeout(() => {
            setError(true);
        }, 5000);
    };

    return (
        <div
            role="presentation"
        >
            <img
                className={cn(className, loaded && !error ? styles.image : styles.image_hidden)}
                alt=""
                src={url}
                onLoad={() => handleLoad()}
                onError={() => handleError()}
            />
            {!loaded && !error && (
                <div className={cn(className, styles.imageLoader)} />
            )}
            {error && (
                <img
                    className={className}
                    src="/no-image-svgrepo-com.svg"
                    alt=""
                />
            )}
        </div>
    );
};
