import { createAsyncThunk } from '@reduxjs/toolkit';
import { portfolioEventApiInstance } from '../../../entities';

type GetPortfolioEventByUidRequest = {
    uid: string
}

const getPortfolioEventByUid = createAsyncThunk(
    'getPortfolioEventByUid',
    async ({
        uid,
    }: GetPortfolioEventByUidRequest) => {
        console.log('ВЫЗОВ');
        const response = await portfolioEventApiInstance.findOne(uid);
        console.log('123', response);
        return response;
    },
);

export const PortfolioEventDetailBlockActions = {
    getPortfolioEventByUid,
};
