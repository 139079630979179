import { Flex } from '@chakra-ui/react';
import React from 'react';
import { SimpleCarousel } from 'shared';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { CateringFormatModel } from 'entities';
import { CateringFormatMainHeader } from './CateringFormatMainHeader';

export type CateringFormatMainProps = {
    cateringFormat?: CateringFormatModel
}

export const CateringFormatMain: React.FC<CateringFormatMainProps> = ({
    cateringFormat,
}) => (
    <Flex
        gap="2rem"
        flexDirection="column"
    >
        <CateringFormatMainHeader
            formatName={cateringFormat?.name || ''}
            padding="2rem 2rem 0 2rem"
        />
        <Flex
            flexDirection="row"
            flexWrap="wrap"
            gap="2rem"
            padding="0 2rem 0 2rem"
        >
            <Flex
                flexDirection="column"
                flex="1 0 40%"
            >
                <BlocksRenderer
                    content={cateringFormat?.description || []}
                />
            </Flex>
            <SimpleCarousel
                flex="1 0 50%"
                images={cateringFormat?.images?.map(({ url }) => url) || []}
            />
        </Flex>
    </Flex>
);
