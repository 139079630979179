import { z } from 'zod';

export const SweetShopArticleCategoryTagModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    category: z.object({
        uid: z.string(),
    }),
});

export type SweetShopArticleCategoryTagModel
    = z.infer<typeof SweetShopArticleCategoryTagModelSchema>;
