import { z } from 'zod';

export const OutdoorBarArticlePortionModelSchema = z.object({
    value: z.number(),
    meassure: z.string(),
    description: z.any().optional().nullable(),
    weight: z.number(),
    price: z.number(),
    currency: z.object({
        uid: z.string(),
        name: z.string(),
        abbreviation: z.string(),
    }).optional().nullable(),
});

export type OutdoorBarArticlePortionModel = z.infer<typeof OutdoorBarArticlePortionModelSchema>;
