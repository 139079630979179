import { z } from 'zod';

export const CulinaryStudioArticleCardModelSchema = z.object({
    uid: z.string(),
    name: z.string(),
    category: z.object({
        uid: z.string(),
    }).optional().nullable(),
    image: z.object({
        url: z.string(),
    }).optional().nullable(),
});

export type CulinaryStudioArticleCardModel = z.infer<typeof CulinaryStudioArticleCardModelSchema>;
