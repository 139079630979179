import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';

export const PortfolioEventsBlockHeader = () => (
    <Flex
        flexDirection="row"
    >
        <Heading
            flex={1}
            textDecoration="underline"
        >
            НАШЕ ПОРТФОЛИО
        </Heading>
    </Flex>
);
