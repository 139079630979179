import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { ApiFactory, ApiResponse } from 'shared/api';
import BaseError from 'utils/base-error';
import { z } from 'zod';

/**
 * Инстанс апишки
 */
const apiInstance = ApiFactory.getInstance();

/**
 * Схема для парсинга респонса
 */
export const GetLeadFormApiResponseSchema = z.object({
    data: z.object({
        image: z.object({
            url: z.string(),
        }).optional().nullable(),
        commercialOfferFile: z.object({
            url: z.string(),
        }).optional().nullable(),
    }),
});

type GetLeadFormApiResponse
    = ApiResponse<z.infer<typeof GetLeadFormApiResponseSchema>>
    | BaseError;

type GetLeadFormActionRequest = undefined

export const getLeadForm = createAsyncThunk<
    GetLeadFormApiResponse,
    GetLeadFormActionRequest
>(
    'LeadSectionActionsGetLeadForm',
    async () => apiInstance.get(
        '/lead-form',
        {
            'populate[logoImage][fields]': 'url',
            'populate[mainImage][fields]': 'url',
        },
        GetLeadFormApiResponseSchema,
    ),
);
